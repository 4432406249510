import React, { useEffect, useState, useLayoutEffect } from "react";
import Navbar from './Navbar';
import background from './static/images/background.png'
import Style from "style-it"
import {useLocation} from "react-router-dom"

//import "./common.css"
//import "./sytles.css"

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function isValid(input) {
	var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
	if (pattern.test(input)) {
		return false;
	}
	return true; //good user input
  }

const Guide = () => {
  const [expanded, setExpanded] = useState(false);
  const [windoWidth, windowHeight] = useWindowSize();

  const clickHandler = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  const dropDown = (
    <div className="dropdown">
      <a href="https://www.socialswap.io/" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DEX</h2></div></a>
      <a href="/referrals" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Ref</h2></div></a>
      <a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DappRadar</h2></div></a>
      <a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Contract</h2></div></a>
    </div>
  );

  let bg = background;
  if(windoWidth < 900){
    bg = null;
  }

  return (
    <div>
        <Style>
            {`
            
            `}
        </Style>

        <div id="App" class="layout" data="null">

            <div className="header">
            <div className="header__logo"><a href="/"><img src="/modules/site/main/assets/images/logo.svg" /></a></div>
            <ul className="header__nav">
            <li><a href="/assets/">Assets</a></li>
            <li><a href="/">Exchange</a></li>
            <li><a href="/#/liquidity/">Liquidity</a></li>
            <li><a href="/staking/">Staking</a></li>
            <li><a href="/ref/">Ref</a></li>
            <li><a href="/vote/">Vote <sup>2</sup></a></li>
            <li><a href="/nft/">NFT</a></li>
            <li><a href="/pairs/">Pairs</a></li>
            </ul>
            <div className="header__grow" />
            <div className="header__tools">{/**/}<button className="btn primary">Connect to wallet</button><button className="btn header__bars"><img src="/modules/site/main/assets/images/bars.svg" /></button></div>
            {/**/}
            </div>

            

            <div className="section-header-banner i-section-large">
            <div className="layout__wrap">
                <div className="i-grid i-grid-large i-flex-middle">
                    <div className="i-w-1-1 i-w-1-2@s">
                        <h2 className="i-h2 i-text-white i-margin-bottom-medium">We are pleased to welcome you to USWAP.ME<br /> It is one of the fastest growing projects in the Tron network and  DeFi world</h2>
                        <p className="i-text-white i-text-opacity i-margin-bottom-medium">This section is created for beginner crypto investors. And we did our best to make it easier for you, dear friend, to take your first steps in the world of crypto-investing.</p>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s"><img src="/modules/site/guide/assets/images/section-header-banner.svg" /></div>
                </div>
            </div>
            </div>

            

            <div id="menu" className="menu-middle-container">
            <div className="layout__wrap">
                <ul className="menu-middle">
                    <li className="active"><a href="/guide/#menu">Start</a></li>
                    <li><a href="/guide/guide/#menu">Guide</a></li>
                    <li><a href="/guide/interface/#menu">Interface description</a></li>
                    <li><a href="/guide/glossary/#menu">Glossary of terms</a></li>
                </ul>
            </div>
            </div>

            

            <div className="section-index-level i-section-large">
            <div className="layout__wrap">
                <h2 className="i-h1 i-margin-bottom">Choose a section that suits your level</h2>
                <div className="i-grid i-grid-large">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="section-index-level__left i-padding-medium">
                        <img src="../../../modules/site/guide/assets/images/section-index-level_left.svg" className="i-visible@s" /><img src="../../../modules/site/guide/assets/images/section-index-level_left__mobile.svg" className="i-hidden@s" />
                        <p className="i-margin-top i-margin-horizontal-auto i-w-1-1 i-w-2-3@m">I am a beginner and I haven’t come across crypto investment before</p>
                        </div>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="section-index-level__right i-padding-medium">
                        <img src="../../../modules/site/guide/assets/images/section-index-level_right.svg" className="i-visible@s" /><img src="../../../modules/site/guide/assets/images/section-index-level_right__mobile.svg" className="i-hidden@s" />
                        <p className="i-margin-top i-margin-horizontal-auto i-w-1-1 i-w-2-3@m">I am an experienced crypto investor and I want to know even more</p>
                        <span className="i-badge i-badge-blue i-badge-hours">soon</span>
                        </div>
                    </div>
                </div>
                <div className="block-info">The information below should not be taken as financial or legal advice. Use it at your own risk only after careful analysis, research and verification. Trading is a high-risk activity, fraught with possible losses. Check with a financial advisor before making any decisions. The content of the site cannot be considered an offer or a guide to action.</div>
            </div>
            </div>

            

            <div className="i-section-medium i-background-blue">
            <div className="layout__wrap">
                <p className="i-h2 i-text-white i-text-center">There are several tools for making money on Uswap.me</p>
            </div>
            </div>

            

            <div className="section-index-dex i-section-large">
            <div className="layout__wrap">
                <div className="i-grid i-grid-large">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <h2 className="i-h1 i--margin-bottom">DEX | Exchange</h2>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <div className="block-rupor i--margin-bottom">Trading is definitely intended only for experienced, decision-making agents.</div>
                    </div>
                </div>
                <div className="i-grid i-grid-large">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <p className="i-margin-bottom">The exchange section on the uswap.me website is a reliable and convenient platform for exchanging trc-20 tokens (these are the tokens of the TRON network).</p>
                        <p className="i-margin-bottom">Uswap.me is a fully automated decentralized exchange that performs trading exchange operations without the participation of third parties. When trading on uswap, you do not need to use concepts such as an order book, broker or order.</p>
                        <p className="i-margin-bottom">To exchange one token for another, you just need to select the trading pair you are interested in, determine the quantity and click on the exchange button.</p>
                        <p>The exchange is carried out through an infinite pool of liquidity, which we will talk about below.</p>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="i-text-center"><img src="../../../modules/site/guide/assets/images/section-index-dex_right.svg" className="i---visible@s" /></div>
                    </div>
                </div>
                <div className="block-info i-margin-bottom-medium">It's not a secret for anyone that the default cryptocurrency is a highly volatile (currency with frequent price fluctuations) asset, which allows you to make money on the rate of decline or growth of a particular token.</div>
                <div className="i-text-center"><a href="../../../guide/guide/2/#guide" className="link-section" style={{borderBottom: '1px dashed var(--color-blue)', textDecorationLine: 'none'}}>Go to the Step by step guide</a></div>
            </div>
            </div>

            

            <div className="section-index-basket i-section-large i-background-blue-light">
            <div className="layout__wrap">
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <h2 className="i-h1 i--margin-bottom">Providing liquidity to a trading pair</h2>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <div className="block-rupor i--margin-bottom">If trading is risky and requires the investor's activity, then this earning tool can be defined as passive investing with passive earnings.</div>
                    </div>
                </div>
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-4-10@s i-margin-bottom-medium">
                        <div className="i-padding i-border-radius i-background-white i-text-large i-text-blue-dark">
                        <p>Trading on a decentralized exchange like Uswap is done using liquidity pools.</p>
                        </div>
                    </div>
                    <div className="i-w-1-1 i-w-6-10@s i-margin-bottom-medium">
                        <p className="i-margin-bottom">In simple words, the Liquidity Pool is a stock of currency (previously placed on the exchange) available for quick exchange.</p>
                        <p>When a trader wishes to exchange one currency for another, he does not need to contact the buyer or seller. He refers to a liquidity pool created specifically for this trading pair, into which a large amount of one and the other currencies are pre-filled. When making an exchange, a trader puts one currency into the pool and takes another, thereby shifting the ratio of the pair to each other. (See diagram)</p>
                    </div>
                </div>
                <div className="i-text-center i-margin-bottom-medium"><img src="../../../modules/site/guide/assets/images/section-index-basket.svg" className="i---visible@s" /></div>
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-6-10@s i-margin-bottom-medium">
                        <p className="i-margin-bottom">Let's say that you, as a future liquidity provider, have 2 assets in storage. For example USDT (this is the dollar) and TRX (this is the main currency of the TRON Blockchain)</p>
                        <p>The Uswap exchange invites you to place your funds (provide liquidity) in the appropriate pool for the USDT | TRX pair. Traders will use your funds for the exchange, for which they pay a commission of 0.3% for each exchange (swap). And this 0.3% is distributed among all liquidity providers in proportion to the share in the pool. (See diagram)</p>
                    </div>
                    <div className="i-w-1-1 i-w-4-10@s i-margin-bottom-medium">
                        <div className="i-padding i-border-radius i-background-white i-text-large i-text-blue-dark">
                        <p>Pool liquidity - (the funds for which the exchange is made) is provided by liquidity providers. These are investors who temporarily placed their money in the pool for a reward.</p>
                        </div>
                    </div>
                </div>
                <div className="i-text-center i-margin-bottom-medium"><img src="../../../modules/site/guide/assets/images/section-index-basket-2.svg" className="i---visible@s" /></div>
                <div className="i-text-center"><a href="../../../guide/guide/3/#guide" className="link-section" style={{borderBottom: '1px dashed var(--color-blue)', textDecorationLine: 'none'}}>Go to the Step by step guide</a></div>
            </div>
            </div>

            

            <div className="section-index-steiking i-section-large">
            <div className="layout__wrap">
                <div className="i-grid i-grid-large">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <h2 className="i-h1">Staking <span className="i-badge i-badge-orange i-badge-dollar">profitable</span></h2>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <div className="block-rupor">Be ware and careful, store and invest only those tokens in which you are confident.</div>
                    </div>
                </div>
                <div className="i-grid i-grid-large">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <p className="i-margin-bottom">Uswap.me cares about its investors, and an additional motivational project - Staking - was developed especially for them.</p>
                        <p className="i-margin-bottom">It is very good and pleasant to make money from each Swap. But we went further. For the fact that you provide liquidity to the Uswap.me exchange, we give the opportunity to participate in the Staking project.</p>
                        <p className="i-margin-bottom">It's still easier here than providing liquidity.</p>
                        <p className="i-margin-bottom">When an investor adds liquidity to one of the trading pairs, he receives special technical tokens. LP tokens. They help determine the investor's share in the total liquidity of the pool.</p>
                        <p className="i-margin-bottom">After that, the investor is invited to go to the UME or EFT Staking section, select the appropriate pool and click on the Stake button. From now on, every second you receive pleasant bonuses in the form of a UME or EFT token, which can be exchanged for any currency you are interested in. For example TRX. On the bar of each pool, you see the annual percentage of income, which is calculated based on several factors, such as the UME rate, the amount of remuneration, and the number of participants in the pool.</p>
                        <div className="block-info i-margin-bottom">It is important to clarify. Upon entering staking, a commission is debited from the liquidity provider. The commission of 2-3% is debited immediately from the entire amount. The exchange income is made up of these percentages.</div>
                        <p>And one more important clarification. We cannot predict, guarantee and stabilize the rate of cryptocurrencies. Therefore, it is very important to remember and understand that the uswap.me project is not responsible for the fall in the exchange rate that is placed and earned on the exchange.</p>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="i-text-center i-margin-bottom-medium"><img src="../../../modules/site/guide/assets/images/section-index-steiking.svg" className="i---visible@s" /></div>
                        <div className="i-padding i-border-radius i-background-blue-light i-text-large i-text-blue-dark">
                        <p className="i-margin-bottom">The smart contract ensures the timely payment of bonus tokens, exchange fees and the correct operation of all services.</p>
                        <p>But the investor is personally responsible for losses in exchange rates.</p>
                        </div>
                    </div>
                </div>
                <div className="i-text-center"><a href="../../../guide/guide/5/#guide" className="link-section">Go to the Step by step guide</a></div>
            </div>
            </div>

            

            <div className="section-index-referral i-section-large i-background-blue-light">
            <div className="layout__wrap">
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <h2 className="i-h1">Referral program</h2>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom">
                        <div className="block-rupor">Once, by sharing a referral link, you will receive a reward of 15% from all income from staking your referral during the entire period of his/her work on uswap.me.</div>
                    </div>
                </div>
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="i-margin-bottom-medium">
                        <p className="i-margin-bottom">As part of the marketing program for the development of the uswap.me project, we have developed a motivational referral program.</p>
                        <p>Like everything on uswap.me, our referral program is as transparent, understandable and honest as possible.</p>
                        </div>
                        <div>
                        <p className="i-text-large i-text-blue i-margin-bottom">How it works:</p>
                        <ul className="section-index-referral__list">
                            <li>
                                <div>Go to the REF section and copy your individual referral link.</div>
                            </li>
                            <li>
                                <div>Send to the person concerned. These can be your acquaintances, friends or relatives. Don't forget to add a link to this guide.</div>
                            </li>
                            <li>
                                <div>The referral starts using the Staking service</div>
                            </li>
                            <li>
                                <div>You get your 15% from all income from staking of your referral</div>
                            </li>
                            <li>
                                <div>The referral reward is credited to your wallet at the moment your referral withdraws the earned funds.</div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="i-text-center i-margin-bottom-medium"><img src="../../../modules/site/guide/assets/images/section-index-referral.svg" className="i---visible@s" /></div>
                        <div className="block-info i-margin-bottom">IMPORTANT!!! Your referral receives his income in full. Your reward is paid by the uswap.me exchange</div>
                    </div>
                </div>
                <div className="i-text-center"><a href="../../../ref/" className="link-section">Go to the REF Section</a></div>
            </div>
            </div>

            

            <div className="section-index-umeeft i-section-large i--background-blue-light">
            <div className="layout__wrap">
                <div className="i-grid i-grid-medium">
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <h2 className="i-h1 i-margin-bottom">UME &amp; EFT</h2>
                        <p className="i-margin-bottom">As mentioned above, the Uswap.me exchange awards with UME and EFT tokens for staking.</p>
                        <p className="i-margin-bottom">These are copyright projects of the uswap.me exchange</p>
                        <p className="i-margin-bottom">In fact, UME and EFT are voting and governance tokens. With their help, each holder of UME and EFT can participate in the life of the project, voting for one way or another of the project's development. For example, using voting, the project community makes decisions on listing (adding) new tokens to the uswap.me exchange</p>
                        <p>UME and EFT have a price tag. Volatile price. Therefore, it is often both sold and bought. No one can forbid you to store or sell the received tokens and make money on it.</p>
                    </div>
                    <div className="i-w-1-1 i-w-1-2@s i-margin-bottom-medium">
                        <div className="i-text-center"><img src="../../../modules/site/guide/assets/images/section-index-umeeft.svg" className="i---visible@s" /></div>
                    </div>
                </div>
                <div className="i-text-center">&nbsp;</div>
            </div>
            </div>






        </div>

    </div>
  );
}

export default Guide;