
import React, { useEffect, useState, useLayoutEffect } from "react";
import Price from './Price';
import Navbar from './Navbar';
import background from './static/images/background.png'
import telegram from './static/images/telegram2.png'
import youtube from './static/images/youtube2.png'
import reddit from './static/images/reddit2.png'
import {useLocation} from "react-router-dom"
import Style from "style-it"
import Chart from "react-apexcharts"
import {useMediaQuery} from "react-responsive"

const backendIp = "https://socialswappairs.herokuapp.com/data/highest";
//const backendAll = "https://socialswapchart.herokuapp.com/data/all";
const backendAll = "https://socialswapstats.herokuapp.com/data/all";
const backendNineMonth = "https://socialswapstats.herokuapp.com/data/ninemonth";
const backendSixMonth = "https://socialswapstats.herokuapp.com/data/sixmonth";
const backendThreeMonth = "https://socialswapstats.herokuapp.com/data/threemonth";
const backendMonth = "https://socialswapstats.herokuapp.com/data/month";
const backendWeek = "https://socialswapstats.herokuapp.com/data/week";
const backendDay = "https://socialswapstats.herokuapp.com/data/day";

const backendallTimeHigh = "https://socialswapstats.herokuapp.com/data/high/all"
const backendallTimeLow = "https://socialswapstats.herokuapp.com/data/low/all"
const backenddailyHigh = "https://socialswapstats.herokuapp.com/data/high/day"
const backenddailyLow = "https://socialswapstats.herokuapp.com/data/low/day"
const backendweeklyHigh = "https://socialswapstats.herokuapp.com/data/high/week"
const backendweeklyLow = "https://socialswapstats.herokuapp.com/data/low/week"
const backendmonthlyHigh = "https://socialswapstats.herokuapp.com/data/high/month"
const backendmonthlyLow = "https://socialswapstats.herokuapp.com/data/low/month"

const backendCirc = "https://backend.socialswap.io/api/v1/public/settings"
const backendCircMy = "https://socialswapstats.herokuapp.com/data/circ"
const backendBurned = "https://socialswapstats.herokuapp.com/data/burn";

const backendtlv = "https://backend.socialswap.io/api/v1/tlv"
const backendallTimeHighTLV = "https://socialswapstats.herokuapp.com/data/tlv/high/all"
const backendallTimeLowTLV = "https://socialswapstats.herokuapp.com/data/tlv/low/all"
const backenddailyHighTLV = "https://socialswapstats.herokuapp.com/data/tlv/high/day"
const backenddailyLowTLV = "https://socialswapstats.herokuapp.com/data/tlv/low/day"
const backendweeklyHighTLV = "https://socialswapstats.herokuapp.com/data/tlv/high/week"
const backendweeklyLowTLV = "https://socialswapstats.herokuapp.com/data/tlv/low/week"
const backendmonthlyHighTLV = "https://socialswapstats.herokuapp.com/data/tlv/high/month"
const backendmonthlyLowTLV = "https://socialswapstats.herokuapp.com/data/tlv/low/month"

const backendallTimeHighMC = "https://socialswapstats.herokuapp.com/data/mc/high/all"
const backendallTimeLowMC = "https://socialswapstats.herokuapp.com/data/mc/low/all"
const backenddailyHighMC = "https://socialswapstats.herokuapp.com/data/mc/high/day"
const backenddailyLowMC = "https://socialswapstats.herokuapp.com/data/mc/low/day"
const backendweeklyHighMC = "https://socialswapstats.herokuapp.com/data/mc/high/week"
const backendweeklyLowMC = "https://socialswapstats.herokuapp.com/data/mc/low/week"
const backendmonthlyHighMC = "https://socialswapstats.herokuapp.com/data/mc/high/month"
const backendmonthlyLowMC = "https://socialswapstats.herokuapp.com/data/mc/low/month"

const backendTransAll = "https://socialswapstats.herokuapp.com/data/trans/all"
const backendCircAll = "https://socialswapstats.herokuapp.com/data/circ/all"
const backendDailyRewardsAll = "https://socialswapstats.herokuapp.com/data/dailyrewards/all"
const backendHoldersAll = "https://socialswapstats.herokuapp.com/data/holders/all"
const backendStakersAll = "https://socialswapstats.herokuapp.com/data/stakers/all"
const backendBurnedAll = "https://socialswapstats.herokuapp.com/data/burned/all"
const timeInterval = 10000;
const PRICE = "price"


const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function isValid(input) {
  var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
  if (pattern.test(input)) {
      return false;
  }
  return true; 
  
  //good user input
}

function max(input) {
  if (toString.call(input) !== "[object Array]")  
    return false;
return Math.max.apply(null, input);
}

function min(input) {
  if (toString.call(input) !== "[object Array]")  
    return false;
return Math.min.apply(null, input);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatizeHistoryPrice(data){
  if(data["prices"][0] && data["prices"][0]["timestamp"] && data["prices"][0]["sstprice"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["prices"].length;i++){
      timestamps.push(parseInt(data["prices"][i]["timestamp"]));
      prices.push(data["prices"][i]["sstprice"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryStakers(data){
  if(data["stakers"][0] && data["stakers"][0]["timestamp"] && data["stakers"][0]["totalstakers"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["stakers"].length;i++){
      timestamps.push(parseInt(data["stakers"][i]["timestamp"]));
      prices.push(data["stakers"][i]["totalstakers"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryHolders(data){
  if(data["holders"][0] && data["holders"][0]["timestamp"] && data["holders"][0]["holders"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["holders"].length;i++){
      timestamps.push(parseInt(data["holders"][i]["timestamp"]));
      prices.push(data["holders"][i]["holders"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryTrans(data){
  if(data["transactions"][0] && data["transactions"][0]["timestamp"] && data["transactions"][0]["transactions"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["transactions"].length;i++){
      timestamps.push(parseInt(data["transactions"][i]["timestamp"]));
      prices.push(data["transactions"][i]["transactions"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryCirc(data){
  if(data["circulating"][0] && data["circulating"][0]["timestamp"] && data["circulating"][0]["circsupply"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["circulating"].length;i++){
      timestamps.push(parseInt(data["circulating"][i]["timestamp"]));
      prices.push(data["circulating"][i]["circsupply"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryBurned(data){
  if(data["burned"][0] && data["burned"][0]["timestamp"] && data["burned"][0]["burned"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["burned"].length;i++){
      timestamps.push(parseInt(data["burned"][i]["timestamp"]));
      prices.push(data["burned"][i]["burned"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryRewards(data){
  if(data["rewards"][0] && data["rewards"][0]["timestamp"] && data["rewards"][0]["rewards"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["rewards"].length;i++){
      timestamps.push(parseInt(data["rewards"][i]["timestamp"]));
      prices.push(data["rewards"][i]["rewards"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}


function procentualPriceChange(prices){
  if(prices[0] > prices[prices.length -1]){
    document.getElementById("pricechange").style.color = "red";
    var difference = ((1 - (prices[prices.length - 1]/prices[0]))*100).toFixed(2);
    return("-" + difference + "%");
  }else{
    document.getElementById("pricechange").style.color = "#69db6d";
    var difference = (((prices[prices.length -1]/prices[0]) - 1) * 100).toFixed(2);
    return("+" + difference + "%");
  }
}


function extractDate(timestamp){
  const dateObject = new Date(parseInt(timestamp))
  const day = dateObject.toLocaleString("en-US", {day: "numeric"})
  const month = dateObject.toLocaleString("en-US", {month: "numeric"})
  const year = dateObject.toLocaleString("en-US", {year: "numeric"})
  const completeDate = day + "." + month + "." + year
  return completeDate;
}

function getOptionsBar(timestamps, prices, showTipName){
  return{
      chart: {
        type: 'bar',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      labels: timestamps,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: false,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        tickAmount: 6,
        min: 0,
        max: max(prices) * 1.25,
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: 3,
        labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => { 
              if(parseInt(value) >= 1000){
                return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return  parseFloat(value).toFixed(3);
            }  
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0,
        },
        title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
      legend: {
        horizontalAlign: 'right'
      },
      tooltip: {
        enabled: true, //Enable and disable tooltips
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '12px',
          fontFamily: undefined
        },
        onDatasetHover: {
            highlightDataSeries: false,
        },
        x: {
            show: true, //Show date on tooltip
            format: 'dddd, MMMM d, yyyy', //HH:mm
            formatter: undefined,
        },
        y: {
            formatter: function(value){
              if(parseInt(value) >= 1000){
                return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
             } else {
                return  value.toFixed(4);
             }  
            },
            title: {
                formatter: (seriesName) => showTipName,
            },
        },
        z: {
            formatter: undefined,
            title: 'Size: '
        },
        marker: {
            show: true,
        },
        items: {
          display: "flex",
        },
        fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },
      }
    }
}

function getOptionsArea(timestamps, prices, showTipName){
  return{
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      labels: timestamps,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: false,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        tickAmount: 6,
        min: min(prices) * 0.98,
        max: max(prices) * 1.01,
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: 3,
        labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => { 
              if(parseInt(value) >= 1000){
                return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
             } else {
                return  parseFloat(value).toFixed(3);
             }  
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0,
        },
        title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
      legend: {
        horizontalAlign: 'right'
      }
    }
}

function getOptionsDoublearea(timestamps, prices, showTipName){
  return{
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      colors:['#90ee90', '#008ffb'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 3,
        dashArray: 0
      },
      labels: timestamps,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: false,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        tickAmount: 6,
        min: min(prices) * 0.7,
        max: max(prices) * 1.3,
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: 3,
        labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => { 
              if(parseInt(value) >= 1000){
                return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
             } else {
                return  parseFloat(value).toFixed(3);
             }  
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0,
        },
        title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
    },
      tooltip: {
      enabled: true, //Enable and disable tooltips
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
      x: {
          show: true, //Show date on tooltip
          format: 'dddd, MMMM d, yyyy, hh:mm TT G\\M\\T', //HH:mm
          formatter: undefined,
      },
      y: {
          formatter: function(value){
            if(parseInt(value) >= 1000){
              return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
              return 0;
          }  
          },
          title: {
              formatter: (seriesName) => seriesName,
          },
      },
      z: {
          formatter: undefined,
          title: 'Size: '
      },
      marker: {
          show: true,
      },
      items: {
        display: "flex",
      },
      fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
      },
    },
      legend: {
        horizontalAlign: 'right'
      }
    }
}

function getOptions(timestamps, prices, showTipName){
  return {
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors:['#90ee90'],
    stroke: {
      curve: 'straight'
    },
    labels: timestamps,
    xaxis: {
      type: 'datetime',
      categories: timestamps,
      labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
          },
      },
      axisBorder: {
          show: true,
          color: '#78909C',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
      },
      axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
      },
      tickAmount: undefined,
      tickPlacement: 'between',
      min: undefined,
      max: undefined,
      range: undefined,
      floating: false,
      decimalsInFloat: undefined,
      position: 'bottom',
      title: {
          text: undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
          },
      },
      crosshairs: {
          show: true,
          width: 1,
          position: 'back',
          opacity: 0.9,        
          stroke: {
              color: '#b6b6b6',
              width: 0,
              dashArray: 0,
          },
          fill: {
              type: 'solid',
              color: '#B1B9C4',
              gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
              },
          },
          dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4,
          },
      },
      tooltip: {
          enabled: false,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: 0,
            fontFamily: 0,
          },
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: false,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      tickAmount: 6,
      min: (par) => {
        const launchtime = 1617184298575;
        if(min(timestamps) < launchtime){ //Having workaround to request whether we are currently on all time chart view
          return 0;
        }else{
          return min(prices) * 0.98;
        }
      },
      max: max(prices) * 1.01,
      forceNiceScale: false,
      floating: false,
      decimalsInFloat: 3,
      labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => { 
            if(parseInt(value) >= 1000){
              return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           } else {
              return  parseFloat(value).toFixed(3);
           }  
          },
      },
      axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
      },
      axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
      },
      title: {
          text: undefined,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
          },
      },
      crosshairs: {
          show: true,
          position: 'back',
          stroke: {
              color: '#b6b6b6',
              width: 1,
              dashArray: 0,
          },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true, //Show date on tooltip
        format: 'dddd, MMMM d, yyyy, hh:mm TT G\\M\\T', //HH:mm
        formatter: undefined,
        },
      y: {
            formatter: function(value){
              if(parseInt(value) >= 1000){
                return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return  value.toFixed(4);
            }  
            },
            title: {
                formatter: (seriesName) => showTipName,
            },
        },
    },
    legend: {
      horizontalAlign: 'left'
    }
  }
}

const App = () => {
  const [data, setData] = useState([]);
  const [price, setPrice] = useState("N/A");
  const [expanded, setExpanded] = useState(false);
  const [windoWidth, windowHeight] = useWindowSize();
  const [allTimeHigh, setallTimeHigh] = useState(0)
  const [allTimeLow, setallTimeLow] = useState(0)
  const [dailyHigh, setdailyHigh] = useState(0)
  const [dailyLow, setdailyLow] = useState(0)
  const [weeklyHigh, setweeklyHigh] = useState(0)
  const [weeklyLow, setweeklyLow] = useState(0)
  const [monthlyHigh, setmonthlyHigh] = useState(0)
  const [monthlyLow, setmonthlyLow] = useState(0)
  const [allHighDate, setallHighDate] = useState(0)
  const [allLowDate, setallLowDate] = useState(0)
  const [percentageUp, setpercentageUp] = useState(0)
  const [percentageDown, setpercentageDown] = useState(0)
  const [ROI, setROI] = useState("Calculating")
  const [TLV, setTLV] = useState(0)
  const [Circ, setCirc] = useState(0)
  const[CircMy, setCircMy] = useState(0)
  const[marketCap, setMarketCap] = useState(0);
  const[fullyDilutedMarketCap, setFullyDilutedMarketCap] = useState(0);
  const[burned, setBurned] = useState(0);
  const [refLink, setrefLink] = useState("https://www.socialswap.io/")
  const {search} = useLocation()
  const [showMore, setshowMore] = useState("Show More");

  const [allTimeHighTLV, setallTimeHighTLV] = useState(0);
  const [allTimeLowTLV, setallTimeLowTLV] = useState(0);
  const [dailyHighTLV, setdailyHighTLV] = useState(0);
  const [dailyLowTLV, setdailyLowTLV] = useState(0);
  const [weeklyHighTLV, setweeklyHighTLV] = useState(0);
  const [weeklyLowTLV, setweeklyLowTLV] = useState(0);
  const [monthlyHighTLV, setmonthlyHighTLV] = useState(0);
  const [monthlyLowTLV, setmonthlyLowTLV] = useState(0);
  const [allHighDateTLV, setallHighDateTLV ] = useState(0);
  const [allLowDateTLV, setallLowDateTLV] = useState("28.3.2021");

  const [allTimeHighMC, setallTimeHighMC] = useState(0);
  const [allTimeLowMC, setallTimeLowMC] = useState(0);
  const [dailyHighMC, setdailyHighMC] = useState(0);
  const [dailyLowMC, setdailyLowMC] = useState(0);
  const [weeklyHighMC, setweeklyHighMC] = useState(0);
  const [weeklyLowMC, setweeklyLowMC] = useState(0);
  const [monthlyHighMC, setmonthlyHighMC] = useState(0);
  const [monthlyLowMC, setmonthlyLowMC] = useState(0);
  const [allHighDateMC, setallHighDateMC ] = useState(0);
  const [allLowDateMC, setallLowDateMC] = useState("28.3.2021");

  const [currentTimeFrameID, setcurrentTimeFrameID] = useState("1D");
  const [dailyHistory, setDailyHistory] = useState([]);
  const [weeklyHistory, setweeklyHistory] = useState([]);
  const [monthlyHistory, setMonthlyHistory] = useState([]);
  const[threeMonthHistory, setThreeMonthHistory] = useState([]);
  const[sixMonthHistory, setSixMonthHistory] = useState([]);
  const[nineMonthHistory, setNineMonthHistory] = useState([]);
  const [allHistory, setAllHistory] = useState([]);

  const[allHistoryTrans, setAllHistoryTrans] = useState([]);

  const[allHistoryCirc, setAllHistoryCirc] = useState([]);

  const [allHistoryRewards, setAllHistoryRewards] = useState([]);

  const [allHistoryHolders, setAllHistoryHolders] = useState([]);

  const [allHistoryStakers, setAllHistoryStakers] = useState([]);

  const[allHistoryBurned, setAllHistoryBurned] = useState([]);

  const[priceChange, setpriceChange] = useState("");

  const smallScreen = useMediaQuery({ maxWidth: 800 })

  const [series, setSeries] = useState([
        {
          name: "series-1",
          data: [5, 6]
        }
      ]);
  const [options, setOptions] = useState(getOptions([], [], ""));

  const [seriestrans, setSeriesTrans] = useState([
    {
      name: "series-2",
      data: [5, 6]
    }
  ]);
const [optionstrans, setOptionsTrans] = useState(getOptions([], [], ""));

const [seriescirc, setSeriesCirc] = useState([
  {
    name: "series-3",
    data: [5, 6]
  }
]);
const [optionscirc, setOptionsCirc] = useState(getOptions([], [], ""));

const [seriesBurned, setSeriesBurned] = useState([
  {
    name: "series-5",
    data: [5, 6]
  }
]);
const [optionsBurned, setOptionsBurned] = useState(getOptions([], [], ""));

const [seriesRewards, setSeriesRewards] = useState([
  {
    name: "series-4",
    data: [5, 6]
  }
]);
const [optionsRewards, setOptionsRewards] = useState(getOptions([], [], ""));

const [seriesStakersAndHolders, setSeriesStakersAndHolders] = useState([
  {
      name: 'Stakers',
      data: [31, 40, 28, 51, 42, 109, 100]
    }, 
    {
      name: 'Holders',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
]);

const [optionsStakersAndHolders, setOptionsStakersAndHolders] = useState(getOptions([], [], ""));

  const dayFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("1D").classList.remove('button_normal');
    document.getElementById("1D").classList.add('button_chosen');
    setcurrentTimeFrameID("1D");
    setpriceChange(procentualPriceChange(dailyHistory[0]));
    setOptions(getOptions(dailyHistory[1], dailyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: dailyHistory[0]
      }
    ]);
  }

  const weekFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("7D").classList.remove('button_normal');
    document.getElementById("7D").classList.add('button_chosen');
    setcurrentTimeFrameID("7D");
    setpriceChange(procentualPriceChange(weeklyHistory[0]));
    setOptions(getOptions(weeklyHistory[1], weeklyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: weeklyHistory[0]
      }
    ]);
  }

  const monthFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("1M").classList.remove('button_normal');
    document.getElementById("1M").classList.add('button_chosen');
    setcurrentTimeFrameID("1M");
    setpriceChange(procentualPriceChange(monthlyHistory[0]));
    setOptions(getOptions(monthlyHistory[1], monthlyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: monthlyHistory[0]
      }
    ]);
  }

  const threeMonthFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("3M").classList.remove('button_normal');
    document.getElementById("3M").classList.add('button_chosen');
    setcurrentTimeFrameID("3M");
    setpriceChange(procentualPriceChange(threeMonthHistory[0]));
    setOptions(getOptions(threeMonthHistory[1], threeMonthHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: threeMonthHistory[0]
      }
    ]);
  }

  const sixMonthFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("6M").classList.remove('button_normal');
    document.getElementById("6M").classList.add('button_chosen');
    setcurrentTimeFrameID("6M");
    setpriceChange(procentualPriceChange(sixMonthHistory[0]));
    setOptions(getOptions(sixMonthHistory[1], sixMonthHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: sixMonthHistory[0]
      }
    ]);
  }

  const nineMonthFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("9M").classList.remove('button_normal');
    document.getElementById("9M").classList.add('button_chosen');
    setcurrentTimeFrameID("9M");
    setpriceChange(procentualPriceChange(nineMonthHistory[0]));
    setOptions(getOptions(nineMonthHistory[1], nineMonthHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: nineMonthHistory[0]
      }
    ]);
  }

  const allFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("All").classList.remove('button_normal');
    document.getElementById("All").classList.add('button_chosen');
    setcurrentTimeFrameID("All");
    setpriceChange(procentualPriceChange(allHistory[0]));
    setOptions(getOptions(allHistory[1], allHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: allHistory[0]
      }
    ]);
  }

  const showMoref = () => {
    if(showMore == "Show More"){
      document.getElementById("tlvday").classList.remove('notshown');
      document.getElementById("tlvmonth").classList.remove('notshown');
      document.getElementById("tlvweek").classList.remove('notshown');
      document.getElementById("tlvall").classList.remove('notshown');
      document.getElementById("tlvalllow").classList.remove('notshown');
      document.getElementById("mcday").classList.remove('notshown');
      document.getElementById("mcweek").classList.remove('notshown');
      document.getElementById("mcmonth").classList.remove('notshown');
      document.getElementById("mcalllow").classList.remove('notshown');
      document.getElementById("mcallhigh").classList.remove('notshown');
      setshowMore("Show Less");
    }else{
      setshowMore("Show More");
      document.getElementById("tlvday").classList.add('notshown');
      document.getElementById("tlvmonth").classList.add('notshown');
      document.getElementById("tlvweek").classList.add('notshown');
      document.getElementById("tlvall").classList.add('notshown');
      document.getElementById("tlvalllow").classList.add('notshown');
      document.getElementById("mcday").classList.add('notshown');
      document.getElementById("mcweek").classList.add('notshown');
      document.getElementById("mcmonth").classList.add('notshown');
      document.getElementById("mcalllow").classList.add('notshown');
      document.getElementById("mcallhigh").classList.add('notshown');
    }
  }

//Load the Price Chart for the first time
useEffect( () => {
    if(dailyHistory.length != 0){
      setpriceChange(procentualPriceChange(dailyHistory[0]));
      setOptions(getOptions(dailyHistory[1], dailyHistory[0], "Price"));

      setSeries([
        {
          name: "series-1",
          data: dailyHistory[0]
        }
      ]);
    }
  }, [dailyHistory])

  //Load the Transactions Chart for the first time
useEffect( () => {
  if(allHistoryTrans.length != 0){
    setOptionsTrans(getOptionsArea(allHistoryTrans[1], allHistoryTrans[0], "Transactions"));

    setSeriesTrans([
      {
        name: "series-1",
        data: allHistoryTrans[0]
      }
    ]);
  }
}, [allHistoryTrans])

//Load the Circulating Chart for the first time
useEffect( () => {
  if(allHistoryCirc.length != 0){
    setOptionsCirc(getOptionsArea(allHistoryCirc[1], allHistoryCirc[0], "Circulating"));

    setSeriesCirc([
      {
        name: "series-1",
        data: allHistoryCirc[0]
      }
    ]);
  }
}, [allHistoryCirc])

//Load the Total Burned Chart for the first time
useEffect( () => {
  if(allHistoryBurned.length != 0){
    setOptionsBurned(getOptionsArea(allHistoryBurned[1], allHistoryBurned[0], "Burned"));

    setSeriesBurned([
      {
        name: "series-1",
        data: allHistoryBurned[0]
      }
    ]);
  }
}, [allHistoryBurned])

//Load the Daily Rewards Chart for the first time
useEffect( () => {
  if(allHistoryRewards.length != 0){
    setOptionsRewards(getOptionsBar(allHistoryRewards[1], allHistoryRewards[0], "Rewards"));

    setSeriesRewards([
      {
        name: "series-1",
        data: allHistoryRewards[0]
      }
    ]);
  }
}, [allHistoryRewards])

//Load the Stakers and Holders Chart for the first time
useEffect( () => {
  if(allHistoryStakers.length != 0 && allHistoryHolders.length != 0){
    setOptionsStakersAndHolders(getOptionsDoublearea(allHistoryStakers[1], allHistoryStakers[0], "Stakers"));

    console.log("test" + allHistoryStakers);

    setSeriesStakersAndHolders([
  {
      name: 'Stakers',
      data: allHistoryStakers[0]
    }, 
    {
      name: 'Holders',
      data: allHistoryHolders[0]
    }
  ]);
  }
}, [allHistoryStakers, allHistoryHolders])


  var givenReferral = search.split("ref=")[1]

  useEffect(() => {
    if(isValid(givenReferral) && givenReferral){
      var completeLink = "https://socialswap.io/referral/" + givenReferral
      setrefLink(completeLink)
    }
  })
  

  const clickHandler = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  //Huge Use Effect loading all needed data from the backend
  useEffect(() => {
    fetch(backendDay).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setDailyHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendWeek).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setweeklyHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendMonth).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setMonthlyHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendThreeMonth).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setThreeMonthHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendSixMonth).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setSixMonthHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendNineMonth).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setNineMonthHistory(formatizeHistoryPrice(response));
    }).catch(e => {
      
    })
    fetch(backendAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      var standard = formatizeHistoryPrice(response);
      console.log(standard);
      standard[0].splice(1,0, 0.13, 0.1923, 0.2233, 0.233, 0.23);
      console.log(standard)
      standard[1].splice(1,0, 1616957198000, 1616970835000, 1617037353000, 1617103351000,1617157323000);
      setAllHistory(standard);
    }).catch(e => {
      
    })
    fetch(backendTransAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryTrans(formatizeHistoryTrans(response));
    }).catch(e => {
      
    })
    fetch(backendCircAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryCirc(formatizeHistoryCirc(response));
    }).catch(e => {
      
    })
    fetch(backendBurnedAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryBurned(formatizeHistoryBurned(response));
    }).catch(e => {
      
    })
    fetch(backendDailyRewardsAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryRewards(formatizeHistoryRewards(response));
      console.log("hey" + allHistoryRewards);
    }).catch(e => {
      
    })
    fetch(backendStakersAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryStakers(formatizeHistoryStakers(response));
      console.log("hey" + allHistoryStakers);
    }).catch(e => {
      console.log(e)
    })
    fetch(backendHoldersAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setAllHistoryHolders(formatizeHistoryHolders(response));
      console.log("hey" + allHistoryHolders);
    }).catch(e => {
      console.log(e);
    })
    fetch(backendIp)
      .then(
        (response) => {
          return response.json()
        }
      )
      .then((response) => {
        setPrice(response[PRICE]);
      })
      .catch(e => {
      })
      fetch(backendallTimeHigh).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeHigh(parseFloat(response["price"]).toFixed(4))
        setallHighDate(extractDate(response["timestamp"]))
      }).catch(e => {
        
      })
      fetch(backendallTimeLow).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeLow(parseFloat(response["price"]).toFixed(4));
        setallLowDate(extractDate(response["timestamp"]))
      }).catch(e => {
        console.log(e)
      })
      fetch(backenddailyHigh).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyHigh(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })
      fetch(backenddailyLow).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyLow(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })
      fetch(backendweeklyHigh).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyHigh(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })
      fetch(backendweeklyLow).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyLow(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })
      fetch(backendmonthlyHigh).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyHigh(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })
      fetch(backendmonthlyLow).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyLow(parseFloat(response["price"]).toFixed(4))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendallTimeHighTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeHighTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
        setallHighDateTLV(extractDate(response["timestamp"]));
      }).catch(e => {
        console.log(e)
      })

      fetch(backendallTimeLowTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeLowTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed2))
        setallTimeLowTLV(0.00);
      }).catch(e => {
        console.log(e)
      })

      fetch(backendmonthlyHighTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyHighTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendmonthlyLowTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyLowTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backenddailyHighTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyHighTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backenddailyLowTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyLowTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendweeklyHighTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyHighTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendweeklyLowTLV).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyLowTLV(numberWithCommas(parseFloat(response["tlv"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })
      
      fetch(backendtlv).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setTLV(numberWithCommas(parseFloat(response["total_usdt"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      
      fetch(backendallTimeHighMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeHighMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
        setallHighDateMC(extractDate(response["timestamp"]));
      }).catch(e => {
        console.log(e)
      })

      fetch(backendallTimeLowMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setallTimeLowMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed2))
        setallTimeLowMC(0.00);
      }).catch(e => {
        console.log(e)
      })

      fetch(backendmonthlyHighMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyHighMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendmonthlyLowMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setmonthlyLowMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backenddailyHighMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyHighMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backenddailyLowMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setdailyLowMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendweeklyHighMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyHighMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendweeklyLowMC).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setweeklyLowMC(numberWithCommas(parseFloat(response["marketcap"]).toFixed(2)))
      }).catch(e => {
        console.log(e)
      })
    

      fetch(backendCirc).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setCirc(parseFloat(response["data"]["1"]["attributes"]["value"]))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendCircMy).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setCircMy(numberWithCommas(parseFloat(response["circulating"])))
      }).catch(e => {
        console.log(e)
      })

      fetch(backendBurned).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setBurned(response["burned"]);
      }).catch(e => {
        console.log(e)
      })

    const interval = setInterval(() => {
      fetch(backendIp)
        .then(
          (response) => {
            return response.json()
          }
        )
        .then((response) => {
          setPrice(response[PRICE]);
        })
        .catch(e => {
          console.log(e)
        })

    }, timeInterval);
    return () => clearInterval(interval);
  }, [])


  useEffect(() => {
    setROI( ((allTimeHigh/0.05) * 100).toFixed(0))
  }, [allTimeHigh, allTimeLow])

  useEffect(() => {
    setFullyDilutedMarketCap(numberWithCommas(((1000000000 - burned) * price).toFixed(2)));
  }, [price, burned])

  useEffect(() => {
    if(Circ != 0 && price != 0){
      setMarketCap(numberWithCommas(parseFloat(Circ * price).toFixed(2)));
    }
  }, [Circ, price])


  useEffect(() => {
    setpercentageDown(parseFloat((1 - (price/allTimeHigh)) * 100).toFixed(3))
    setpercentageUp(parseFloat((price/allTimeLow) * 100 - 100).toFixed(3))
  }, [allTimeHigh, price, allTimeLow])

  const dropDown = (
    <div className="dropdown">
      <a href={refLink} target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DEX</h2></div></a>
      <a href="/referrals" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Ref</h2></div></a>
      <a href="/Education" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Education</h2></div></a>
      <a href="/Marketing" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Marketing</h2></div></a>
      <a href="/Meetups" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Meetups</h2></div></a>
      <a href="https://www.probit.com/app/exchange/SST-USDT" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Probit</h2></div></a>
      <a href="https://coinpaprika.com/coin/sst-socialswap-token/" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Coinpaprika</h2></div></a>
      <a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DappRadar</h2></div></a>
      <a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Contract</h2></div></a>
    </div>
  );

  let bg = background;
  if(windoWidth < 900){
    bg = null;
  }

  return (
    <React.Fragment>
      <div>
      <Style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Montserrat');

        .chart {
          background: rgb(255, 255, 255);
          overflow: visible;
          display: block;
          width: 500px;
          height: 200px;
          padding-bottom: 50px;
        }
        #chart {
          max-width: 650px;
        }
        
        
        body {
          padding-top: 60px;
          overflow-x: hidden;
          position: relative;
          overflow-y: hidden;
        }
        html, body {
          overflow-x: hidden;
        }
         
        
        button:hover {
          background: orange;
        }
        
        .buttonLink {
          width: 200px;
          height: 80px;
          background: linear-gradient(to right, #515da8 0%, #e31e25 100%);
          color: #fff;
          margin: 0 auto 5px;
          border-radius: 50%;
          font-size: 28px;
          font-weight: bold;
          border: 0px;
        }
        
        #nav {
          position: fixed;
          top: 0;
          width: 100%;
          height: 50px;
          float: left;
          padding: 0;
          padding-top: 16px;
          list-style: none;
          background-color: white;
          border-bottom: 1px solid #ccc;
          border-top: 1px solid #ccc;
          box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .2);
          text-align: right;
          margin: 0;
          z-index: 1000;
        }
        
        #nav li {
          display: inline;
          padding: 5px 5px;
          text-decoration: none;
          font-weight: bold;
          color: grey;
          float: none;
        }
        
        #logo {
          float: left;
          padding-left: 10px;
          height: 90%
        }
        
        #selector li {
          display: inline;
          width: 20%;
        }
        
        .price {
          margin: auto;
          width: 50%;
          padding: 10px;
          text-align: center;
          font-size: larger;
          background: "blue"
        }
        
        .grid {
          opacity: 0.1;
        }
        
        .main {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: static !important;
        }
        
        .priceForm {
          overflow: visible;
        }

        .heading {
          text-align: center;
          margin: 0 0;
          margin-top: 4%;
        }
        
        .heading h1 {
          font-weight: bolder;
          font-size: 2.8rem;
          font-color: #222531;
        }
  
        
        .inputField {
          width: 150px;
          text-align: right;
        }
        
        .prices {
          font-size: 42px;
          padding: 40px
        }
        
        .prices input {
          font-size: 42px;
        }
        
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        
        input[type=number] {
          -moz-appearance: textfield;
        }
        
        .btn-primary {
          background: linear-gradient(to right, #86829e 0%, #25262b 100%);
          color: #fff;
          border: 0;
          outline: 0;
          font-size: 16px;
        }
        
        .btn {
          padding: 6px 20px;
          border-radius: 10px;
          font-size: 1.3rem;
          font-weight: bold;
        }
        
        a, u {
          text-decoration: none;
        }
        
        .Connect_button {
          color: #ffffff;
          font-family: century-gothic;
          font-size: 18px;
          font-weight: bold;
          background-image: linear-gradient(to right, #515da8 0%, #e31e25 100%);
          border: none;
          border-radius: 50px;
          padding: 4px 30px !important;
          vertical-align: text-top;
          opacity: 0.7;
          margin-top: 1px;
        }
        
        .ng-trigger-dialogContainer {
          border-radius: 20px !important;
          border: 1px solid #000 !important;
        }
        
        .Connect_button:focus {
          outline: none;
        }
        
        .referal_maincontainer {
          display: flex;
          padding-top: 120px;
        }
        
        .balance_form {
          background: #fff;
          width: 100%;
          height: 510px;
        }
        
        .form_dropdowndiv .dropdown button {
          width: 101.9%;
          border-radius: 0 50px 50px 0;
          padding: 0;
          border: none;
          background-image: linear-gradient(to right, #dadada, #b3b1b3);
          color: #000;
          border-left: 2px solid #929092;
          height: 54px;
          font-weight: bold;
          text-indent: -74px;
        }
        
        #dropdownMenuButton img {
          float: left;
          width: 26px;
        }
        
        .form_dropdowndiv .dropdown button:focus {
          outline: none;
        }
        
        .form_dropdowndiv input, .form_dropdowndiv .dropdown {
          display: inline-block;
          border: none;
          padding: 0;
          border-radius: 0 50px 50px 0;
          height: 54px;
          vertical-align: top;
        }
        
        .form_dropdowndiv .dropdown {
          width: 53%;
          overflow: hidden;
        }
        
        .form_dropdowndiv input {
          text-indent: 20px;
          width: 47%;
          font-size: 16px;
          color: #222531;
        }
        
        .form_dropdowndiv input::placeholder {
          color: #000;
        }
        
        .form_dropdowndiv input:focus {
          box-shadow: none;
          outline: none;
        }
        
        .form_dropdowndiv {
          border: 0px solid rgb(0, 0, 0);
          border-radius: 15px;
          height: 60px;
          margin-top: 8px;
          overflow: hidden;
          background: #f3f2f5;
        }
        
        .form_dropdowndiv .dropdown button img {
          width: 20px;
          margin-right: 0px;
          margin-left: 10px;
        }
        
        .balnce_inputform {
          position: relative;
        }
        
        .balnce_inputform label {
          color: #000000;
          font-weight: bold;
          font-size: 1.2rem;
          margin-left: 20px;
          margin-bottom: 8px;
        }
        
        .dropdown-toggle::after {
          content: "";
          position: absolute;
          z-index: 99;
          display: block;
          width: 8px;
          height: 8px;
          opacity: 0;
        }
        
        .to_dropdownimg {
          font-size: 12px !important;
          background-image: linear-gradient(to right, #f3abb0, #d28d97) !important;
        }
        
        .labelto {
          margin-top: 50px;
        }
        
        .arrow_images {
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
          text-align: center;
        }
        
        .arrow_images img {
          width: 30px;
          margin: 0 10px;
        }
        
        .balance_text {
          color: #e31e25;
          font-family: century-gothic;
          font-size: 24px;
          text-align: right;
          margin: 0 0 16px 0;
        }
        
        .formmaincontainerdiv {
          margin-bottom: 50px;
        }
        
        .btn_container {
          text-align: center;
          margin-top: 50px;
        }
        
        .btn_container button {
          padding: 4px 20px;
        }
        
        select.select_first {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          font-weight: bold;
          text-indent: 58px;
          color: #000;
          margin-top: 6px;
          font-size: 16px;
          width: 94%;
        }
        
        select:focus {
          background-color: transparent;
          box-shadow: none;
        }
        
        select.select_two {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          color: #000;
          font-weight: bold;
          font-size: 16px;
          margin-top: 7px;
          text-indent: 45px;
          width: 94%;
        }
        
        .marleft20px {
          margin-left: 20px !important;
        }
        
        .to_dropdownimg img {
          float: left;
          margin-left: 10px;
        }
        
        .form-group {
          margin: 0px!important;
          padding: 0px 18px 16px 18px!important;
        }
        
        @media screen and (min-width: 1022px) and (max-width: 1024px) {
          .form_dropdowndiv .dropdown button img {
            margin-right: 76px;
          }
        }
        
        @media screen and (min-width: 500px) and (max-width: 770px) {
          select.select_first {
            font-size: 12px;
          }
        }
        
        @media only screen and (max-width: 768px) {
          .referal_maincontainer {
            padding-top: 0
          }
        }
        
        @media only screen and (max-width: 767px) {
          .bg-light {
            padding: 16px 33px !important;
          }
          .card-body {
            padding: .5rem;
          }
          .form-group {
            margin: 0px!important;
            padding: 0px 18px!important;
          }
          .arrowtopbot img {
            height: 35px;
          }
          .form_dropdowndiv .dropdown button {
            font-size: 8px !important;
          }
          .tyah_button {
            font-size: 16px !important;
          }
          select.select_first {
            text-indent: 10px;
            font-size: 12px;
            padding: 0px 5px 0px 20px;
            height: 45px;
          }
          .form_dropdowndiv .dropdown button {
            margin-left: 4px;
          }
          #dropdownMenuButton img {
            width: 20px;
          }
          select.select_two {
            font-size: 12px;
            text-indent: 26px;
          }
          select.select_two {
            width: 92%;
          }
          .form_dropdowndiv .dropdown button {
            width: 99.6% !important;
          }
          .referal_maincontainer {
            display: flex;
            padding-top: 0;
            align-items: center;
          }
          .balance_text {
            font-size: 20px;
          }
          .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .arrow_images img {
            width: 20px;
            margin: 0 4px;
          }
          .formmaincontainerdiv {
            margin-bottom: 30px;
          }
          .labelto {
            margin-top: 30px;
          }
          .Connect_button {
            font-size: 16px;
          }
        }
        
        @media screen and (min-width: 200px) and (max-width: 320px) {
          select.select_first {
            text-indent: 29px;
            font-size: 10px;
          }
          .referal_maincontainer {
            height: inherit;
          }
          .form_dropdowndiv .dropdown button {
            width: 99% !important;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .form_dropdowndiv input, .form_dropdowndiv .dropdown {
            height: 48px;
          }
          .form_dropdowndiv {
            height: 54px;
          }
          .form_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        @media only screen and (max-width: 280px) {
          .form_dropdowndiv .dropdown button {
            width: 98.6% !important;
          }
          .Connect_button {
            color: #ffffff;
            font-family: century-gothic;
            font-size: 16px;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .form_dropdowndiv input, .form_dropdowndiv .dropdown {
            height: 48px;
          }
          .form_dropdowndiv {
            height: 54px;
          }
          .form_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        .coin-img {
          display: block;
        }
        
        .converter {
          width: 60%;
          margin: 0 auto;
        }
        
        .swapbutton {
          margin: auto;
          margin-top: 6%;
          background: linear-gradient(to right, #222531 0%, #222531 100%);
          color: #fff;
          border: 0;
          outline: 0;
          width:30%;
          font-size: 16px;
          padding: 6px 20px;
          border-radius: 10px;
          font-size: 1.6rem;
          font-weight: bold;
        }
        
        .swap {
          color: white;
        }
        
        .left {
          text-align: left;
        }
        
        .middlepls {
          text-align: center;
        }
        
        .currencies {
          font-size: 21px;
          font-weight: bold;  
          color: #222531;
        }
        
        .form-control {
          background: rgba(0, 0, 0, 0);
          font-weight: bold;
          color: grey;
          text-align: center;
        }
        
        .footertext {
          color: #222531;
        }
        
        .social-icon {
          width: 60px;
        }
        
        .footerdiv {
          display: flex;
          text-align: center;
        }
        
        .footerchild {
          padding-top: 20px;
          align-self: center;
          margin: auto;
          width: 250px;
          display:flex;
          justify-content: space-between;
          padding-bottom: 5%;
        }
        
        .container {
          display: inline-block;
          cursor: pointer;
        }
        
        .bar1, .bar2, .bar3 {
          width: 35px;
          height: 5px;
          background-color: #333;
          margin: 6px 0;
          transition: 0.4s;
        }
        
        /* Rotate first bar */
        .change .bar1 {
          -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
          transform: rotate(-45deg) translate(-9px, 6px) ;
        }
        
        /* Fade out the second bar */
        .change .bar2 {
          opacity: 0;
        }
        
        /* Rotate last bar */
        .change .bar3 {
          -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
          transform: rotate(45deg) translate(-8px, -8px) ;
        }
        
        .dropdown {
          text-align:center;
        }
        
        .dropdownElement {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          height: 50px;
        }
        
        .dropdownElement .text {
          font-weight: bolder;
          background: linear-gradient(to right, #a19bbf 0%, #25262b 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        .footertextmobile {
          color: rgb(71, 71, 71);
          display: none;
        }
        
        @media only screen and (max-width: 900px) {
          /* For mobile phones: */
          [class="footertext"] {
            display: none;
          }
          [class="converter"] {
            width: 100%;
          }
          [class="swapbutton"] {
            width: 50%;
            font-size: 24;
          }
          [class="price"] {
            width: 90%;
          }
        
        }
        
        @media only screen and (max-width: 300px) {
        
          [class="chart"]{
            width: 250px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 250px;
          }
        }
        
        @media only screen and (max-width: 420px) {
        
          [class="chart"]{
            width: 270px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 270px;
          }
        }
        
        .chartheading {
          text-align: center;
          margin: 0 0;
          margin-top: 3%;
        }

        .chartheadingnotfirst {
          text-align: center;
          margin: 0 0;
          margin-top: 1%;
        }

        .chartheadingnotfirst h1 {
          font-weight: bolder;
          font-color: #222531;
          font-size: 2.2rem;
        }
        
        
        .chartheading h1 {
          font-weight: bolder;
          font-color: #222531;
          font-size: 2.2rem;
        }
        
        .transferButton{
          cursor: pointer;
        }
        
        .footerheading {
          text-align: center;
          margin: 0 0;
          margin-top: 10px;
        }
        
        .footerheading h1 {
          font-weight: bolder;
          font-color: #222531;
          font-size: 2.2rem;
        }
        
        .statisticsoverview{
          text-align: center;
          margin-bottom: 50px;
        }
        
        .statscontainer{
          width:100%;
        }
        
        
        
        .newtooltip {
          color:#fff;
          background-color:rgb(121, 121, 121);
          width:12px;
          height:12px;
          display:inline-block;
          border-radius:100%;
          font-size:10px;
          text-align:center;
          text-decoration:none;
          -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
          -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
          box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
        }
        
        .tooltip {
          position: relative;
          display: inline-block;
          background: rgb(121, 121, 121);
          color: #fff;
          padding: 0.05px 5.1px;
          border-radius: 100%;
          font-size: 14px;
          font-weight: 700;
        }

        .tooltip .tooltiptextleft {
          visibility: hidden;
          width: 150px;
          background-color: rgb(218, 218, 218);
          color: rgb(37, 37, 37);
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          font-size: 15px;
          
          /* Position the tooltip */
          position: absolute;
          z-index: 1;
          bottom: 0%;
          left: 0%;
          margin-left: -150px;
        }
        
        .tooltip:hover .tooltiptextleft {
          visibility: visible;
        }
        
        .tooltip:active .tooltiptextleft {
          visibility: visible;
        }
        
        .tooltip .tooltiptext {
          visibility: hidden;
          width: 150px;
          background-color: rgb(218, 218, 218);
          color: rgb(37, 37, 37);
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          font-size: 15px;
          
          /* Position the tooltip */
          position: absolute;
          z-index: 1;
          bottom: 100%;
          left: 50%;
          margin-left: -60px;
        }
        
        .tooltip:hover .tooltiptext {
          visibility: visible;
        }
        
        .tooltip:active .tooltiptext {
          visibility: visible;
        }
        .btn-group{
        }
        .btn-group .button {
          border: 1px solid #25262b;
          color: white;
          padding: 9px 11px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          cursor: pointer;
          float: left;
          border-radius: 5px;
          margin-right: 5px;
        }
        
        .btn-group .button:not(:last-child) {
          border-right: none; /* Prevent double borders */
        }
        
        .btn-group .button:hover {
          background-color: rgba(37, 38, 43, 0.9);
        }

        .button_normal{
          background-color: #222531; /* Green */
        }
        .button_chosen{
          background-color: rgba(37, 38, 43, 0.9);
        }

        table {
          width: 100%;
          border-collapse: collapse;
          overflow: hidden;
        }
        th, td {
          padding: 15px;
          background-color: #f3f2f5;
          color: rgb(26, 26, 26);
        }
        th {
          text-align: center;
        }
        td {
          font-weight: 600;
        }
        thead th {
          background-color: #222531;
          color: #f4f4f4;
        }
        tbody tr:hover {
          background-color: rgb(97, 97, 97, 0.3);
        }
        tbody td {
          position: relative;
        }
        tbody td:hover:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: -1;
        }
         
        .showmore{
          background-color: white;
          width: 100%;
          height: 30px;
          color: black;
          /*border: 2px solid black; /* Green */*/
          background: #f3f2f5 none repeat scroll 0% 0%;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
        }

        .showmore:hover{
          background: rgb(239, 242, 245) none repeat scroll 0% 0%;
        }

        .notshown{
          display: none;
        }
        #pricechange{
          display: none;
          margin-bottom: 0px;
        }
        .flex-container {
          display: flex;
          border: 1px solid black;
          margin-bottom: 50px;
        }
        
        .flex-container > div {
          margin: 10px;
          padding: 20px;
          font-size: 30px;
        }
        .flex-item-left {
          padding: 10px;
          flex: 50%;
        }
        
        .flex-item-right {
          padding: 10px;
          flex: 50%;
        }
        @media (max-width: 1600px) {
          .flex-container {
            flex-direction: column;
            border: 0px solid black;
            margin-bottom: 0px;
          }
        }
        `}
      </Style>
      <Navbar clickHandler={clickHandler} fixer={setExpanded} referral={refLink}/>
      {expanded?dropDown:undefined}
      <div>
        <div className="heading">
          <h1> SocialSwap Token Live Price</h1>
        </div>
        <div className="price">
          <Price price={price} referral={refLink}/>
        </div>
        <div className="main">

          <div className="chartheading">
                  <h1>SocialSwap Token Chart</h1>
          </div>
          <div class="btn-group">
            <button class="button button_chosen" id="1D" onClick={dayFrame}>1D</button>
            <button class="button button_normal" id="7D" onClick={weekFrame}>7D</button>
            <button class="button button_normal" id="1M" onClick={monthFrame}>1M</button>
            <button class="button button_normal" id="3M" onClick={threeMonthFrame}>3M</button>
            <button class="button button_normal" id="6M" onClick={sixMonthFrame}>6M</button>
            <button class="button button_normal" id="9M" onClick={nineMonthFrame}>9M</button>
            <button class="button button_normal" id="All" onClick={allFrame}>All</button>
          </div>
          <h2 style={{color: "#69db6d", marginTop: "25px"}} id="pricechange">{priceChange}</h2>
          <Chart
                options={options}
                series={series}
                type="area"
                width={smallScreen?"360":"700"}
            />

      <div className="statistics">
          <div className="footerheading">
            <h1>SocialSwap Token Statistics</h1>
          </div>


          <div className="statisticsoverview">
          <div class="statscontainer">
            <table id="removetable">
              <thead>
                <tr>
                  <th>SST Statistics</th>
                  <th>SocialSwap Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Daily High/ Daily Low</td>
                  <td>{dailyHigh}$ / {dailyLow}$</td>
                </tr>
                <tr>
                  <td>Weekly High/ Weekly Low</td>
                  <td>{weeklyHigh}$ / {weeklyLow}$</td>
                </tr>
                <tr>
                  <td>Monthly High/ Monthly Low</td>
                  <td>{monthlyHigh}$ / {monthlyLow}$</td>
                </tr>
                <tr>
                  <td>All Time High ({allHighDate})</td>
                  <td>{allTimeHigh}$</td>
                </tr>
                <tr>
                  <td>All Time Low ({allLowDate})</td>
                  <td>{allTimeLow}$</td>
                </tr>
                <tr>
                  <td>SST Market Cap</td>
                  <td>{marketCap} $</td>
                </tr>
                <tr>
                  <td>SST Fully Diluted Market Cap</td>
                  <td>{fullyDilutedMarketCap} $</td>
                </tr>
                <tr>
                  <td>ROI <a className="tooltip">?<span class="tooltiptext">The approximate return on investment if purchased at the time of launch in relation to All Time High</span></a></td>
                  <td style={{color:"green"}}>{ROI}%</td>
                </tr>
                <tr>
                  <td>Circulating Supply <a className="tooltip">?<span class="tooltiptext">The amount of coins that are circulating in the market</span></a></td>
                  <td>{CircMy}</td>
                </tr>
                <tr>
                  <td>TLV <a className="tooltip">?<span class="tooltiptext">Total Locked Value on SocialSwap.io (DEX)</span></a></td>
                  <td>{TLV} $</td>
                </tr>
                <tr class="notshown" id="tlvday">
                  <td>TLV: Daily High/ Daily Low</td>
                  <td>{dailyHighTLV}$ / {dailyLowTLV}$</td>
                </tr>
                <tr class="notshown" id="tlvweek">
                  <td>TLV: Weekly High/ Weekly Low</td>
                  <td>{weeklyHighTLV}$ / {weeklyLowTLV}$</td>
                </tr>
                <tr class="notshown" id="tlvmonth">
                  <td>TLV: Monthly High/ Monthly low</td>
                  <td>{monthlyHighTLV}$ / {monthlyLowTLV}$</td>
                </tr>
                <tr class="notshown" id="tlvall">
                  <td>TLV: All Time High ({allHighDateTLV})</td>
                  <td>{allTimeHighTLV}$</td>
                </tr>
                <tr class="notshown" id="tlvalllow">
                  <td>TLV: All Time Low ({allLowDateTLV})</td>
                  <td>{allTimeLowTLV}$</td>
                </tr>
                <tr class="notshown" id="mcday">
                  <td>Marketcap: Daily High/ Daily Low</td>
                  <td>{dailyHighMC}$ / {dailyLowMC}$</td>
                </tr>
                <tr class="notshown" id="mcweek">
                  <td>Marketcap: Weekly High/ Weekly Low</td>
                  <td>{weeklyHighMC}$ / {weeklyLowMC}$</td>
                </tr>
                <tr class="notshown" id="mcmonth">
                  <td>Marketcap: Monthly High/ Monthly low</td>
                  <td>{monthlyHighMC}$ / {monthlyLowMC}$</td>
                </tr>
                <tr class="notshown" id="mcallhigh">
                  <td>Marketcap: All Time High ({allHighDateMC})</td>
                  <td>{allTimeHighMC}$</td>
                </tr>
                <tr class="notshown" id="mcalllow">
                  <td>Marketcap: All Time Low ({allLowDateMC})</td>
                  <td>{allTimeLowMC}$</td>
                </tr>
              </tbody>
            </table>
            <button className="showmore" onClick={showMoref}>{showMore}</button>
          </div>
          </div>

          </div>

        
        <div class="flex-container">

          <div class="flex-item-left">
            <div className="chartheadingnotfirst">
                  <h1>SocialSwap Total Transactions</h1>
          </div>
          <Chart
                options={optionstrans}
                series={seriestrans}
                type="area"
                width={smallScreen?"360":"700"}
            />
          </div>

            <div class="flex-item-right">
              <div className="chartheadingnotfirst">
                    <h1>SocialSwap Circulating Supply</h1>
            </div>
            <Chart
                  options={optionscirc}
                  series={seriescirc}
                  type="area"clear
                  width={smallScreen?"360":"700"}
              />
          </div>

        </div>


        {/*
        <div class="flex-item-right">
              <div className="chartheadingnotfirst">
                    <h1>SocialSwap Total Burned</h1>
            </div>
            <Chart
                  options={optionsBurned}
                  series={seriesBurned}
                  type="area"clear
                  width={smallScreen?"360":"700"}
              />
        </div>*
        <div className="flex-item-right">
            <div className="chartheadingnotfirst">
                      <h1>SocialSwap Stakers and Holders</h1>
              </div>
              <Chart
                    options={optionsStakersAndHolders}
                    series={seriesStakersAndHolders}
                    type="area"
                    width={smallScreen?"360":"700"}
              />
          </div>
        */}
        
          
             
          <div className="footer">
            <h1 className="footertext">Follow them and get the latest updates on SocialSwap.</h1>
            <div className="footerdiv">
              <div className="footerchild">
                <a target="_blank" href="https://www.youtube.com/channel/UC6gcqlRfsUuW_ZJ1BDVfycQ" rel="noreferrer"><img className="social-icon" src={youtube} alt="youtube" /></a>
                <a target="_blank" href="https://www.reddit.com/r/SocialSwap/" rel="noreferrer"><img className="social-icon" src={reddit} alt="reddit" /></a>
                <a target="_blank" href="https://t.me/socialswapioenglish" rel="noreferrer"><img className="social-icon" src={telegram} alt="telegram" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

</div>
    </React.Fragment>

  );
}

export default App;