import React, { useState } from 'react';

import logo from './sstlogo.png'

import tether from './tether.png'

import transfer from './transfer.png'

const fixFormat = (price, amount, isSST) => {
        var number = 0
        if(isSST){
            number = price * amount
        }else{
            number = amount / price
        }
        let numberAsStr = number.toString()
        if(numberAsStr==="0"){
            return "0";
        }
        const pos = numberAsStr.indexOf('.');
        if (pos < 0) {
            return numberAsStr + ".000";
        }
        if (numberAsStr.substr(pos + 1).length< 4) {
            for (let i = 0; i < 4 - numberAsStr.substr(pos + 1).length; ++i) {
                numberAsStr += "0";
            }
        }
        return (parseFloat(number)).toFixed(4);
    
}

const Price = (props) => {
    const [sstNum, setSstNum] = useState(1);
    const[leftPic, setleftPic] = useState(logo)
    const[rightPic, setrightPic] = useState(tether)
    const[leftText, setleftText] = useState("SST")
    const[rightText, setrightText] = useState("USD")
    const[isSST, setisSST] = useState(true)

    const onChangeHandler = (event) => {
        if (isNaN(+event.target.value)) {
            return;
        }
        if (!event.target.value) {
            setSstNum(0);
        } else if (sstNum === 0) {
            if (event.target.value.startsWith("0")) {
                setSstNum(event.target.value.substr(1));
            }else{
                setSstNum(event.target.value.substr(0,1))
            }

        } else {
            setSstNum(event.target.value)
        }

    }

    const convertCurrency = (event) => {
        const holdPic = leftPic
        const holdText = leftText
        setleftText(rightText)
        setrightText(holdText)
        setleftPic(rightPic)
        setrightPic(holdPic)
        setisSST(!isSST)
    }

    return (
        <div>
            <div className="converter">
                <div className="currencies"><img src={leftPic} width="18" alt="SST" />&nbsp;{leftText}&nbsp;&nbsp;<img className="transferButton" src={transfer} width="23" alt="usd" onClick={convertCurrency}/>&nbsp;&nbsp;{rightText}&nbsp;<img src={rightPic} width="18" alt="usd" /></div>
                <div className="form_dropdowndiv">
                    <input type="text" className="form-control middlepls" value={sstNum} onChange={onChangeHandler} />
                    <div className="dropdown">
                        <input type="text" className="form-control" value={fixFormat(props.price, sstNum, isSST)} />
                    </div>
                </div>
            </div>
            <a href={props.referral} target="_blank">
            <div className="swapbutton">
                <a className="swap" href={props.referral} target="_blank" rel="noreferrer">Go To Swap</a>
            </div>
            </a>
        </div>
    );
}

export default Price;