import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import home from "./App"
import ref from "./Refpage"
import funnel1 from "./Funnel_1"
import funnel3 from "./Funnel_3"
import funnel4 from "./Funnel_4"
import funnel4_1 from "./Funnel_4_1"
import IntroPage from "./IntroPage"
import StepByStepPage from "./StepByStepPage"
import Marketing from "./Marketing"
import Education from "./Education"
import Marketingdownloads from "./MarketingDownloads"
import Marketingpdfs from "./Marketingpdf"
import Marketingmemes from "./Marketingmemes"
import Marketingsticker from "./Marketingsticker"
import Marketinginstagram from "./MarketingInstagram"
import Marketingvideos from"./MarketingVideos"
import Meetups from "./Meetups"
import Login from "./Login"
import Dashboard from "./Dashboard"
import Innerpdf from "./Innerpdfs"
import Innervideos from "./Innervideos"
import Innerinterviews from "./Innerinterviews"
import Inneraccounts from "./Inneraccounts"
import Guide from "./Guide"
import International from "./International"

import Panel from "./Panel"
import Facts from './Facts';

const rootElement = document.getElementById("root");
    ReactDOM.render(
      <BrowserRouter>
       <Switch>
        <Route exact path="/" component={home} />
        <Route exact path="/referrals" component={ref}></Route>
        <Route path="/f2/ref/:id" component={IntroPage} />
        <Route path="/stepbytep/ref/:id" component={StepByStepPage} />
        <Route path="/f2" component={IntroPage} />
        <Route path="/stepbytep" component={StepByStepPage} />
        <Route exact path="/f1" component={funnel1}></Route>
        <Route exact path="/f3" component={funnel3}></Route>
        <Route exact path="/f4" component={funnel4}></Route>
        <Route exact path="/f41" component={funnel4_1}></Route>
        <Route exact path="/Marketing" component={Marketing}></Route>
        <Route exact path ="/Education" component={Education}></Route>
        <Route exact path ="/Marketingdownloads" component={Marketingdownloads}></Route>
        <Route exact path ="/Marketingvideos" component={Marketingvideos}></Route>
        <Route exact path="/Marketingpdf" component={Marketingpdfs}></Route>
        <Route exact path="/Marketingmemes" component={Marketingmemes}></Route>
        <Route exact path="/Marketingbanner" component={Marketingsticker}></Route>
        <Route exact path="/Marketinginstagram" component={Marketinginstagram}></Route>
        <Route exact path="/Meetups" component={Meetups}></Route>
        <Route exact path="/Innerlogin" component={Login}></Route>
        <Route exact path="/Dashboard" component={Dashboard}></Route>
        <Route exact path="/Innerpdf" component={Innerpdf}></Route>
        <Route exact path="/Innervideo" component={Innervideos}></Route>
        <Route exact path="/Innerinterviews" component={Innerinterviews}></Route>
        <Route exact path="/Inneraccounts" component={Inneraccounts}></Route>
        <Route exact path="/pannel123pannel" component={Panel}></Route>
        <Route exact path="/International" component={International}></Route>
        <Route exact path="/Facts" component={Facts}></Route>
      </Switch>
      </BrowserRouter>,
      rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
