import React, { useState, useLayoutEffect, useEffect } from 'react';
import logo from './static/images/socialswap-logo.png';

import Style from "style-it"

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Navbar = (props) => {
    const [width, height] = useWindowSize();
    var curReferral = "";
    var refoninfo = "/"
    if(props.id != null){
        curReferral = props.referral + props.id;
        refoninfo = "/?ref=" + props.id
    }else{
        curReferral = props.referral;
    }

    {/*const showAbout = () => {
        document.getElementById("sub").style.display = "block"; 
    }*/}

    var scrolling = true;

    const toggleNavigation = (event) =>  {
        var body = document.getElementsByTagName('body')[0];
        event.preventDefault();
        body.classList.toggle('nav-open');
        var container = document.getElementById("navcross");
        container.classList.toggle("change");
        //var table = document.getElementById("removetable");
        //table.classList.add("hide");
        if(scrolling){
          scrolling = false;
        }else{
          scrolling = true;
        }
        var htmlTags = document.getElementsByTagName("html");
        htmlTags[0].classList.toggle("stop-scrolling-mobile");
      }

      function disableScrolling(){
        var x=window.scrollX;
        var y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};
    }
    
    function enableScrolling(){
        window.onscroll=function(){};
    }

    let items =
        (<React.Fragment>
            <li><a className="btn btn-primary navbarbutton" href={curReferral} target="_blank" rel="noreferrer">DEX</a></li>
            {
                !props.id 
                ? (
                    <li><a className="btn btn-primary navbarbutton" href="/referrals" rel="noreferrer">Ref</a></li>
                ) 
                : false
            } 
            <li><a className="btn btn-primary navbarbutton" href="/Education" rel="noreferrer">Education</a></li>
            <li><a className="btn btn-primary navbarbutton" href="/Marketing" rel="noreferrer">Marketing</a></li>
            <li><a className="btn btn-primary navbarbutton" href="/Meetups" rel="noreferrer">Meetups</a></li>
            <li><a className="btn btn-primary navbarbutton" href="https://www.probit.com/app/exchange/SST-USDT" target="_blank" rel="noreferrer">Probit</a></li>
            <li><a className="btn btn-primary navbarbutton" href="https://coinpaprika.com/coin/sst-socialswap-token/" target="_blank" rel="noreferrer">Coinpaprika</a></li>
            <li><a className="btn btn-primary navbarbutton" href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer">DappRadar</a></li>
            <li><a className="btn btn-primary navbarbutton" href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer">Contract</a></li>
        </React.Fragment>);
    if (width < 900) {
        items = (
            <li>
                <div class="container" onClick={props.clickHandler}>
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </li>);
    }else{
        props.fixer(false);
    }
    return (
        <ul id="nav">
            <Style>
                {`

.nav-open main {
transform: scale(.8);
}

.intro {
height: 100vh;
width: 100%;
display: table;
background: #26292f url('../img/bg.jpg') no-repeat center center;
background-size: cover;
}

.nav-trigger {
}

.nav-trigger span,
.nav-trigger span::before,
.nav-trigger span::after {
position: absolute;
height: 4px;
width: 36px;
background: #999999;
}

.nav-trigger span {
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
transition: background .3s;
}

.nav-trigger span::before,
.nav-trigger span::after {
content: '';
top: 0;
left: 0;
transition: background .3s, transform .3s;
}

.nav-trigger span::before {
transform: translateY(-12px);
}

.nav-trigger span::after {
transform: translateY(12px);
}


.nav-open .nav-trigger span {
background: transparent;
}

.nav-open .nav-trigger span::before,
.nav-open .nav-trigger span::after {
background: #1F2B3D;
}

.nav-open .nav-trigger span::before {
transform: rotate(-45deg);
}

.nav-open .nav-trigger span::after {
transform: rotate(45deg);
}

.overlay {
position: fixed;
z-index: 2;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #1C1D21;
opacity: 0;
visibility: hidden;
transition: opacity .5s, visibility .5s;
}

.nav-open .overlay {
opacity: .6;
visibility: visible;
}

.nav-container {
position: fixed;
z-index: 1000;
top: 0;
right: 0;
height: 100%;
width: 345px;
max-width: 500px;
padding: 0 0;
margin-top: 68px;
background: white;
overflow: auto;
transform: translateZ(0);
transform: translateX(100%);
transition: transform .5s cubic-bezier(.07,.23,.34,1);
border-left: 1px solid #222531;
background-color: white;
}

.nav-open .nav-container {
transform: translateX(0);
}

.nav-container h2 {
font-size: 15px;
font-weight: bold;
text-transform: uppercase;
color: #AAAAAA;
margin: 1.5em 0;
}

.nav {
list-style: none;
padding: 0;
background: inherit;
z-index:10;
}

.nav a {
display: block;
color: #222531;
transform: translateZ(0);
}

.nav-open .nav a {
animation: slide-in .4s .2s backwards;
}

.nav-open .nav li:nth-of-type(2) a {
animation-delay: .3s;
}

.nav-open .nav li:nth-of-type(3) a {
animation-delay: .4s;
}

.nav-open .nav li:nth-of-type(4) a {
animation-delay: .5s;
}

.nav-open .nav li:nth-of-type(5) a {
animation-delay: .6s;
}

.nav-open .nav li:nth-of-type(6) a {
animation-delay: .7s;
}
.nav-open .nav li:nth-of-type(7) a {
animation-delay: .8s;
}
.nav-open .nav li:nth-of-type(8) a {
animation-delay: .9s;
}
.nav-open .nav li:nth-of-type(9) a {
animation-delay: 1s;
}

.hide{
display: none;
}



@media only screen and (max-width: 600px) {
.nav a {
  font-size: 30px;
}

.nav-container {
  width: 100%;
  height: 100%;
}

#nav{
  position: absolute;
}

.stop-scrolling-mobile {
  overflow: hidden;
}

}

.headingnav {
  text-align: center;
  margin: 0 0;
  margin-top: 0%;
}

.headingnav h1 {
  font-size: 1.3rem;
  padding: 0 0;
  margin: 0 0;
  font-weight: bolder;
  font-color: black;
}
hr {
  width: 98%;
}

.hamburgler {
  width: 150px;
  height: 150px;
  display: block;
  transition: 0.3s ease all;
}
.hamburgler.no-hamburgler {
  transform: rotate(-45deg);
}
.bun, .meat {
  display: block;
  width: 100%;
  background: #cb4b1f;
  height: 20%;
  transition: 0.3s ease all;
  border-radius: 50px;
}
.no-hamburgler .top {
  height: 38%;
  width: 20%;
  margin-left: 40%;
  border-radius: 50px 50px 0 0;
}
.no-hamburgler .bottom {
  height: 38%;
  width: 20%;
  margin-left: 40%;
  border-radius: 0 0 50px 50px;
}
.meat {
  margin: 20% 0;
}
.no-hamburgler .meat {
  margin: 2% 0;
}
                        
                `}
            </Style>
            <a href={refoninfo}> <li><img src={logo} id="logo" alt="logo" /></li> </a>

            <li>
                <div class="container" id="navcross" onClick={toggleNavigation}>
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </li>
            

            <nav class="nav-container" id="navigation">
                <header className="hide">
                    <h1 style={{fontSize: "45px", cursor: "pointer"}} onClick={toggleNavigation}>X</h1>
                </header>

                <ul class="nav">
                    <li class="headingnav"><h1><a href={curReferral} target="_blank">SocialSwap DEX</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="/referrals">Referral</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="/Education">Education</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="/Marketing">Marketing</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="/Meetups">Meetups</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="/International">International</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://www.socialswap-shop.io/" target="_blank">Onlineshop</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://www.probit.com/app/exchange/SST-USDT" target="_blank">Probit</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://coinpaprika.com/coin/sst-socialswap-token/" target="_blank" rel="noreferrer">Coinpaprika</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer">DappRadar</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer">Contract</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://t.me/socialswapdefi" target="_blank" rel="noreferrer">Support</a></h1></li>
                    <hr></hr>
                    <li class="headingnav"><h1><a href="https://t.me/socialswapdefi" target="_blank" rel="noreferrer"></a></h1></li>
                </ul>
            </nav>
            {/*items */}
        </ul>)
}

export default Navbar;