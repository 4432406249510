import React, { useEffect, useState, useLayoutEffect } from "react";
import Axios from "axios"
import Style from "style-it"

export default function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [registerText, setregisterText] = useState("Registration was not successful.");

  const PORT = process.env.PORT || 5000;

  Axios.defaults.withCredentials = true;

  const register = () => {
    Axios.post('/register',
    {
      email: registerEmail,
      password: registerPassword,
    })
    .then((response) => {
      if(response.data.success){
        setregisterText("Successfully registered a new user: " + registerEmail);
       setRegisterEmail("");
       setRegisterPassword("");
       var x = document.getElementById("registration");
       x.style.display = "block";
      }else{
        setregisterText("Registration was not successful.");
      var x = document.getElementById("registration");
       x.style.display = "block";
      }
    }, (error) => {
      setregisterText("Registration was not successful.");
      var x = document.getElementById("registration");
       x.style.display = "block";
    });
  };

  const login = () => {
    Axios.post('/login',
    {
      email: email,
      password: password,
    })
    .then((response) => {
      if(response.data.message){
        var x = document.getElementById("wrongcombination");
        x.style.display = "block";
      }else{
        var x = document.getElementById("wrongcombination");
        x.style.display = "none";
        window.location.href = '/dashboard';
      }
    }, (error) => {
      console.log(error);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login();
    }
  }

  const showLogin = () => {
    var x = document.getElementById("loginpassword");
    var y = document.getElementById("log");
    if (y.checked === true) {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const showRegister = () => {
    var x = document.getElementById("registerpassword");
    var y = document.getElementById("reg");
    if (y.checked === true) {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  useEffect(() => {
    Axios.get('/login').then( (response) => {
      if(response.data.loggedIn == true && response.data.user[0].admin == 0){
        window.location.href="/dashboard";
      }else if(response.data.user[0].admin == 1){
        setIsAdmin(true);
      }
    }, (error)=>{
      console.log(error);
    })
  }, [])


  return (
    <React.Fragment>
      <Style>
        {`
        html,body {
          height:100%;
          width:100%;
          margin:0;
        }
        body {
          display:flex;
        }
        
        .social-login img {
          width: 24px;
        }
        a {
          text-decoration: none;
        }
        
        #root {
          margin-left: auto;
          margin-right: auto;
          margin-top: 5%;
        }
        
        .card {
          font-family: sans-serif;
          width: 300px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 3em;
          margin-bottom:3em;
          border-radius: 10px;
          background-color: #ffff;
          padding: 1.8rem;
          box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
        }
        
        .title {
          text-align: center;
          font-weight: bold;
          margin: 0;
        }
        .subtitle {
          text-align: center;
          font-weight: bold;
        }
        .btn-text {
          margin: 0;
        }
        
        .social-login {
          display: flex;
          justify-content: center;
          gap: 5px;
        }
        
        .google-btn {
          background: #fff;
          border: solid 2px rgb(245 239 239);
          border-radius: 8px;
          font-weight: bold;
          display: flex;
          padding: 10px 10px;
          flex: auto;
          align-items: center;
          gap: 5px;
          justify-content: center;
        }
        .fb-btn {
          background: #fff;
          border: solid 2px rgb(69, 69, 185);
          border-radius: 8px;
          padding: 10px;
          display: flex;
          align-items: center;
        }
        
        .or {
          text-align: center;
          font-weight: bold;
          border-bottom: 2px solid rgb(245 239 239);
          line-height: 0.1em;
          margin: 25px 0;
        }
        .or span {
          background: #fff;
          padding: 0 10px;
        }
        
        .email-login {
          display: flex;
          flex-direction: column;
        }
        .email-login label {
          color: rgb(170 166 166);
        }
        
        input[type="text"],
        input[type="password"] {
          padding: 15px 20px;
          margin-top: 8px;
          margin-bottom: 15px;
          border: 1px solid #ccc;
          border-radius: 8px;
          box-sizing: border-box;
        }
        
        .cta-btn {
          background-color: black;
          color: white;
          padding: 18px 20px;
          margin-top: 10px;
          margin-bottom: 20px;
          width: 100%;
          border-radius: 10px;
          border: none;
          cursor: pointer;
          font-size: 15px;
        }
        
        .forget-pass {
          text-align: center;
          display: block;
        }
        
        .heading {
          text-align: center;
          margin: 0 0;
          margin-top: 4%;
        }
        
        .heading h1 {
          font-weight: bolder;
          font-size: 2.8rem;
          font-color: #222531;
        }
        
        .errormessage {
          text-align: center;
          color: red;
          display: none;
        }
        .showpass{
          margin-bottom:50px;
        }
        `}
      </Style>
      <h1 className="heading">SocialSwapInfo.io Inner Circle Login</h1>
      <div className="card">
        <h2 className="title">Log in</h2>
        <div className="email-login">
          <label htmlFor="email"> <b>Username</b></label>
          <input type="text" placeholder="Enter Username" name="uname" required value={email} onChange={(e) => {setEmail(e.target.value)}}/>
          <label htmlFor="psw"><b>Password</b></label>
          <input type="password" placeholder="Enter Password" name="psw" required value={password} onKeyDown={handleKeyDown} onChange={(e) => {setPassword(e.target.value)}} id="loginpassword"/>
          <label for="pass" id="showpass"><input type="checkbox" id="log" onClick={showLogin}></input>Show password</label>
        </div>
        <p className="errormessage" id="wrongcombination">Wrong Email or Password</p>
        <button className="cta-btn" onClick={login}>Log In</button>
        {/*<a className="forget-pass" href="#">Forgot password?</a>*/}
      </div>
      
      {isAdmin?<div className="card">
          <h2 className="title">Register</h2>
          <div className="email-login">
            <label htmlFor="email"> <b>Username</b></label>
            <input type="text" placeholder="Enter Username" name="uname" required value={registerEmail} onChange={(e) => {setRegisterEmail(e.target.value)}}/>
            <label htmlFor="psw"><b>Password</b></label>
            <input type="password" placeholder="Enter Password" name="psw" required value={registerPassword} onChange={(e) => {setRegisterPassword(e.target.value)}} id="registerpassword"/>
            <label for="pass" id="showpass"><input type="checkbox" id="reg" onClick={showRegister}></input>Show password</label>
          </div>
          <p className="errormessage" id="registration">{registerText}</p>
          <button className="cta-btn" onClick={register}>Register</button>
        </div>:undefined}

    </React.Fragment>
  )
}