
import React, { useEffect, useState, useLayoutEffect } from "react";
import Price from './Price';
import Navbar from './Navbar';
import background from './static/images/background.png'
import telegram from './static/images/telegram2.png'
import youtube from './static/images/youtube2.png'
import reddit from './static/images/reddit2.png'
import {useLocation} from "react-router-dom"
import Style from "style-it"
import Chart from "react-apexcharts"
import {useMediaQuery} from "react-responsive"


const timeInterval = 10000;
const PRICE = "price"


const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function isValid(input) {
  var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
  if (pattern.test(input)) {
      return false;
  }
  return true; 
  
  //good user input
}

function max(input) {
  if (toString.call(input) !== "[object Array]")  
    return false;
return Math.max.apply(null, input);
}

function min(input) {
  if (toString.call(input) !== "[object Array]")  
    return false;
return Math.min.apply(null, input);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatizeHistoryPrice(data){
  if(data["prices"][0] && data["prices"][0]["timestamp"] && data["prices"][0]["sstprice"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["prices"].length;i++){
      timestamps.push(parseInt(data["prices"][i]["timestamp"]));
      prices.push(data["prices"][i]["sstprice"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryTrans(data){
  if(data["transactions"][0] && data["transactions"][0]["timestamp"] && data["transactions"][0]["transactions"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["transactions"].length;i++){
      timestamps.push(parseInt(data["transactions"][i]["timestamp"]));
      prices.push(data["transactions"][i]["transactions"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}

function formatizeHistoryCirc(data){
  if(data["circulating"][0] && data["circulating"][0]["timestamp"] && data["circulating"][0]["circsupply"]){
    var timestamps = [];
    var prices = [];
    for(var i=0;i<data["circulating"].length;i++){
      timestamps.push(parseInt(data["circulating"][i]["timestamp"]));
      prices.push(data["circulating"][i]["circsupply"]);
    }
    var result = [];
    result.push(prices);
    result.push(timestamps);
    return result;
  }else{
    return [];
  }
}


function procentualPriceChange(prices){
  if(prices[0] > prices[prices.length -1]){
    document.getElementById("pricechange").style.color = "red";
    var difference = ((1 - (prices[prices.length - 1]/prices[0]))*100).toFixed(2);
    return("-" + difference + "%");
  }else{
    document.getElementById("pricechange").style.color = "#69db6d";
    var difference = (((prices[prices.length -1]/prices[0]) - 1) * 100).toFixed(2);
    return("+" + difference + "%");
  }
}


function extractDate(timestamp){
  const dateObject = new Date(parseInt(timestamp))
  const day = dateObject.toLocaleString("en-US", {day: "numeric"})
  const month = dateObject.toLocaleString("en-US", {month: "numeric"})
  const year = dateObject.toLocaleString("en-US", {year: "numeric"})
  const completeDate = day + "." + month + "." + year
  return completeDate;
}

function getOptions(timestamps, prices, showTipName){
  return {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false, //Possibility to allow zooming
          type: 'x',  
          autoScaleYaxis: false,  
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
      }
      },
      colors:['#90ee90'],  
      xaxis: {
        type: 'datetime',
        categories: timestamps,
        labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 0,
            offsetY: 0
        },
        tickAmount: undefined,
        tickPlacement: 'between',
        min: undefined,
        max: undefined,
        range: undefined,
        floating: false,
        decimalsInFloat: undefined,
        position: 'bottom',
        title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-xaxis-title',
            },
        },
        crosshairs: {
            show: true,
            width: 1,
            position: 'back',
            opacity: 0.9,        
            stroke: {
                color: '#b6b6b6',
                width: 0,
                dashArray: 0,
            },
            fill: {
                type: 'solid',
                color: '#B1B9C4',
                gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                },
            },
            dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 1,
                opacity: 0.4,
            },
        },
        tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0,
            },
        },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: false,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      tickAmount: 6,
      min: min(prices) * 0.98,
      max: max(prices) * 1.01,
      forceNiceScale: false,
      floating: false,
      decimalsInFloat: 3,
      labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => { 
            if(parseInt(value) >= 1000){
              return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           } else {
              return  parseFloat(value).toFixed(3);
           }  
          },
      },
      axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
      },
      axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
      },
      title: {
          text: undefined,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
          },
      },
      crosshairs: {
          show: true,
          position: 'back',
          stroke: {
              color: '#b6b6b6',
              width: 1,
              dashArray: 0,
          },
      },
  },
  tooltip: {
    enabled: true, //Enable and disable tooltips
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    fillSeriesColor: false,
    theme: false,
    style: {
      fontSize: '12px',
      fontFamily: undefined
    },
    onDatasetHover: {
        highlightDataSeries: false,
    },
    x: {
        show: true, //Show date on tooltip
        format: 'dddd, MMMM d, yyyy, hh:mm TT G\\M\\T', //HH:mm
        formatter: undefined,
    },
    y: {
        formatter: function(value){
          if(parseInt(value) >= 1000){
            return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
         } else {
            return  value.toFixed(4);
         }  
        },
        title: {
            formatter: (seriesName) => showTipName,
        },
    },
    z: {
        formatter: undefined,
        title: 'Size: '
    },
    marker: {
        show: true,
    },
    items: {
      display: "flex",
    },
    fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
    },
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'round',
    colors: undefined,
    width: 3.8,
    dashArray: 0,      
  }

}
}

const Facts = () => {
  const [data, setData] = useState([]);
  const [price, setPrice] = useState("N/A");
  const [expanded, setExpanded] = useState(false);
  const [windoWidth, windowHeight] = useWindowSize();
  const [allTimeHigh, setallTimeHigh] = useState(0)
  const [allTimeLow, setallTimeLow] = useState(0)
  const [refLink, setrefLink] = useState("https://www.socialswap.io/")
  const {search} = useLocation()
  const [showMore, setshowMore] = useState("Show More");

  const [currentTimeFrameID, setcurrentTimeFrameID] = useState("1D");
  const [dailyHistory, setDailyHistory] = useState([]);
  const [weeklyHistory, setweeklyHistory] = useState([]);
  const [monthlyHistory, setMonthlyHistory] = useState([]);
  const [allHistory, setAllHistory] = useState([]);

  const[allHistoryTrans, setAllHistoryTrans] = useState([]);

  const[allHistoryCirc, setAllHistoryCirc] = useState([]);

  const smallScreen = useMediaQuery({ maxWidth: 800 })

  const [series, setSeries] = useState([
        {
          name: "series-1",
          data: [5, 6]
        }
      ]);
  const [options, setOptions] = useState(getOptions([], [], ""));

  const [seriestrans, setSeriesTrans] = useState([
    {
      name: "series-2",
      data: [5, 6]
    }
  ]);
const [optionstrans, setOptionsTrans] = useState(getOptions([], [], ""));

const [seriescirc, setSeriesCirc] = useState([
  {
    name: "series-3",
    data: [5, 6]
  }
]);
const [optionscirc, setOptionsCirc] = useState(getOptions([], [], ""));

  const dayFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("1D").classList.remove('button_normal');
    document.getElementById("1D").classList.add('button_chosen');
    setcurrentTimeFrameID("1D");
    setOptions(getOptions(dailyHistory[1], dailyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: dailyHistory[0]
      }
    ]);
  }

  const weekFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("7D").classList.remove('button_normal');
    document.getElementById("7D").classList.add('button_chosen');
    setcurrentTimeFrameID("7D");
    setOptions(getOptions(weeklyHistory[1], weeklyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: weeklyHistory[0]
      }
    ]);
  }

  const monthFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("1M").classList.remove('button_normal');
    document.getElementById("1M").classList.add('button_chosen');
    setcurrentTimeFrameID("1M");
    setOptions(getOptions(monthlyHistory[1], monthlyHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: monthlyHistory[0]
      }
    ]);
  }

  const allFrame = () => {
    document.getElementById(currentTimeFrameID).classList.remove('button_chosen');
    document.getElementById(currentTimeFrameID).classList.add('button_normal');
    document.getElementById("All").classList.remove('button_normal');
    document.getElementById("All").classList.add('button_chosen');
    setcurrentTimeFrameID("All");
    setOptions(getOptions(allHistory[1], allHistory[0], "Price"))
    setSeries([
      {
        name: "series-1",
        data: allHistory[0]
      }
    ]);
  }

  const showMoref = () => {
    if(showMore == "Show More"){
      document.getElementById("tlvday").classList.remove('notshown');
      document.getElementById("tlvmonth").classList.remove('notshown');
      document.getElementById("tlvweek").classList.remove('notshown');
      document.getElementById("tlvall").classList.remove('notshown');
      document.getElementById("tlvalllow").classList.remove('notshown');
      setshowMore("Show Less");
    }else{
      setshowMore("Show More");
      document.getElementById("tlvday").classList.add('notshown');
      document.getElementById("tlvmonth").classList.add('notshown');
      document.getElementById("tlvweek").classList.add('notshown');
      document.getElementById("tlvall").classList.add('notshown');
      document.getElementById("tlvalllow").classList.add('notshown');
    }
  }

//Load the Price Chart for the first time
useEffect( () => {
    if(dailyHistory.length != 0){
      setOptions(getOptions(dailyHistory[1], dailyHistory[0], "Price"));

      setSeries([
        {
          name: "series-1",
          data: dailyHistory[0]
        }
      ]);
    }
  }, [dailyHistory])

  //Load the Transactions Chart for the first time
useEffect( () => {
  if(allHistoryTrans.length != 0){
    setOptionsTrans(getOptions(allHistoryTrans[1], allHistoryTrans[0], "Transactions"));

    setSeriesTrans([
      {
        name: "series-1",
        data: allHistoryTrans[0]
      }
    ]);
  }
}, [allHistoryTrans])

//Load the Circulating Chart for the first time
useEffect( () => {
  if(allHistoryCirc.length != 0){
    setOptionsCirc(getOptions(allHistoryCirc[1], allHistoryCirc[0], "Circulating"));

    setSeriesCirc([
      {
        name: "series-1",
        data: allHistoryCirc[0]
      }
    ]);
  }
}, [allHistoryCirc])


  var givenReferral = search.split("ref=")[1]

  useEffect(() => {
    if(isValid(givenReferral) && givenReferral){
      var completeLink = "https://socialswap.io/referral/" + givenReferral
      setrefLink(completeLink)
    }
  })
  

  const clickHandler = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  //Huge Use Effect loading all needed data from the backend
  useEffect(() => {
    
  }, [])



  const dropDown = (
    <div className="dropdown">
      <a href={refLink} target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DEX</h2></div></a>
      <a href="/referrals" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Ref</h2></div></a>
      <a href="/Education" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Education</h2></div></a>
      <a href="/Marketing" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Marketing</h2></div></a>
      <a href="/Meetups" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Meetups</h2></div></a>
      <a href="https://www.probit.com/app/exchange/SST-USDT" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Probit</h2></div></a>
      <a href="https://coinpaprika.com/coin/sst-socialswap-token/" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Coinpaprika</h2></div></a>
      <a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DappRadar</h2></div></a>
      <a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Contract</h2></div></a>
    </div>
  );

  let bg = background;
  if(windoWidth < 900){
    bg = null;
  }

  return (
    <React.Fragment>
      <div>
      <Style>
        {`
       
        `}
      </Style>
      <Navbar clickHandler={clickHandler} fixer={setExpanded} referral={refLink}/>
      {expanded?dropDown:undefined}
      <div>
        
      </div>

    </div>
    </React.Fragment>

  );
}

export default Facts;