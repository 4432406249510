
import React, { useEffect, useState, useLayoutEffect } from "react";
import Price from './Price';
import Navbar from './Navbar';
import background from './static/images/background.png'
import telegram from './static/images/telegram2.png'
import youtube from './static/images/youtube2.png'
import reddit from './static/images/reddit2.png'
import BarChart from './BarChart';
import Style from "style-it"

const backendIp = "https://socialswapback.herokuapp.com/data/recent";
const backendAll = "https://socialswapback.herokuapp.com/data/all";
const timeInterval = 10000;
const PRICE = "price";


const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Refpage = () => {
  const [data, setData] = useState([]);
  const [price, setPrice] = useState("N/A");
  const [expanded, setExpanded] = useState(false);
  const [refLink, setrefLink] = useState("")
  const [generatedRef, setgeneratedRef] = useState("Please generate Reflink")

  const [linkCreated, setlinkCreated] = useState(false)
  const [linkCreated2, setlinkCreated2] =useState(false)
  const [linkCreated3, setlinkCreated3] = useState(false)
  const [linkCreated4, setlinkCreated4] = useState(false)
  const [linkCreated5, setlinkCreated5] = useState(false)
  const [linkCreated6, setlinkCreated6] = useState(false)

  const [linkText, setlinkText] = useState("Pro Funnel")
  const [linkText2, setlinkText2] = useState("Beginner Funnel")
  const [linkText3, setlinkText3] = useState("English Funnel")
  const [linkText4, setlinkText4] = useState("Sven Funnel")
  const [linkText5, setlinkText5] = useState("Dennis Funnel")
  const [linkText6, setlinkText6] = useState("Cryptvest Funnel")

  const [windoWidth, windowHeight] = useWindowSize();
  const [previewText, setpreviewText] = useState("Generate Link First")
  const [previewLink, setpreviewLink] = useState("#")
  

  const clickHandler = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  useEffect(() => {
    fetch(backendAll).then(
      (response) => {
        return response.json();
      }
    ).then((response)=> {
      setData(response["prices"])
    }).catch(e => {
      
    })
    fetch(backendIp)
      .then(
        (response) => {
          return response.json()
        }
      )
      .then((response) => {
        setPrice(response[PRICE]);
      })
      .catch(e => {
      })
    const interval = setInterval(() => {
      fetch(backendAll).then(
        (response) => {
          return response.json();
        }
      ).then((response)=> {
        setData(response["prices"])
      }).catch(e => {
        
      })
      fetch(backendIp)
        .then(
          (response) => {
            return response.json()
          }
        )
        .then((response) => {
          setPrice(response[PRICE]);
        })
        .catch(e => {
          console.log(e)
        })
    }, timeInterval);
    return () => clearInterval(interval);
  }, [])

  const dropDown = (
    <div className="dropdown">
      <a href={refLink} target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DEX</h2></div></a>
      <a href="/referrals" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Ref</h2></div></a>
      <a href="/Marketing" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Marketing</h2></div></a>
      <a href="/Meetups" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Meetups</h2></div></a>
      <a href="https://www.probit.com/app/exchange/SST-USDT" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Probit</h2></div></a>
      <a href="https://coinpaprika.com/coin/sst-socialswap-token/" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Coinpaprika</h2></div></a>
      <a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DappRadar</h2></div></a>
      <a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Contract</h2></div></a>
    </div>
  );

  let bg = background;
  if(windoWidth < 900){
    bg = null;
  }

  const onChangeHandler = (event) => {
        if (!event.target.value) {
            setrefLink("");
        }
        setrefLink(event.target.value)
  }

  const generateQRCode = (ref) => {
    document.getElementById("qrcode").src = "https://chart.googleapis.com/chart?cht=qr&chl=" + ref + "&chs=160x160&chld=L|0";
    document.getElementById("qrcodenotshow").style.display = "none";
  }

  const refLinkCopier = (event) => {
    if(previewText != "Generate Link First"){
      var store = generatedRef;
      setgeneratedRef("Your link was copied");
      navigator.clipboard.writeText(store);
      setTimeout(function(){
        setgeneratedRef(store);
      }, 1500)
    }
  }

  const onClickHandler = (event) => {

      var address = refLink.split("referral/")[1]
      var ref = "https://funnel.socialswapinfo.io/start/?ref=" + address;

      if(linkCreated){
        navigator.clipboard.writeText(ref)
        setlinkText("Copied")
        document.getElementById("createbutton1").classList.add('refswapbuttonclicked');
        document.getElementById("createbutton1").classList.remove('refswapbutton');
        document.getElementById("createbutton1m").classList.add('refswapbuttonclicked');
        document.getElementById("createbutton1m").classList.remove('refswapbutton');

        setTimeout(function(){ 
          document.getElementById("createbutton1").classList.remove('refswapbuttonclicked');
          document.getElementById("createbutton1").classList.add('refswapbutton');
          document.getElementById("createbutton1m").classList.remove('refswapbuttonclicked');
          document.getElementById("createbutton1m").classList.add('refswapbutton');
          setlinkText("Copy Ref Link");
         }, 1500);
      }

      if(address && !linkCreated){
        setgeneratedRef(ref)
        generateQRCode(ref);
        setlinkCreated(true)
        setlinkText("Copy Ref Link");

        setlinkText3("English Funnel")
        setlinkText2("Beginner Funnel")
        setlinkText4("Sven Funnel")
        setlinkText5("Dennis Funnel")
        setlinkText6("Cryptvest Funnel")
        setlinkCreated2(false)
        setlinkCreated3(false)
        setlinkCreated4(false)
        setlinkCreated5(false)
        setlinkCreated6(false)

        setpreviewText("Preview Pro Funnel")
        setpreviewLink(ref)
        document.getElementById("previewlink").classList.remove('disablelink');
      }
  }

  const onClickHandler2 = (event) => {

    var address = refLink.split("referral/")[1]
    var ref = "https://socialswapinfo.io/f2/ref/" + address

    if(linkCreated2){
      navigator.clipboard.writeText(ref)
      setlinkText2("Copied")
      document.getElementById("createbutton2").classList.add('refswapbuttonclicked');
      document.getElementById("createbutton2").classList.remove('refswapbutton');
      document.getElementById("createbutton2m").classList.add('refswapbuttonclicked');
      document.getElementById("createbutton2m").classList.remove('refswapbutton');

      setTimeout(function(){ 
        document.getElementById("createbutton2").classList.remove('refswapbuttonclicked');
        document.getElementById("createbutton2").classList.add('refswapbutton');
        document.getElementById("createbutton2m").classList.remove('refswapbuttonclicked');
        document.getElementById("createbutton2m").classList.add('refswapbutton');
        setlinkText2("Copy Ref Link")
       }, 1500);
    }

    if(address && !linkCreated2){
      setgeneratedRef(ref)
      generateQRCode(ref);
      setlinkCreated2(true)
      setlinkText2("Copy Ref Link")

      setlinkText("Pro Funnel")
      setlinkText3("English Funnel")
      setlinkText4("Sven Funnel")
      setlinkText5("Dennis Funnel")
      setlinkText6("Cryptvest Funnel")
      setlinkCreated(false)
      setlinkCreated3(false)
      setlinkCreated4(false)
      setlinkCreated5(false)
      setlinkCreated6(false)


      setpreviewText("Preview Beginner Funnel")
      setpreviewLink(ref)
      document.getElementById("previewlink").classList.remove('disablelink');
    }
}

const onClickHandler3 = (event) => {

  var address = refLink.split("referral/")[1]
  var ref = "https://socialswapinfo.io/f3/?ref=" + address

  if(linkCreated3){
    navigator.clipboard.writeText(ref)
    setlinkText3("Copied")
    document.getElementById("createbutton3").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton3").classList.remove('refswapbutton');
    document.getElementById("createbutton3m").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton3m").classList.remove('refswapbutton');

    setTimeout(function(){ 
      document.getElementById("createbutton3").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton3").classList.add('refswapbutton');
      document.getElementById("createbutton3m").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton3m").classList.add('refswapbutton');
      setlinkText3("Copy Ref Link")
     }, 1500);
  }

  if(address && !linkCreated3){
    setgeneratedRef(ref)
    generateQRCode(ref);
    setlinkCreated3(true)
    setlinkText3("Copy Ref Link")

    setlinkText("Pro Funnel")
    setlinkText2("Beginner Funnel")
    setlinkText4("Sven Funnel")
    setlinkText5("Dennis Funnel")
    setlinkText6("Cryptvest Funnel")
    setlinkCreated(false)
    setlinkCreated2(false)
    setlinkCreated4(false)
    setlinkCreated5(false)
    setlinkCreated6(false)

    setpreviewText("Preview English Funnel")
    setpreviewLink(ref)
    document.getElementById("previewlink").classList.remove('disablelink');
  }
}

const onClickHandler4 = (event) => {

  var address = refLink.split("referral/")[1]
  var ref = "https://socialswapinfo.io/f4/?ref=" + address

  if(linkCreated4){
    navigator.clipboard.writeText(ref)
    setlinkText4("Copied")
    document.getElementById("createbutton4").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton4").classList.remove('refswapbutton');
    document.getElementById("createbutton4m").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton4m").classList.remove('refswapbutton');

    setTimeout(function(){ 
      document.getElementById("createbutton4").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton4").classList.add('refswapbutton');
      document.getElementById("createbutton4m").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton4m").classList.add('refswapbutton');
      setlinkText4("Copy Ref Link")
     }, 1500);
  }

  if(address && !linkCreated4){
    setgeneratedRef(ref)
    generateQRCode(ref);
    setlinkCreated4(true)
    setlinkText4("Copy Ref Link")

    setlinkText("Pro Funnel")
    setlinkText2("Beginner Funnel")
    setlinkText3("English Funnel")
    setlinkText5("Dennis Funnel")
    setlinkText6("Cryptvest Funnel")
    setlinkCreated(false)
    setlinkCreated2(false)
    setlinkCreated3(false)
    setlinkCreated5(false)
    setlinkCreated6(false)

    setpreviewText("Preview Sven Flieshardt Funnel")
    setpreviewLink(ref)
    document.getElementById("previewlink").classList.remove('disablelink');
  }
}

const onClickHandler5 = (event) => {

  var address = refLink.split("referral/")[1]
  var ref = "https://socialswapinfo.io/f41/?ref=" + address

  if(linkCreated5){
    navigator.clipboard.writeText(ref)
    setlinkText5("Copied")
    document.getElementById("createbutton5").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton5").classList.remove('refswapbutton');
    document.getElementById("createbutton5m").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton5m").classList.remove('refswapbutton');

    setTimeout(function(){ 
      document.getElementById("createbutton5").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton5").classList.add('refswapbutton');
      document.getElementById("createbutton5m").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton5m").classList.add('refswapbutton');
      setlinkText5("Copy Ref Link")
     }, 1500);
  }

  if(address && !linkCreated5){
    setgeneratedRef(ref)
    generateQRCode(ref);
    setlinkCreated5(true)
    setlinkText5("Copy Ref Link")

    setlinkText("Pro Funnel")
    setlinkText2("Beginner Funnel")
    setlinkText3("English Funnel")
    setlinkText4("Sven Funnel")
    setlinkText6("Cryptvest Funnel")
    setlinkCreated(false)
    setlinkCreated2(false)
    setlinkCreated3(false)
    setlinkCreated4(false)
    setlinkCreated6(false)

    setpreviewText("Preview Dennis Funnel")
    setpreviewLink(ref)
    document.getElementById("previewlink").classList.remove('disablelink');
  }
}

const onClickHandler6 = (event) => {

  var address = refLink.split("referral/")[1]
  var ref = "https://cryptvest.de/start/?ref=" + address

  if(linkCreated6){
    navigator.clipboard.writeText(ref)
    setlinkText6("Copied")
    document.getElementById("createbutton6").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton6").classList.remove('refswapbutton');
    document.getElementById("createbutton6m").classList.add('refswapbuttonclicked');
    document.getElementById("createbutton6m").classList.remove('refswapbutton');

    setTimeout(function(){ 
      document.getElementById("createbutton6").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton6").classList.add('refswapbutton');
      document.getElementById("createbutton6m").classList.remove('refswapbuttonclicked');
      document.getElementById("createbutton6m").classList.add('refswapbutton');
      setlinkText6("Copy Ref Link")
     }, 1500);
  }

  if(address && !linkCreated6){
    setgeneratedRef(ref)
    generateQRCode(ref);
    setlinkCreated6(true)
    setlinkText6("Copy Ref Link")

    setlinkText("Pro Funnel")
    setlinkText2("Beginner Funnel")
    setlinkText3("English Funnel")
    setlinkText4("Sven Funnel")
    setlinkText5("Dennis Funnel")
    setlinkCreated(false)
    setlinkCreated2(false)
    setlinkCreated3(false)
    setlinkCreated4(false)
    setlinkCreated5(false)

    setpreviewText("Preview Cryptvest Funnel")
    setpreviewLink(ref)
    document.getElementById("previewlink").classList.remove('disablelink');
  }
}

  return (
    <React.Fragment>
      <Style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Montserrat');

        .chart {
          background: rgb(255, 255, 255);
          overflow: visible;
          display: block;
          width: 500px;
          height: 200px;
          padding-bottom: 50px;
        }
        
        body {
          padding-top: 60px;
          overflow-x: hidden;
          position: relative;
          overflow-y: hidden;
        }
        html, body {
          overflow-x: hidden;
        }
        
        button {
          background: linear-gradient(to right, #D38312 0%, #A83279 100%);
          color: #fff;
          border: 0;
          outline: 0;
        }
        
        button:hover {
          background: orange;
        }
        
        .buttonLink {
          width: 200px;
          height: 80px;
          background: linear-gradient(to right, #515da8 0%, #e31e25 100%);
          color: #fff;
          margin: 0 auto 5px;
          border-radius: 50%;
          font-size: 28px;
          font-weight: bold;
          border: 0px;
        }
        
        #nav {
          position: fixed;
          top: 0;
          width: 100%;
          height: 50px;
          float: left;
          padding: 0;
          padding-top: 16px;
          list-style: none;
          background-color: white;
          border-bottom: 1px solid #ccc;
          border-top: 1px solid #ccc;
          box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .2);
          text-align: right;
          margin: 0;
        }
        
        #nav li {
          display: inline;
          padding: 5px 5px;
          text-decoration: none;
          font-weight: bold;
          color: grey;
          float: none;
          vertical-align: center;
        }
        
        #logo {
          float: left;
          padding-left: 10px;
          height: 90%
        }
        
        #selector li {
          display: inline;
          width: 20%;
        }
        
        .price {
          margin: auto;
          width: 50%;
          padding: 10px;
          text-align: center;
          font-size: larger;
          background: "blue"
        }
        
        .grid {
          opacity: 0.1;
        }
        
        .main {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        
        .priceForm {
          overflow: visible;
        }
        
        .heading {
          text-align: center;
          margin: 0 0;
          margin-top: 4%;
        }
        
        .heading h1 {
          font-weight: bolder;
          background: linear-gradient(to right, #a19bbf 0%, #25262b 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.8rem;
        }
        
        .inputField {
          width: 150px;
          text-align: right;
        }
        
        .prices {
          font-size: 42px;
          padding: 40px
        }
        
        .prices input {
          font-size: 42px;
        }
        
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        
        input[type=number] {
          -moz-appearance: textfield;
        }
        
        .btn-primary {
          background: linear-gradient(to right, #86829e 0%, #25262b 100%);
          color: #fff;
          border: 0;
          outline: 0;
          font-size: 16px;
        }
        
        .btn {
          padding: 6px 20px;
          border-radius: 10px;
          font-size: 1.3rem;
          font-weight: bold;
        }
        
        a, u {
          text-decoration: none;
        }
        
        .Connect_button {
          color: #ffffff;
          font-family: century-gothic;
          font-size: 18px;
          font-weight: bold;
          background-image: linear-gradient(to right, #515da8 0%, #e31e25 100%);
          border: none;
          border-radius: 50px;
          padding: 4px 30px !important;
          vertical-align: text-top;
          opacity: 0.7;
          margin-top: 1px;
        }
        
        .ng-trigger-dialogContainer {
          border-radius: 20px !important;
          border: 1px solid #000 !important;
        }
        
        .Connect_button:focus {
          outline: none;
        }
        
        .referal_maincontainer {
          display: flex;
          padding-top: 120px;
        }
        
        .balance_form {
          background: #fff;
          width: 100%;
          height: 510px;
        }
        
        .form_dropdowndiv .dropdown button {
          width: 101.9%;
          border-radius: 0 50px 50px 0;
          padding: 0;
          border: none;
          background-image: linear-gradient(to right, #dadada, #b3b1b3);
          color: #000;
          border-left: 2px solid #929092;
          height: 54px;
          font-weight: bold;
          text-indent: -74px;
        }
        
        #dropdownMenuButton img {
          float: left;
          width: 26px;
        }
        
        .form_dropdowndiv .dropdown button:focus {
          outline: none;
        }
        
        .form_dropdowndiv input, .form_dropdowndiv .dropdown {
          display: inline-block;
          border: none;
          padding: 0;
          border-radius: 0 50px 50px 0;
          height: 54px;
          vertical-align: top;
        }
        
        .form_dropdowndiv .dropdown {
          width: 53%;
          overflow: hidden;
        }
        
        .form_dropdowndiv input {
          text-indent: 20px;
          width: 47%;
          font-size: 16px;
          color: rgb(92, 92, 92);
        }
        
        .form_dropdowndiv input::placeholder {
          color: #000;
        }
        
        .form_dropdowndiv input:focus {
          box-shadow: none;
          outline: none;
        }
        
        .form_dropdowndiv {
          border: 0px solid rgb(0, 0, 0);
          border-radius: 50px;
          height: 60px;
          margin-top: 8px;
          overflow: hidden;
          background: linear-gradient(to right, rgba(161, 155, 191, 0.15) 0%, rgba(37, 38, 43, 0.15) 100%);
        }
        
        .form_dropdowndiv .dropdown button img {
          width: 20px;
          margin-right: 0px;
          margin-left: 10px;
        }
        
        .balnce_inputform {
          position: relative;
        }
        
        .balnce_inputform label {
          color: #000000;
          font-weight: bold;
          font-size: 1.2rem;
          margin-left: 20px;
          margin-bottom: 8px;
        }
        
        .dropdown-toggle::after {
          content: "";
          position: absolute;
          z-index: 99;
          display: block;
          width: 8px;
          height: 8px;
          opacity: 0;
        }
        
        .to_dropdownimg {
          font-size: 12px !important;
          background-image: linear-gradient(to right, #f3abb0, #d28d97) !important;
        }
        
        .labelto {
          margin-top: 50px;
        }
        
        .arrow_images {
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
          text-align: center;
        }
        
        .arrow_images img {
          width: 30px;
          margin: 0 10px;
        }
        
        .balance_text {
          color: #e31e25;
          font-family: century-gothic;
          font-size: 24px;
          text-align: right;
          margin: 0 0 16px 0;
        }
        
        .formmaincontainerdiv {
          margin-bottom: 50px;
        }
        
        .btn_container {
          text-align: center;
          margin-top: 50px;
        }
        
        .btn_container button {
          padding: 4px 20px;
        }
        
        select.select_first {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          font-weight: bold;
          text-indent: 58px;
          color: #000;
          margin-top: 6px;
          font-size: 16px;
          width: 94%;
        }
        
        select:focus {
          background-color: transparent;
          box-shadow: none;
        }
        
        select.select_two {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          color: #000;
          font-weight: bold;
          font-size: 16px;
          margin-top: 7px;
          text-indent: 45px;
          width: 94%;
        }
        
        .marleft20px {
          margin-left: 20px !important;
        }
        
        .to_dropdownimg img {
          float: left;
          margin-left: 10px;
        }
        
        .form-group {
          margin: 0px!important;
          padding: 0px 18px 16px 18px!important;
        }
        
        @media screen and (min-width: 1022px) and (max-width: 1024px) {
          .form_dropdowndiv .dropdown button img {
            margin-right: 76px;
          }
        }
        
        @media screen and (min-width: 500px) and (max-width: 770px) {
          select.select_first {
            font-size: 12px;
          }
        }
        
        @media only screen and (max-width: 768px) {
          .referal_maincontainer {
            padding-top: 0
          }
        }
        
        @media only screen and (max-width: 767px) {
          .bg-light {
            padding: 16px 33px !important;
          }
          .card-body {
            padding: .5rem;
          }
          .form-group {
            margin: 0px!important;
            padding: 0px 18px!important;
          }
          .arrowtopbot img {
            height: 35px;
          }
          .form_dropdowndiv .dropdown button {
            font-size: 8px !important;
          }
          .tyah_button {
            font-size: 16px !important;
          }
          select.select_first {
            text-indent: 10px;
            font-size: 12px;
            padding: 0px 5px 0px 20px;
            height: 45px;
          }
          .form_dropdowndiv .dropdown button {
            margin-left: 4px;
          }
          #dropdownMenuButton img {
            width: 20px;
          }
          select.select_two {
            font-size: 12px;
            text-indent: 26px;
          }
          select.select_two {
            width: 92%;
          }
          .form_dropdowndiv .dropdown button {
            width: 99.6% !important;
          }
          .referal_maincontainer {
            display: flex;
            padding-top: 0;
            align-items: center;
          }
          .balance_text {
            font-size: 20px;
          }
          .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .arrow_images img {
            width: 20px;
            margin: 0 4px;
          }
          .formmaincontainerdiv {
            margin-bottom: 30px;
          }
          .labelto {
            margin-top: 30px;
          }
          .Connect_button {
            font-size: 16px;
          }
        }
        
        @media screen and (min-width: 200px) and (max-width: 320px) {
          select.select_first {
            text-indent: 29px;
            font-size: 10px;
          }
          .referal_maincontainer {
            height: inherit;
          }
          .form_dropdowndiv .dropdown button {
            width: 99% !important;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .form_dropdowndiv input, .form_dropdowndiv .dropdown {
            height: 48px;
          }
          .form_dropdowndiv {
            height: 54px;
          }
          .form_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        @media only screen and (max-width: 280px) {
          .form_dropdowndiv .dropdown button {
            width: 98.6% !important;
          }
          .Connect_button {
            color: #ffffff;
            font-family: century-gothic;
            font-size: 16px;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .form_dropdowndiv input, .form_dropdowndiv .dropdown {
            height: 48px;
          }
          .form_dropdowndiv {
            height: 54px;
          }
          .form_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        .coin-img {
          display: block;
        }
        
        .converter {
          width: 60%;
          margin: 0 auto;
        }
        
        .swapbutton {
          margin: auto;
          margin-top: 6%;
          background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
          color: #fff;
          border: 0;
          outline: 0;
          width:30%;
          font-size: 16px;
          padding: 6px 20px;
          border-radius: 20px;
          font-size: 1.6rem;
          font-weight: bold;
        }
        
        .swap {
          color: white;
        }
        
        .left {
          text-align: left;
        }
        
        .middlepls {
          text-align: center;
        }
        
        .currencies {
          font-size: 21px;
          font-weight: bold;
        }
        
        .form-control {
          background: rgba(0, 0, 0, 0);
          font-weight: bold;
          color: grey;
          text-align: center;
        }
        
        .footertext {
          color: rgb(71, 71, 71);
        }
        
        .social-icon {
          width: 60px;
        }
        
        .footerdiv {
          display: flex;
          text-align: center;
        }
        
        .footerchild {
          padding-top: 20px;
          align-self: center;
          margin: auto;
          width: 250px;
          display:flex;
          justify-content: space-between;
          padding-bottom: 5%;
        }
        
        .container {
          display: inline-block;
          cursor: pointer;
        }
        
        .bar1, .bar2, .bar3 {
          width: 35px;
          height: 5px;
          background-color: #333;
          margin: 6px 0;
          transition: 0.4s;
        }
        
        /* Rotate first bar */
        .change .bar1 {
          -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
          transform: rotate(-45deg) translate(-9px, 6px) ;
        }
        
        /* Fade out the second bar */
        .change .bar2 {
          opacity: 0;
        }
        
        /* Rotate last bar */
        .change .bar3 {
          -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
          transform: rotate(45deg) translate(-8px, -8px) ;
        }
        
        .dropdown {
          text-align:center;
        }
        
        .dropdownElement {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          height: 50px;
        }
        
        .dropdownElement .text {
          font-weight: bolder;
          background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        .footertextmobile {
          color: rgb(71, 71, 71);
          display: none;
        }
        
        @media only screen and (max-width: 900px) {
          /* For mobile phones: */
          [class="footertext"] {
            display: none;
          }
          [class="converter"] {
            width: 100%;
          }
          [class="swapbutton"] {
            width: 50%;
            font-size: 24;
          }
          [class="price"] {
            width: 90%;
          }
        
        }
        
        @media only screen and (max-width: 300px) {
        
          [class="chart"]{
            width: 250px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 250px;
          }
        }
        
        @media only screen and (max-width: 420px) {
        
          [class="chart"]{
            width: 270px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 270px;
          }
        }
        
        .chartheading {
          text-align: center;
          margin: 0 0;
          margin-top: 8%;
        }
        
        .chartheading h1 {
          background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.2rem;
        }
        
        .transferButton{
          cursor: pointer;
        }
        
        .footerheading {
          text-align: center;
          margin: 0 0;
          margin-top: 10px;
        }
        
        .footerheading h1 {
          font-weight: bolder;
          background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.2rem;
        }
        
        .statisticsoverview{
          text-align: center;
        }
        
        .statscontainer{
          width:100%;
        }
        
        table {
          width: 100%;
          border-collapse: collapse;
          overflow: hidden;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
        th, td {
          padding: 15px;
          background-color: rgba(214, 213, 213, 0.35);
          color: rgb(26, 26, 26);
        }
        th {
          text-align: center;
        }
        td {
          font-weight: 600;
        }
        thead th {
          background-color: rgba(255, 120, 79, 0.5)
        }
        tbody tr:hover {
          background-color: rgb(97, 97, 97, 0.3);
        }
        tbody td {
          position: relative;
        }
        tbody td:hover:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: -1;
        }
        
        .newtooltip {
          color:#fff;
          background-color:rgb(121, 121, 121);
          width:12px;
          height:12px;
          display:inline-block;
          border-radius:100%;
          font-size:10px;
          text-align:center;
          text-decoration:none;
          -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
          -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
          box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
        }
        
        .tooltip {
          position: relative;
          display: inline-block;
          background: rgb(121, 121, 121);
          color: #fff;
          padding: 0.05px 5.1px;
          border-radius: 100%;
          font-size: 14px;
          font-weight: 700;
        }
        
        .tooltip .tooltiptext {
          visibility: hidden;
          width: 150px;
          background-color: rgb(218, 218, 218);
          color: rgb(37, 37, 37);
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          font-size: 15px;
          
          /* Position the tooltip */
          position: absolute;
          z-index: 1;
          bottom: 100%;
          left: 50%;
          margin-left: -60px;
        }
        
        .tooltip:hover .tooltiptext {
          visibility: visible;
        }
        
        .tooltip:active .tooltiptext {
          visibility: visible;
        }

        @import url('https://fonts.googleapis.com/css?family=Montserrat');



        .priceForm {
          overflow: visible;
        }
        
        .heading {
          text-align: center;
          margin: 0 0;
          margin-top: 4%;
        }
        
        .heading h1 {
          font-weight: bolder;
          background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.8rem;
        }
        
        .refheading {
          text-align: center;
          margin: 0 0;
          margin-top: 4%;
        }
        
        .refheading h1 {
          font-weight: bolder;
          background: linear-gradient(to right, #222531 0%, #222531 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.6rem;
        }
        
        .inputField {
          width: 150px;
          text-align: right;
        }
        
        .prices {
          font-size: 42px;
          padding: 40px
        }
        
        .prices input {
          font-size: 42px;
        }
        
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        
        input[type=number] {
          -moz-appearance: textfield;
        }
        
        .btn-primary {
          background: linear-gradient(to right, #86829e 0%, #25262b 100%);
          color: #fff;
          border: 0;
          outline: 0;
          font-size: 16px;
        }
        
        .btn {
          padding: 6px 20px;
          border-radius: 10px;
          font-size: 1.3rem;
          font-weight: bold;
        }
        
        a, u {
          text-decoration: none;
        }
        
        .Connect_button {
          color: #ffffff;
          font-family: century-gothic;
          font-size: 18px;
          font-weight: bold;
          background-image: linear-gradient(to right, #515da8 0%, #e31e25 100%);
          border: none;
          border-radius: 50px;
          padding: 4px 30px !important;
          vertical-align: text-top;
          opacity: 0.7;
          margin-top: 1px;
        }
        
        .ng-trigger-dialogContainer {
          border-radius: 20px !important;
          border: 1px solid #000 !important;
        }
        
        .Connect_button:focus {
          outline: none;
        }
        
        .referal_maincontainer {
          display: flex;
          padding-top: 120px;
        }
        
        .balance_form {
          background: #fff;
          width: 100%;
          height: 510px;
        }
        
        .refform_dropdowndiv .dropdown button {
          width: 101.9%;
          border-radius: 0 50px 50px 0;
          padding: 0;
          border: none;
          background-image: linear-gradient(to right, #dadada, #b3b1b3);
          color: #000;
          border-left: 2px solid #929092;
          height: 54px;
          font-weight: bold;
          text-indent: -74px;
        }
        
        #dropdownMenuButton img {
          float: left;
          width: 26px;
        }
        
        .refform_dropdowndiv .dropdown button:focus {
          outline: none;
        }
        
        .refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
          display: inline-block;
          border: none;
          padding: 0;
          border-radius: 0 50px 50px 0;
          height: 54px;
          vertical-align: top;
        }
        
        .refform_dropdowndiv .dropdown {
          width: 53%;
          overflow: hidden;
        }
        
        .refform_dropdowndiv input {
          text-indent: 20px;
          width: 80%;
          font-size: 16px;
          color: #222531;
        }
        
        .refform_dropdowndiv input::placeholder {
          color: #000;
        }
        
        .refform_dropdowndiv input:focus {
          box-shadow: none;
          outline: none;
        }
        
        .refform_dropdowndiv {
          border: 0px solid rgb(0, 0, 0);
          height: 60px;
          margin-top: 8px;
          overflow: hidden;
          text-align: center;
          border-radius: 15px;
          background: #f3f2f5;
        }
        
        .refform_dropdowndiv .dropdown button img {
          width: 20px;
          margin-right: 0px;
          margin-left: 10px;
        }
        
        .balnce_inputform {
          position: relative;
        }
        
        .balnce_inputform label {
          color: #000000;
          font-weight: bold;
          font-size: 1.2rem;
          margin-left: 20px;
          margin-bottom: 8px;
        }
        
        .dropdown-toggle::after {
          content: "";
          position: absolute;
          z-index: 99;
          display: block;
          width: 8px;
          height: 8px;
          opacity: 0;
        }
        
        .to_dropdownimg {
          font-size: 12px !important;
          background-image: linear-gradient(to right, #f3abb0, #d28d97) !important;
        }
        
        .labelto {
          margin-top: 50px;
        }
        
        .arrow_images {
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
          text-align: center;
        }
        
        .arrow_images img {
          width: 30px;
          margin: 0 10px;
        }
        
        .balance_text {
          color: #e31e25;
          font-family: century-gothic;
          font-size: 24px;
          text-align: right;
          margin: 0 0 16px 0;
        }
        
        .formmaincontainerdiv {
          margin-bottom: 50px;
        }
        
        .btn_container {
          text-align: center;
          margin-top: 50px;
        }
        
        .btn_container button {
          padding: 4px 20px;
        }
        
        select.select_first {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          font-weight: bold;
          text-indent: 58px;
          color: #000;
          margin-top: 6px;
          font-size: 16px;
          width: 94%;
        }
        
        select:focus {
          background-color: transparent;
          box-shadow: none;
        }
        
        select.select_two {
          position: absolute;
          top: 0;
          background: transparent;
          border: 0;
          color: #000;
          font-weight: bold;
          font-size: 16px;
          margin-top: 7px;
          text-indent: 45px;
          width: 94%;
        }
        
        .marleft20px {
          margin-left: 20px !important;
        }
        
        .to_dropdownimg img {
          float: left;
          margin-left: 10px;
        }
        
        .form-group {
          margin: 0px!important;
          padding: 0px 18px 16px 18px!important;
        }
        
        @media screen and (min-width: 1022px) and (max-width: 1024px) {
          .refform_dropdowndiv .dropdown button img {
            margin-right: 76px;
          }
        }
        
        @media screen and (min-width: 500px) and (max-width: 770px) {
          select.select_first {
            font-size: 12px;
          }
        }
        
        @media only screen and (max-width: 768px) {
          .referal_maincontainer {
            padding-top: 0
          }
        }
        
        @media only screen and (max-width: 767px) {
          .bg-light {
            padding: 16px 33px !important;
          }
          .card-body {
            padding: .5rem;
          }
          .form-group {
            margin: 0px!important;
            padding: 0px 18px!important;
          }
          .arrowtopbot img {
            height: 35px;
          }
          .refform_dropdowndiv .dropdown button {
            font-size: 8px !important;
          }
          .tyah_button {
            font-size: 16px !important;
          }
          select.select_first {
            text-indent: 10px;
            font-size: 12px;
            padding: 0px 5px 0px 20px;
            height: 45px;
          }
          .refform_dropdowndiv .dropdown button {
            margin-left: 4px;
          }
          #dropdownMenuButton img {
            width: 20px;
          }
          select.select_two {
            font-size: 12px;
            text-indent: 26px;
          }
          select.select_two {
            width: 92%;
          }
          .refform_dropdowndiv .dropdown button {
            width: 99.6% !important;
          }
          .referal_maincontainer {
            display: flex;
            padding-top: 0;
            align-items: center;
          }
          .balance_text {
            font-size: 20px;
          }
          .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .arrow_images img {
            width: 20px;
            margin: 0 4px;
          }
          .formmaincontainerdiv {
            margin-bottom: 30px;
          }
          .labelto {
            margin-top: 30px;
          }
          .Connect_button {
            font-size: 16px;
          }
        }
        
        @media screen and (min-width: 200px) and (max-width: 320px) {
          select.select_first {
            text-indent: 29px;
            font-size: 10px;
          }
          .referal_maincontainer {
            height: inherit;
          }
          .refform_dropdowndiv .dropdown button {
            width: 99% !important;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
            height: 48px;
          }
          .refform_dropdowndiv {
            height: 54px;
          }
          .refform_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        @media only screen and (max-width: 280px) {
          .refform_dropdowndiv .dropdown button {
            width: 98.6% !important;
          }
          .Connect_button {
            color: #ffffff;
            font-family: century-gothic;
            font-size: 16px;
          }
          .balance_text, .balnce_inputform label {
            font-size: 20px;
            margin-bottom: 0;
          }
          .refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
            height: 48px;
          }
          .refform_dropdowndiv {
            height: 54px;
          }
          .refform_dropdowndiv .dropdown button {
            height: 48px;
          }
        }
        
        .coin-img {
          display: block;
        }
        
        .refconverter {
          width: 25%;
          margin: 0 auto;
        }
        
        .refswapbutton {
          margin: auto;
          margin-top: 3%;
          background: linear-gradient(to right, #222531 0%, #222531 100%);
          color: #fff;
          border: 0;
          outline: 0;
          width:15%;
          font-size: 14px;
          padding: 6px 20px;
          border-radius: 10px;
          font-size: 1.6rem;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
        }

        
        
        .refswapbuttonclicked {
          margin: auto;
          margin-top: 3%;
          background: linear-gradient(to right, rgba(134, 130, 158, 0.35) 0%, rgba(37, 38, 43, 0.35) 100%);
          color: #fff;
          border: 0;
          outline: 0;
          width:15%;
          font-size: 14x;
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 1.6rem;
          font-weight: bold;
          text-align: center;
        }
        
        .refswap {
          color: white;
          cursor: pointer;
        }
        
        .left {
          text-align: left;
        }
        
        .middlepls {
          text-align: center;
        }
        
        .currencies {
          font-size: 20px;
          font-weight: bold;
        }
        
        .refform-control {
          background: rgba(0, 0, 0, 0);
          font-weight: bold;
          color: grey;
          text-align: center;
          width: 500px;
        }
        
        .reffootertext {
          color: rgb(71, 71, 71);
          margin-top: 10%;
        }
        
        .social-icon {
          width: 60px;
        }
        
        .footerdiv {
          display: flex;
          text-align: center;
        }
        
        .footerchild {
          padding-top: 20px;
          align-self: center;
          margin: auto;
          width: 250px;
          display:flex;
          justify-content: space-between;
          padding-bottom: 5%;
        }
        
        .container {
          display: inline-block;
          cursor: pointer;
        }
        
        .bar1, .bar2, .bar3 {
          width: 35px;
          height: 5px;
          background-color: #333;
          margin: 6px 0;
          transition: 0.4s;
        }
        
        /* Rotate first bar */
        .change .bar1 {
          -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
          transform: rotate(-45deg) translate(-9px, 6px) ;
        }
        
        /* Fade out the second bar */
        .change .bar2 {
          opacity: 0;
        }
        
        /* Rotate last bar */
        .change .bar3 {
          -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
          transform: rotate(45deg) translate(-8px, -8px) ;
        }
        
        .dropdown {
          text-align:center;
        }
        
        .dropdownElement {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          height: 50px;
        }
        
        .dropdownElement .text {
          font-weight: bolder;
          background: linear-gradient(to right, #a19bbf 0%, #25262b 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        .footertextmobile {
          color: rgb(71, 71, 71);
          display: none;
        }

        .first{
          display: inline-block;
        }
        .second{
          display: inline-block;
          margin-left: 50px;
        }
        .previewwrapper{
          text-align: center;
        }

        .mobilebutts{
          display:none;
        }
        
        @media only screen and (max-width: 900px) {
          /* For mobile phones: */
          .second{
            display: none;
          }
          .desktopbutts{
            display:none;
          }
          .mobilebutts{
            display: inline;
          }
          [class="reftext"] {
            margin-top: 10%;
          }
          [class="refconverter"] {
            width: 100%;
          }
          [class="refswapbutton"] {
            width: 50%;
            font-size: 24;
            margin-top: 10%;
          }
          [class="refswapbuttonclicked"] {
            width: 50%;
            font-size: 24;
            margin-top: 10%;
          }
          [class="price"] {
            width: 90%;
          }
          [class="reffootertext"] {
            display: none;
          }
          [class ="refconverter"] {
            margin-bottom: 50px;
          }
        }
        
        @media only screen and (max-width: 300px) {
        
          [class="chart"]{
            width: 250px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 250px;
          }
        }
        
        @media only screen and (max-width: 420px) {
        
          [class="chart"]{
            width: 270px;
            height: 350px;
            padding-top: 30px;
          }
          [class="footerchild"]{
            width: 270px;
          }
        }
        
        .chartheading {
          text-align: center;
          margin: 0 0;
          margin-top: 8%;
        }
        
        .chartheading h1 {
          background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 2.2rem;
        }
        
        .transferButton{
          cursor: pointer;
        }
        
        .generatedref{
            text-align: center;
            margin-top: 2%;
        }
        
        .reftext{
          color: #222531;
            font-size: 30px;
        }
        
        .reftextlink{
            color: rgb(71, 71, 71);
            font-size: 20px;
        }
        .disablelink{
          pointer-events: none;
        }
        `}
      </Style>
      <Navbar clickHandler={clickHandler} fixer={setExpanded} referral="https://socialswap.io"/>
      {expanded?dropDown:undefined}
      <div >
        <div className="refheading">
          <h1> SocialSwapInfo.io Reflink Generator</h1>
        </div>

        <div className="refconverter">
                <div className="refform_dropdowndiv">
                    <input type="text" className="refform-control" placeholder="Enter your referral link" value={refLink} onChange={onChangeHandler}/>
                </div>
        </div>

        <div className="mobilebutts">
            <div className="refswapbutton" id="createbutton2m" style={{marginTop: "2.5%"}} onClick={onClickHandler2}>
                    <a className="refswap" rel="noreferrer">{linkText2}</a>
            </div>
            <div className="refswapbutton" id="createbutton3m" style={{marginTop: "2.5%"}} onClick={onClickHandler3}>
                    <a className="refswap" rel="noreferrer">{linkText3}</a>
            </div>
            <div className="refswapbutton" id="createbutton4m" style={{marginTop: "2.5%"}} onClick={onClickHandler4}>
                    <a className="refswap" rel="noreferrer">{linkText4}</a>
            </div>
            <div className="refswapbutton" id="createbutton5m" style={{marginTop: "2.5%"}} onClick={onClickHandler5}>
                    <a className="refswap" rel="noreferrer">{linkText5}</a>
            </div>
            <div className="refswapbutton" id="createbutton1m" style={{marginTop: "2.5%"}} onClick={onClickHandler}>
                    <a className="refswap" rel="noreferrer">{linkText}</a>
            </div>
            <div className="refswapbutton" id="createbutton6m" style={{marginTop: "2.5%"}} onClick={onClickHandler6}>
                    <a className="refswap" rel="noreferrer">{linkText6}</a>
            </div>
        </div>

        <div className="desktopbutts">

          <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton2" style={{marginTop: "2.5%"}} onClick={onClickHandler2}>
                    <a className="refswap" rel="noreferrer">{linkText2}</a>
            </div>
            <div className="refswapbutton second" id="createbutton">
                    <a className="refswap" rel="noreferrer" href="https://socialswapinfo.io/f2" target="_blank">Preview</a>
            </div>
          </div>

          <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton3" style={{marginTop: "2.5%"}} onClick={onClickHandler3}>
                    <a className="refswap" rel="noreferrer">{linkText3}</a>
            </div>
            <div className="refswapbutton second" id="createbutton">
                    <a className="refswap" rel="noreferrer" href="https://socialswapinfo.io/f3" target="_blank">Preview</a>
            </div>
          </div>

          <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton4" style={{marginTop: "2.5%"}} onClick={onClickHandler4}>
                    <a className="refswap" rel="noreferrer">{linkText4}</a>
            </div>
            <div className="refswapbutton second" id="createbutton">
                    <a className="refswap" rel="noreferrer" href="https://socialswapinfo.io/f4" target="_blank">Preview</a>
            </div>
          </div>

          <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton5" style={{marginTop: "2.5%"}} onClick={onClickHandler5}>
                    <a className="refswap" rel="noreferrer">{linkText5}</a>
            </div>
              <div className="refswapbutton second" id="createbutton">
                      <a className="refswap" rel="noreferrer" href="https://socialswapinfo.io/f41" target="_blank">Preview</a>
              </div>
            </div>

            <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton1" style={{marginTop: "2.5%"}} onClick={onClickHandler}>
                    <a className="refswap" rel="noreferrer">{linkText}</a>
            </div>
            <div className="refswapbutton second" id="createbutton">
                    <a className="refswap" rel="noreferrer" href="https://funnel.socialswapinfo.io/start/" target="_blank">Preview</a>
            </div>
          </div>

          <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton6" style={{marginTop: "2.5%"}} onClick={onClickHandler6}>
                    <a className="refswap" rel="noreferrer">{linkText6}</a>
            </div>
            <div className="refswapbutton second" id="createbutton">
                    <a className="refswap" rel="noreferrer" href="https://cryptvest.de/start/" target="_blank">Preview</a>
            </div>
          </div>

        </div>

        {/* <div className="previewwrapper">
            <div className="refswapbutton first" id="createbutton5" style={{marginTop: "2.5%"}}>
                    <a className="refswap" rel="noreferrer" onClick={onClickHandler5}>{linkText5}</a>
            </div>
              <div className="refswapbutton second" id="createbutton">
                      <a className="refswap" rel="noreferrer" href="https://socialswapinfo.io/f41" target="_blank">Preview Dennis</a>
              </div>
            </div>
        */}

        

        <div className="generatedref">
        <h1 className="reftext">Your Reflink:</h1>
        <div className="refconverter">
                <div className="refform_dropdowndiv">
                    <input type="text" className="refform-control" placeholder="Enter your referral link" id="reflinkfield" value={generatedRef} readonly="readonly" onClick={refLinkCopier}/>
                </div>
        </div>
        <div className="refswapbutton" id="createbutton2" style={{marginTop: "1%", marginBottom: "2%"}}>
                <a className="refswap disablelink" rel="noreferrer" href={previewLink} id="previewlink" target="_blank">{previewText}</a>
        </div>

        <h1 className="reftext">Your personal QR-Code:</h1>
        <div class="text-center">
        <img src="" id="qrcode"/><span id="qrcodenotshow" style={{display: "block"}}>Generate your link first</span>
        </div>
        {/*
        <div className="refswapbutton" id="createbutton2" style={{marginTop: "1%", marginBottom: "0%"}}>
                <a className="refswap" href="https://chart.googleapis.com/chart?cht=qr&chl=https://socialswapinfo.io/f2/ref/56&chs=160x160&chld=L|0" target="_blank">Download QR-Code</a>
        </div>
        */}
        </div>

        <div className="main">
          <div>
            <h1 className="reffootertext">Follow them and get the latest updates on SocialSwap.</h1>
            <div className="footerdiv">
              <div className="footerchild">
                <a target="_blank" href="https://www.youtube.com/channel/UC6gcqlRfsUuW_ZJ1BDVfycQ" rel="noreferrer"><img className="social-icon" src={youtube} alt="youtube" /></a>
                <a target="_blank" href="https://www.reddit.com/r/SocialSwap/" rel="noreferrer"><img className="social-icon" src={reddit} alt="reddit" /></a>
                <a target="_blank" href="https://t.me/socialswapio" rel="noreferrer"><img className="social-icon" src={telegram} alt="telegram" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>

  );
}

export default Refpage;