import React, {useEffect, useState, useLayoutEffect} from "react";
import Navbar from "./Navbar";
import background from "./static/images/background.png";
import telegram from "./static/images/telegram2.png";
import youtube from "./static/images/youtube2.png";
import reddit from "./static/images/reddit2.png";
import Style from "style-it"

import ReactPlayer from 'react-player'
import "./Intro.css";


const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

const IntroPage = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [windoWidth, windowHeight] = useWindowSize();

    const clickHandler = () => {
        // if (expanded) {
        //   setExpanded(false);
        // } else {
        //   setExpanded(true);
        // }
    };

    const dropDown = (
        <div className="dropdown">
            <a href="https://www.socialswap.io/" target="_blank" rel="noreferrer">
                <div className="dropdownElement">
                    <h2 className="text">DEX</h2>
                </div>
            </a>

            {!props.match.params.id ? (
                <a href="/referrals" target="_blank" rel="noreferrer">
                    <div className="dropdownElement">
                        <h2 className="text">Ref</h2>
                    </div>
                </a>
            ) : (
                false
            )}
            <a
                href="https://dappradar.com/tron/exchanges/socialswap-io"
                target="_blank"
                rel="noreferrer"
            >
                <div className="dropdownElement">
                    <h2 className="text">DappRadar</h2>
                </div>
            </a>
            <a
                href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe"
                target="_blank"
                rel="noreferrer"
            >
                <div className="dropdownElement">
                    <h2 className="text">Contract</h2>
                </div>
            </a>
        </div>
    );

    let bg = background;
    if (windoWidth < 900) {
        bg = null;
    }

    return (
        <React.Fragment>
            <Style>
                {`
                @import url('https://fonts.googleapis.com/css?family=Montserrat');

                .chart {
                  background: rgb(255, 255, 255);
                  overflow: visible;
                  display: block;
                  width: 500px;
                  height: 200px;
                  padding-bottom: 50px;
                }
                
                body {
                  padding-top: 60px;
                  overflow-x: hidden;
                  position: relative;
                  overflow-y: hidden;
                }
                html, body {
                  overflow-x: hidden;
                }
                
                button {
                  background: linear-gradient(to right, #D38312 0%, #A83279 100%);
                  color: #fff;
                  border: 0;
                  outline: 0;
                }
                
                button:hover {
                  background: orange;
                }
                
                .buttonLink {
                  width: 200px;
                  height: 80px;
                  background: linear-gradient(to right, #515da8 0%, #e31e25 100%);
                  color: #fff;
                  margin: 0 auto 5px;
                  border-radius: 50%;
                  font-size: 28px;
                  font-weight: bold;
                  border: 0px;
                }
                
                #nav {
                  position: fixed;
                  top: 0;
                  width: 100%;
                  height: 50px;
                  float: left;
                  padding: 0;
                  padding-top: 16px;
                  list-style: none;
                  background-color: white;
                  border-bottom: 1px solid #ccc;
                  border-top: 1px solid #ccc;
                  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .2);
                  text-align: right;
                  margin: 0;
                }
                
                #nav li {
                  display: inline;
                  padding: 5px 5px;
                  text-decoration: none;
                  font-weight: bold;
                  color: grey;
                  float: none;
                  vertical-align: center;
                }
                
                #logo {
                  float: left;
                  padding-left: 10px;
                  height: 90%
                }
                
                #selector li {
                  display: inline;
                  width: 20%;
                }
                
                .price {
                  margin: auto;
                  width: 50%;
                  padding: 10px;
                  text-align: center;
                  font-size: larger;
                  background: "blue"
                }
                
                .grid {
                  opacity: 0.1;
                }
                
                .main {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
                
                .priceForm {
                  overflow: visible;
                }
                
                .heading {
                  text-align: center;
                  margin: 0 0;
                  margin-top: 4%;
                }
                
                .heading h1 {
                  font-weight: bolder;
                  background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 2.8rem;
                }
                
                .inputField {
                  width: 150px;
                  text-align: right;
                }
                
                .prices {
                  font-size: 42px;
                  padding: 40px
                }
                
                .prices input {
                  font-size: 42px;
                }
                
                input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                
                /* Firefox */
                
                input[type=number] {
                  -moz-appearance: textfield;
                }
                
                .btn-primary {
                  background: linear-gradient(to right, #86829e 0%, #25262b 100%);
                  color: #fff;
                  border: 0;
                  outline: 0;
                  font-size: 16px;
                }
                
                .btn {
                  padding: 6px 20px;
                  border-radius: 10px;
                  font-size: 1.3rem;
                  font-weight: bold;
                }
                
                a, u {
                  text-decoration: none;
                }
                
                .Connect_button {
                  color: #ffffff;
                  font-family: century-gothic;
                  font-size: 18px;
                  font-weight: bold;
                  background-image: linear-gradient(to right, #515da8 0%, #e31e25 100%);
                  border: none;
                  border-radius: 50px;
                  padding: 4px 30px !important;
                  vertical-align: text-top;
                  opacity: 0.7;
                  margin-top: 1px;
                }
                
                .ng-trigger-dialogContainer {
                  border-radius: 20px !important;
                  border: 1px solid #000 !important;
                }
                
                .Connect_button:focus {
                  outline: none;
                }
                
                .referal_maincontainer {
                  display: flex;
                  padding-top: 120px;
                }
                
                .balance_form {
                  background: #fff;
                  width: 100%;
                  height: 510px;
                }
                
                .form_dropdowndiv .dropdown button {
                  width: 101.9%;
                  border-radius: 0 50px 50px 0;
                  padding: 0;
                  border: none;
                  background-image: linear-gradient(to right, #dadada, #b3b1b3);
                  color: #000;
                  border-left: 2px solid #929092;
                  height: 54px;
                  font-weight: bold;
                  text-indent: -74px;
                }
                
                #dropdownMenuButton img {
                  float: left;
                  width: 26px;
                }
                
                .form_dropdowndiv .dropdown button:focus {
                  outline: none;
                }
                
                .form_dropdowndiv input, .form_dropdowndiv .dropdown {
                  display: inline-block;
                  border: none;
                  padding: 0;
                  border-radius: 0 50px 50px 0;
                  height: 54px;
                  vertical-align: top;
                }
                
                .form_dropdowndiv .dropdown {
                  width: 53%;
                  overflow: hidden;
                }
                
                .form_dropdowndiv input {
                  text-indent: 20px;
                  width: 47%;
                  font-size: 16px;
                  color: rgb(92, 92, 92);
                }
                
                .form_dropdowndiv input::placeholder {
                  color: #000;
                }
                
                .form_dropdowndiv input:focus {
                  box-shadow: none;
                  outline: none;
                }
                
                .form_dropdowndiv {
                  border: 0px solid rgb(0, 0, 0);
                  border-radius: 50px;
                  height: 60px;
                  margin-top: 8px;
                  overflow: hidden;
                  background: linear-gradient(to right, rgba(242, 112, 156, 0.15) 0%, rgba(255, 148, 114, 0.15) 100%);
                }
                
                .form_dropdowndiv .dropdown button img {
                  width: 20px;
                  margin-right: 0px;
                  margin-left: 10px;
                }
                
                .balnce_inputform {
                  position: relative;
                }
                
                .balnce_inputform label {
                  color: #000000;
                  font-weight: bold;
                  font-size: 1.2rem;
                  margin-left: 20px;
                  margin-bottom: 8px;
                }
                
                .dropdown-toggle::after {
                  content: "";
                  position: absolute;
                  z-index: 99;
                  display: block;
                  width: 8px;
                  height: 8px;
                  opacity: 0;
                }
                
                .to_dropdownimg {
                  font-size: 12px !important;
                  background-image: linear-gradient(to right, #f3abb0, #d28d97) !important;
                }
                
                .labelto {
                  margin-top: 50px;
                }
                
                .arrow_images {
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 4px;
                  text-align: center;
                }
                
                .arrow_images img {
                  width: 30px;
                  margin: 0 10px;
                }
                
                .balance_text {
                  color: #e31e25;
                  font-family: century-gothic;
                  font-size: 24px;
                  text-align: right;
                  margin: 0 0 16px 0;
                }
                
                .formmaincontainerdiv {
                  margin-bottom: 50px;
                }
                
                .btn_container {
                  text-align: center;
                  margin-top: 50px;
                }
                
                .btn_container button {
                  padding: 4px 20px;
                }
                
                select.select_first {
                  position: absolute;
                  top: 0;
                  background: transparent;
                  border: 0;
                  font-weight: bold;
                  text-indent: 58px;
                  color: #000;
                  margin-top: 6px;
                  font-size: 16px;
                  width: 94%;
                }
                
                select:focus {
                  background-color: transparent;
                  box-shadow: none;
                }
                
                select.select_two {
                  position: absolute;
                  top: 0;
                  background: transparent;
                  border: 0;
                  color: #000;
                  font-weight: bold;
                  font-size: 16px;
                  margin-top: 7px;
                  text-indent: 45px;
                  width: 94%;
                }
                
                .marleft20px {
                  margin-left: 20px !important;
                }
                
                .to_dropdownimg img {
                  float: left;
                  margin-left: 10px;
                }
                
                .form-group {
                  margin: 0px!important;
                  padding: 0px 18px 16px 18px!important;
                }
                
                @media screen and (min-width: 1022px) and (max-width: 1024px) {
                  .form_dropdowndiv .dropdown button img {
                    margin-right: 76px;
                  }
                }
                
                @media screen and (min-width: 500px) and (max-width: 770px) {
                  select.select_first {
                    font-size: 12px;
                  }
                }
                
                @media only screen and (max-width: 768px) {
                  .referal_maincontainer {
                    padding-top: 0
                  }
                }
                
                @media only screen and (max-width: 767px) {
                  .bg-light {
                    padding: 16px 33px !important;
                  }
                  .card-body {
                    padding: .5rem;
                  }
                  .form-group {
                    margin: 0px!important;
                    padding: 0px 18px!important;
                  }
                  .arrowtopbot img {
                    height: 35px;
                  }
                  .form_dropdowndiv .dropdown button {
                    font-size: 8px !important;
                  }
                  .tyah_button {
                    font-size: 16px !important;
                  }
                  select.select_first {
                    text-indent: 10px;
                    font-size: 12px;
                    padding: 0px 5px 0px 20px;
                    height: 45px;
                  }
                  .form_dropdowndiv .dropdown button {
                    margin-left: 4px;
                  }
                  #dropdownMenuButton img {
                    width: 20px;
                  }
                  select.select_two {
                    font-size: 12px;
                    text-indent: 26px;
                  }
                  select.select_two {
                    width: 92%;
                  }
                  .form_dropdowndiv .dropdown button {
                    width: 99.6% !important;
                  }
                  .referal_maincontainer {
                    display: flex;
                    padding-top: 0;
                    align-items: center;
                  }
                  .balance_text {
                    font-size: 20px;
                  }
                  .balnce_inputform label {
                    font-size: 20px;
                    margin-bottom: 0;
                  }
                  .arrow_images img {
                    width: 20px;
                    margin: 0 4px;
                  }
                  .formmaincontainerdiv {
                    margin-bottom: 30px;
                  }
                  .labelto {
                    margin-top: 30px;
                  }
                  .Connect_button {
                    font-size: 16px;
                  }
                }
                
                @media screen and (min-width: 200px) and (max-width: 320px) {
                  select.select_first {
                    text-indent: 29px;
                    font-size: 10px;
                  }
                  .referal_maincontainer {
                    height: inherit;
                  }
                  .form_dropdowndiv .dropdown button {
                    width: 99% !important;
                  }
                  .balance_text, .balnce_inputform label {
                    font-size: 20px;
                    margin-bottom: 0;
                  }
                  .form_dropdowndiv input, .form_dropdowndiv .dropdown {
                    height: 48px;
                  }
                  .form_dropdowndiv {
                    height: 54px;
                  }
                  .form_dropdowndiv .dropdown button {
                    height: 48px;
                  }
                }
                
                @media only screen and (max-width: 280px) {
                  .form_dropdowndiv .dropdown button {
                    width: 98.6% !important;
                  }
                  .Connect_button {
                    color: #ffffff;
                    font-family: century-gothic;
                    font-size: 16px;
                  }
                  .balance_text, .balnce_inputform label {
                    font-size: 20px;
                    margin-bottom: 0;
                  }
                  .form_dropdowndiv input, .form_dropdowndiv .dropdown {
                    height: 48px;
                  }
                  .form_dropdowndiv {
                    height: 54px;
                  }
                  .form_dropdowndiv .dropdown button {
                    height: 48px;
                  }
                }
                
                .coin-img {
                  display: block;
                }
                
                .converter {
                  width: 60%;
                  margin: 0 auto;
                }
                
                .swapbutton {
                  margin: auto;
                  margin-top: 6%;
                  background: linear-gradient(to right, #222531 0%, #222531 100%);
                  color: #fff;
                  border: 0;
                  outline: 0;
                  width:30%;
                  font-size: 16px;
                  padding: 6px 20px;
                  border-radius: 10px;
                  font-size: 1.6rem;
                  font-weight: bold;
                }
                
                .swap {
                  color: white;
                }
                
                .left {
                  text-align: left;
                }
                
                .middlepls {
                  text-align: center;
                }
                
                .currencies {
                  font-size: 21px;
                  font-weight: bold;
                }
                
                .form-control {
                  background: rgba(0, 0, 0, 0);
                  font-weight: bold;
                  color: grey;
                  text-align: center;
                }
                
                .footertext {
                  color: rgb(71, 71, 71);
                }
                
                .social-icon {
                  width: 60px;
                }
                
                .footerdiv {
                  display: flex;
                  text-align: center;
                }
                
                .footerchild {
                  padding-top: 20px;
                  align-self: center;
                  margin: auto;
                  width: 250px;
                  display:flex;
                  justify-content: space-between;
                  padding-bottom: 5%;
                }
                
                .container {
                  display: inline-block;
                  cursor: pointer;
                }
                
                .bar1, .bar2, .bar3 {
                  width: 35px;
                  height: 5px;
                  background-color: #333;
                  margin: 6px 0;
                  transition: 0.4s;
                }
                
                /* Rotate first bar */
                .change .bar1 {
                  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
                  transform: rotate(-45deg) translate(-9px, 6px) ;
                }
                
                /* Fade out the second bar */
                .change .bar2 {
                  opacity: 0;
                }
                
                /* Rotate last bar */
                .change .bar3 {
                  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
                  transform: rotate(45deg) translate(-8px, -8px) ;
                }
                
                .dropdown {
                  text-align:center;
                }
                
                .dropdownElement {
                  border-top: 1px solid #ccc;
                  border-bottom: 1px solid #ccc;
                  height: 50px;
                }
                
                .dropdownElement .text {
                  font-weight: bolder;
                  background: linear-gradient(to right, #a19bbf 0%, #25262b 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
                
                .footertextmobile {
                  color: rgb(71, 71, 71);
                  display: none;
                }
                
                @media only screen and (max-width: 900px) {
                  /* For mobile phones: */
                  [class="footertext"] {
                    display: none;
                  }
                  [class="converter"] {
                    width: 100%;
                  }
                  [class="swapbutton"] {
                    width: 50%;
                    font-size: 24;
                  }
                  [class="price"] {
                    width: 90%;
                  }
                
                }
                
                @media only screen and (max-width: 300px) {
                
                  [class="chart"]{
                    width: 250px;
                    height: 350px;
                    padding-top: 30px;
                  }
                  [class="footerchild"]{
                    width: 250px;
                  }
                }
                
                @media only screen and (max-width: 420px) {
                
                  [class="chart"]{
                    width: 270px;
                    height: 350px;
                    padding-top: 30px;
                  }
                  [class="footerchild"]{
                    width: 270px;
                  }
                }
                
                .chartheading {
                  text-align: center;
                  margin: 0 0;
                  margin-top: 8%;
                }
                
                .chartheading h1 {
                  background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 2.2rem;
                }
                
                .transferButton{
                  cursor: pointer;
                }
                
                .footerheading {
                  text-align: center;
                  margin: 0 0;
                  margin-top: 10px;
                }
                
                .footerheading h1 {
                  font-weight: bolder;
                  background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 2.2rem;
                }
                
                .statisticsoverview{
                  text-align: center;
                }
                
                .statscontainer{
                  width:100%;
                }
                
                table {
                  width: 100%;
                  border-collapse: collapse;
                  overflow: hidden;
                  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                }
                th, td {
                  padding: 15px;
                  background-color: rgba(214, 213, 213, 0.35);
                  color: rgb(26, 26, 26);
                }
                th {
                  text-align: center;
                }
                td {
                  font-weight: 600;
                }
                thead th {
                  background-color: rgba(255, 120, 79, 0.5)
                }
                tbody tr:hover {
                  background-color: rgb(97, 97, 97, 0.3);
                }
                tbody td {
                  position: relative;
                }
                tbody td:hover:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: -9999px;
                  bottom: -9999px;
                  background-color: rgba(255, 255, 255, 0.2);
                  z-index: -1;
                }
                
                .newtooltip {
                  color:#fff;
                  background-color:rgb(121, 121, 121);
                  width:12px;
                  height:12px;
                  display:inline-block;
                  border-radius:100%;
                  font-size:10px;
                  text-align:center;
                  text-decoration:none;
                  -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
                  -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
                  box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
                }
                
                .tooltip {
                  position: relative;
                  display: inline-block;
                  background: rgb(121, 121, 121);
                  color: #fff;
                  padding: 0.05px 5.1px;
                  border-radius: 100%;
                  font-size: 14px;
                  font-weight: 700;
                }
                
                .tooltip .tooltiptext {
                  visibility: hidden;
                  width: 150px;
                  background-color: rgb(218, 218, 218);
                  color: rgb(37, 37, 37);
                  text-align: center;
                  border-radius: 6px;
                  padding: 5px 0;
                  font-size: 15px;
                  
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -60px;
                }
                
                .tooltip:hover .tooltiptext {
                  visibility: visible;
                }
                
                .tooltip:active .tooltiptext {
                  visibility: visible;
                }
                @import url('https://fonts.googleapis.com/css?family=Montserrat');



.priceForm {
  overflow: visible;
}

.heading {
  text-align: center;
  margin: 0 0;
  margin-top: 4%;
}

.heading h1 {
  font-weight: bolder;
  background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.8rem;
}

.refheading {
  text-align: center;
  margin: 0 0;
  margin-top: 4%;
}

.refheading h1 {
  font-weight: bolder;
  background: linear-gradient(to right, #222531 0%, #222531 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.6rem;
}

.inputField {
  width: 150px;
  text-align: right;
}

.prices {
  font-size: 42px;
  padding: 40px
}

.prices input {
  font-size: 42px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

.btn-primary {
  background: linear-gradient(to right, #86829e 0%, #25262b 100%);
  color: #fff;
  border: 0;
  outline: 0;
  font-size: 16px;
}

.btn {
  padding: 6px 20px;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
}

a, u {
  text-decoration: none;
}

.Connect_button {
  color: #ffffff;
  font-family: century-gothic;
  font-size: 18px;
  font-weight: bold;
  background-image: linear-gradient(to right, #515da8 0%, #e31e25 100%);
  border: none;
  border-radius: 50px;
  padding: 4px 30px !important;
  vertical-align: text-top;
  opacity: 0.7;
  margin-top: 1px;
}

.ng-trigger-dialogContainer {
  border-radius: 20px !important;
  border: 1px solid #000 !important;
}

.Connect_button:focus {
  outline: none;
}

.referal_maincontainer {
  display: flex;
  padding-top: 120px;
}

.balance_form {
  background: #fff;
  width: 100%;
  height: 510px;
}

.refform_dropdowndiv .dropdown button {
  width: 101.9%;
  border-radius: 0 50px 50px 0;
  padding: 0;
  border: none;
  background-image: linear-gradient(to right, #dadada, #b3b1b3);
  color: #000;
  border-left: 2px solid #929092;
  height: 54px;
  font-weight: bold;
  text-indent: -74px;
}

#dropdownMenuButton img {
  float: left;
  width: 26px;
}

.refform_dropdowndiv .dropdown button:focus {
  outline: none;
}

.refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
  display: inline-block;
  border: none;
  padding: 0;
  border-radius: 0 50px 50px 0;
  height: 54px;
  vertical-align: top;
}

.refform_dropdowndiv .dropdown {
  width: 53%;
  overflow: hidden;
}

.refform_dropdowndiv input {
  text-indent: 20px;
  width: 80%;
  font-size: 16px;
  color: rgb(92, 92, 92);
}

.refform_dropdowndiv input::placeholder {
  color: #000;
}

.refform_dropdowndiv input:focus {
  box-shadow: none;
  outline: none;
}

.refform_dropdowndiv {
  border: 0px solid rgb(0, 0, 0);
  border-radius: 50px;
  height: 60px;
  margin-top: 8px;
  overflow: hidden;
  background: linear-gradient(to right, rgba(242, 112, 156, 0.15) 0%, rgba(255, 148, 114, 0.15) 100%);
  text-align: center;
}

.refform_dropdowndiv .dropdown button img {
  width: 20px;
  margin-right: 0px;
  margin-left: 10px;
}

.balnce_inputform {
  position: relative;
}

.balnce_inputform label {
  color: #000000;
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 20px;
  margin-bottom: 8px;
}

.dropdown-toggle::after {
  content: "";
  position: absolute;
  z-index: 99;
  display: block;
  width: 8px;
  height: 8px;
  opacity: 0;
}

.to_dropdownimg {
  font-size: 12px !important;
  background-image: linear-gradient(to right, #f3abb0, #d28d97) !important;
}

.labelto {
  margin-top: 50px;
}

.arrow_images {
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  text-align: center;
}

.arrow_images img {
  width: 30px;
  margin: 0 10px;
}

.balance_text {
  color: #e31e25;
  font-family: century-gothic;
  font-size: 24px;
  text-align: right;
  margin: 0 0 16px 0;
}

.formmaincontainerdiv {
  margin-bottom: 50px;
}

.btn_container {
  text-align: center;
  margin-top: 50px;
}

.btn_container button {
  padding: 4px 20px;
}

select.select_first {
  position: absolute;
  top: 0;
  background: transparent;
  border: 0;
  font-weight: bold;
  text-indent: 58px;
  color: #000;
  margin-top: 6px;
  font-size: 16px;
  width: 94%;
}

select:focus {
  background-color: transparent;
  box-shadow: none;
}

select.select_two {
  position: absolute;
  top: 0;
  background: transparent;
  border: 0;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  margin-top: 7px;
  text-indent: 45px;
  width: 94%;
}

.marleft20px {
  margin-left: 20px !important;
}

.to_dropdownimg img {
  float: left;
  margin-left: 10px;
}

.form-group {
  margin: 0px!important;
  padding: 0px 18px 16px 18px!important;
}

@media screen and (min-width: 1022px) and (max-width: 1024px) {
  .refform_dropdowndiv .dropdown button img {
    margin-right: 76px;
  }
}

@media screen and (min-width: 500px) and (max-width: 770px) {
  select.select_first {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .referal_maincontainer {
    padding-top: 0
  }
}

@media only screen and (max-width: 767px) {
  .bg-light {
    padding: 16px 33px !important;
  }
  .card-body {
    padding: .5rem;
  }
  .form-group {
    margin: 0px!important;
    padding: 0px 18px!important;
  }
  .arrowtopbot img {
    height: 35px;
  }
  .refform_dropdowndiv .dropdown button {
    font-size: 8px !important;
  }
  .tyah_button {
    font-size: 16px !important;
  }
  select.select_first {
    text-indent: 10px;
    font-size: 12px;
    padding: 0px 5px 0px 20px;
    height: 45px;
  }
  .refform_dropdowndiv .dropdown button {
    margin-left: 4px;
  }
  #dropdownMenuButton img {
    width: 20px;
  }
  select.select_two {
    font-size: 12px;
    text-indent: 26px;
  }
  select.select_two {
    width: 92%;
  }
  .refform_dropdowndiv .dropdown button {
    width: 99.6% !important;
  }
  .referal_maincontainer {
    display: flex;
    padding-top: 0;
    align-items: center;
  }
  .balance_text {
    font-size: 20px;
  }
  .balnce_inputform label {
    font-size: 20px;
    margin-bottom: 0;
  }
  .arrow_images img {
    width: 20px;
    margin: 0 4px;
  }
  .formmaincontainerdiv {
    margin-bottom: 30px;
  }
  .labelto {
    margin-top: 30px;
  }
  .Connect_button {
    font-size: 16px;
  }
}

@media screen and (min-width: 200px) and (max-width: 320px) {
  select.select_first {
    text-indent: 29px;
    font-size: 10px;
  }
  .referal_maincontainer {
    height: inherit;
  }
  .refform_dropdowndiv .dropdown button {
    width: 99% !important;
  }
  .balance_text, .balnce_inputform label {
    font-size: 20px;
    margin-bottom: 0;
  }
  .refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
    height: 48px;
  }
  .refform_dropdowndiv {
    height: 54px;
  }
  .refform_dropdowndiv .dropdown button {
    height: 48px;
  }
}

@media only screen and (max-width: 280px) {
  .refform_dropdowndiv .dropdown button {
    width: 98.6% !important;
  }
  .Connect_button {
    color: #ffffff;
    font-family: century-gothic;
    font-size: 16px;
  }
  .balance_text, .balnce_inputform label {
    font-size: 20px;
    margin-bottom: 0;
  }
  .refform_dropdowndiv input, .refform_dropdowndiv .dropdown {
    height: 48px;
  }
  .refform_dropdowndiv {
    height: 54px;
  }
  .refform_dropdowndiv .dropdown button {
    height: 48px;
  }
}

.coin-img {
  display: block;
}

.refconverter {
  width: 25%;
  margin: 0 auto;
}

.refswapbutton {
  margin: auto;
  margin-top: 3%;
  background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
  color: #fff;
  border: 0;
  outline: 0;
  width:15%;
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}

.refswapbuttonclicked {
  margin: auto;
  margin-top: 3%;
  background: linear-gradient(to right, rgba(242, 112, 156, 0.35) 0%, rgba(255, 148, 114, 0.35) 100%);
  color: #fff;
  border: 0;
  outline: 0;
  width:15%;
  font-size: 14x;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}

.refswap {
  color: white;
  cursor: pointer;
}

.left {
  text-align: left;
}

.middlepls {
  text-align: center;
}

.currencies {
  font-size: 20px;
  font-weight: bold;
}

.refform-control {
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
  color: grey;
  text-align: center;
  width: 500px;
}

.reffootertext {
  color: #222531;
  margin-top: 10%;
}

.social-icon {
  width: 60px;
}

.footerdiv {
  display: flex;
  text-align: center;
}

.footerchild {
  padding-top: 20px;
  align-self: center;
  margin: auto;
  width: 250px;
  display:flex;
  justify-content: space-between;
  padding-bottom: 5%;
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
  transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
  transform: rotate(45deg) translate(-8px, -8px) ;
}

.dropdown {
  text-align:center;
}

.dropdownElement {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 50px;
}

.dropdownElement .text {
  font-weight: bolder;
  background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footertextmobile {
  color: rgb(71, 71, 71);
  display: none;
}

@media only screen and (max-width: 900px) {
  /* For mobile phones: */
  [class="reftext"] {
    margin-top: 10%;
  }
  [class="refconverter"] {
    width: 100%;
  }
  [class="refswapbutton"] {
    width: 50%;
    font-size: 24;
    margin-top: 10%;
  }
  [class="refswapbuttonclicked"] {
    width: 50%;
    font-size: 24;
    margin-top: 10%;
  }
  [class="price"] {
    width: 90%;
  }
  [class="reffootertext"] {
    display: none;
  }
  [class ="refconverter"] {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 300px) {

  [class="chart"]{
    width: 250px;
    height: 350px;
    padding-top: 30px;
  }
  [class="footerchild"]{
    width: 250px;
  }
}

@media only screen and (max-width: 420px) {

  [class="chart"]{
    width: 270px;
    height: 350px;
    padding-top: 30px;
  }
  [class="footerchild"]{
    width: 270px;
  }
}

.chartheading {
  text-align: center;
  margin: 0 0;
  margin-top: 8%;
}

.chartheading h1 {
  background: linear-gradient(to right, #f85d90 0%, #ff784f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.2rem;
}

.transferButton{
  cursor: pointer;
}

.generatedref{
    text-align: center;
    margin-top: 2%;
}

.reftext{
    color: rgb(71, 71, 71);
    font-size: 30px;
}

.reftextlink{
    color: rgb(71, 71, 71);
    font-size: 20px;
}
                `}
            </Style>
            <Navbar
                clickHandler={clickHandler}
                fixer={setExpanded}
                referral="https://socialswap.io/referral/"
                id={props.match.params.id || null}
            />
            {expanded ? dropDown : undefined}

            <div className="info2">

                <div className="main ">
                    <div>
                        <div className="refheading">
                            <h1>SocialSwap.io</h1>
                        </div>

                        <div>
                            <h2 className="limitwidth">
                                <span>Eine kleine Einführung vorab</span>
                            </h2>
                        </div>

                        <div className="video">
                            <ReactPlayer url='https://www.youtube.com/watch?v=psJroUB8pPE'
                                         width='100%'
                                         />
                        </div>


                        <div className="swapbutton">
                            <a
                                href={"/stepbytep/ref/" + props.match.params.id}
                                rel="noopener"
                            >
                                <span>Zu der Step by Step Anleitung</span>
                            </a>
                        </div>

                        <div>
                            <h1 className="reffootertext">
                                Follow them and get the latest updates on SocialSwap.
                            </h1>
                            <div className="footerdiv">
                                <div className="footerchild">
                                    <a
                                        target="_blank"
                                        href="https://www.youtube.com/channel/UC6gcqlRfsUuW_ZJ1BDVfycQ"
                                        rel="noreferrer"
                                    >
                                        <img className="social-icon" src={youtube} alt="youtube"/>
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://www.reddit.com/r/SocialSwap/"
                                        rel="noreferrer"
                                    >
                                        <img className="social-icon" src={reddit} alt="reddit"/>
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://t.me/socialswapio"
                                        rel="noreferrer"
                                    >
                                        <img className="social-icon" src={telegram} alt="telegram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default IntroPage;
