import React, { useEffect, useState, useLayoutEffect } from "react";
import Navbar from './Navbar';
import background from './static/images/background.png'
import Style from "style-it"
import {useLocation} from "react-router-dom"

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
      function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function isValid(input) {
	var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
	if (pattern.test(input)) {
		return false;
	}
	return true; //good user input
  }

const Funnel_1 = () => {
  const [expanded, setExpanded] = useState(false);
  const [windoWidth, windowHeight] = useWindowSize();
  const [refLink, setrefLink] = useState("https://www.socialswap.io/")
  const {search} = useLocation()
  var givenReferral = search.split("ref=")[1]

  useEffect(() => {
    if(isValid(givenReferral) && givenReferral){
      var completeLink = "https://socialswap.io/referral/" + givenReferral
      setrefLink(completeLink)
    }
  })

  const clickHandler = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  const dropDown = (
    <div className="dropdown">
      <a href="https://www.socialswap.io/" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DEX</h2></div></a>
      <a href="/referrals" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Ref</h2></div></a>
      <a href="https://dappradar.com/tron/exchanges/socialswap-io" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">DappRadar</h2></div></a>
      <a href="https://tronscan.org/#/token20/TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe" target="_blank" rel="noreferrer"><div className="dropdownElement"><h2 className="text">Contract</h2></div></a>
    </div>
  );

  let bg = background;
  if(windoWidth < 900){
    bg = null;
  }

  return (
    <div>
        <Style>
            {`
            

@charset "utf-8";
html {
	scroll-behavior: smooth
}

body,
html {
	color: #333;
	font-family: sans-serif;
	font-size: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%
}

a {
    text-decoration: none
}

body {
	margin: 0;
	padding: 0
}

body.fixed {
	overflow: hidden;
	height: 100%
}

iframe {
	border: none;
	max-width: 100%
}

nav#sol-navigation {
	box-sizing: border-box;
	display: inline-block;
	max-width: inherit;
	position: relative;
	z-index: 997;
	width: 100%
}

nav#sol-navigation.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0
}

nav#sol-navigation>ul {
	box-sizing: inherit;
	display: flex;
	list-style-type: none;
	margin: 0;
	max-width: 100%;
	padding: 0;
	width: 100%
}

nav#sol-navigation>ul>li {
	box-sizing: inherit
}

nav#sol-navigation>ul>li>a {
	align-items: center;
	box-sizing: inherit;
	color: inherit;
	display: flex;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-variant: inherit;
	font-weight: inherit;
	height: 100%;
	justify-content: center;
	padding: 1em;
	text-decoration: none;
	width: 100%
}

a.navlink,
a.toplink {
	background-color: rgba(200, 200, 200, 0.5);
	background-repeat: no-repeat;
	background-position: center center;
	border: 2px solid #efefef;
	border-radius: 5px;
	color: transparent;
	height: 40px;
	opacity: .1;
	position: fixed;
	text-align: center;
	transition: .25s all ease-out;
	width: 40px;
	z-index: 998
}

a.navlink.visible,
a.toplink.visible {
	opacity: 1;
	transition: .25s all ease-in
}

a.navlink.visible:hover,
a.toplink.visible:hover {
	background-color: rgba(200, 200, 200, 0.9)
}

a.navlink {
	left: .5em;
	top: .5em
}

a.toplink {
	bottom: .5em;
	right: .5em
}

div#sol-baselayer {
	margin: 0 auto
}

div#sol-baselayer>.visible {
	opacity: 1;
	transition: opacity .5s ease-in
}

div#sol-baselayer.sol-loading {
	background-attachment: fixed
}

div#sol-baselayer.sol-loading>* {
	opacity: 0
}

div#sol-baselayer.sol-loading:before {
	color: #bebebe;
	font-size: 1.6em;
	font-weight: 700;
	left: 50%;
	position: fixed;
	text-align: center;
	top: calc(50% + 1.8em);
	transform: translate(-50%, -50%)
}

[lang='de'] .sol-loading:before {
	content: 'Laden, bitte warten'
}

[lang='en'] .sol-loading:before {
	content: 'loading, please wait'
}

[lang='es'] .sol-loading:before {
	content: 'cargando por favor espere'
}

[lang='fr'] .sol-loading:before {
	content: 'chargement, veuillez patienter'
}

[lang='it'] .sol-loading:before {
	content: 'Attendere il caricamento prego'
}

[lang='nl'] .sol-loading:before {
	content: 'Laden even geduld aub'
}

[lang='pl'] .sol-loading:before {
	content: 'ładowanie proszę czekać'
}

[lang='ru'] .sol-loading:before {
	content: 'Загрузка, пожалуйста подождите'
}

[lang='tr'] .sol-loading:before {
	content: 'yükleniyor lütfen bekleyin'
}

div#sol-baselayer div.sol-block {
	align-self: flex-start;
	box-sizing: border-box;
	flex-grow: 0;
	max-width: inherit
}

div#sol-baselayer div.sol-image {
	flex-grow: 0
}

div.sol-image img {
	height: 100%;
	width: 100%
}

div#sol-exit div.dialog {
	padding: 0
}

div#sol-spambot_protection>div {
	left: 50%;
	max-width: 90%;
	position: fixed;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 500px
}

div.sol-closer {
	background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDEzLjIyOTE2NiAxMy4yMjkxNjciPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsLTI4My43NzA4MikiPjxwYXRoIGQ9Im0gMy45Njg3NSwyOTMuMDMxMjQgNS4yOTE2NjY3LC01LjI5MTY3IG0gLTUuMjkxNjY2NywwIDUuMjkxNjY2Nyw1LjI5MTY3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojZmZmZmZmO3N0cm9rZS13aWR0aDoyLjY0NTgzMzI1O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjptaXRlcjtzdHJva2UtbWl0ZXJsaW1pdDo0O3N0cm9rZS1kYXNoYXJyYXk6bm9uZTtzdHJva2Utb3BhY2l0eToxIiAvPjwvZz48L3N2Zz4=) no-repeat center/contain;
	border-top-right-radius: inherit;
	cursor: pointer;
	height: 2em;
	position: absolute;
	right: 0;
	top: 0;
	width: 2em;
	z-index: 1000
}

div.sol-closer:hover {
	background-color: #e7573e!important
}

div.sol-cols {
	align-items: stretch;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	max-width: 100%;
	position: inherit
}

div.sol-modal,
div.sol-backdrop {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999
}

div.sol-block img {
	max-width: 100%
}

div.sol-block * {
	border-radius: inherit
}

div.sol-row {
	overflow: hidden;
	position: relative;
	width: inherit
}

div.sol-row.visible {
	opacity: 1;
	transform: translate(0, 0)
}

div.sol-countdown {
	text-align: center
}

div.Xsol-countdown-target-appear {
	display: none;
	opacity: 0
}

div.Xsol-countdown-target-disappear {
	opacity: 1
}

div.Xsol-countdown-target-effect-fade,
div.sol-countdown-target-effect-slide {
	animation-duration: 3s;
	animation-fill-mode: both;
	animation-iteration-count: 1;
	animation-play-state: paused
}

div.Xsol-countdown-target-effect-fade.sol-countdown-target-appear {
	animation-name: countdownFadeIn
}

div.Xsol-countdown-target-effect-fade.sol-countdown-target-disappear {
	animation-duration: 3s;
	animation-name: countdownFadeOut;
	animation-timing-function: cubic-bezier(.25, .46, .45, .94)
}

@keyframes countdownFadeIn {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

@keyframes countdownFadeOut {
	from {
		opacity: 1
	}
	85% {
		opacity: 0
	}
	to {
		height: 0
	}
}

div.Xsol-countdown-target-effect-slide.sol-countdown-target-appear {
	animation-name: countdownSlideInDown;
	display: unset;
	height: 0;
	opacity: 1
}

div.Xsol-countdown-target-effect-slide.sol-countdown-target-disappear {
	animation-duration: 5s;
	animation-name: countdownSlideOutUp
}

@keyframes countdownSlideInDown {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: visible
	}
	to {
		transform: translate3d(0, 0, 0)
	}
}

@keyframes countdownSlideOutDown {
	from {
		transform: translate3d(0, 0, 0)
	}
	85% {
		transform: translate3d(0, 100%, 0);
		visibility: hidden
	}
	to {
		height: 0
	}
}

@keyframes countdownSlideOutUp {
	from {
		transform: translate3d(0, 0, 0)
	}
	95% {
		transform: translate3d(0, -100%, 0)
	}
	to {
		height: 0;
		visibility: hidden
	}
}

div.sol-popup {
	background-color: #fff;
	left: 50%;
	max-height: 100%;
	max-width: 100%;
	overflow-y: auto;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%)
}

div.sol-popup.empty div.sol-closer {
	background-color: #ccc
}

.sol-popup_trigger {
	display: block;
	margin: 0 auto;
	max-width: 100%
}

div.sol-sales_button button.sol-salesbutton {
	border-radius: unset;
	box-shadow: unset;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%
}

div.sol-social_media {
	width: 100%
}

div.sol-social_media>div {
	display: flex;
	flex-wrap: wrap;
	justify-content: center
}

div.sol-social_media a {
	flex-shrink: 0;
	flex-grow: 0;
	margin: 2%
}

div.sol-text {
	hyphens: auto
}

div.sol-title_bar {
	box-sizing: border-box;
	line-height: 1.6em;
	margin-bottom: .5em;
	max-width: 100%;
	min-height: 32px;
	overflow: hidden;
	padding-left: .5em;
	padding-right: 2em;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%
}

div.sol-video {
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%
}

div.sol-video>iframe,
div.sol-video>img,
div.sol-video>video {
	border: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}

div#sol-error {
	background-color: rgba(0, 0, 0, 0.6)
}

div#sol-legal {
	background-color: #fff;
	color: #333;
	font-size: 13px;
	padding: 5px 0;
	text-align: center;
	width: 100%;
	z-index: 102
}

div#sol-legal a {
	color: inherit;
	text-decoration: none
}

div#sol-legal a:not(#sol-powered-by):before {
	content: ' | '
}

div#sol-legal a:last-child {
	margin-right: 5px
}

div#sol-legal a:hover,
span.optin_text a:hover {
	color: #3f62ba
}

div#sol-legal a#sol-powered-by.sol1735:after {
	color: transparent;
	content: 'goolux24';
	margin-left: .2em;
	margin-right: .5em
}

span.optin_text a {
	color: inherit;
	font-weight: 700;
	hyphens: none;
	text-decoration: none
}

span.optin_text,
form li.optin {
	hyphens: auto;
	word-wrap: break-word
}

span.optin_text {
	color: #777;
	font-size: .8em
}

div.loading {
	width: 100%
}

div.loading>div {
	margin: 10px;
	padding-top: 50px;
	text-align: center;
	width: 100%
}

[type='submit'] {
	cursor: pointer
}

ul[class^='form-layout-'] {
	display: inline-block;
	list-style-type: none;
	padding: 0
}

ul[class^='form-layout-']>li {
	margin-bottom: .5em
}

ul[class^='form-layout-'] input[type='checkbox'] {
	margin: 0 .5em 0 0;
	vertical-align: -5%
}

ul[class^='form-layout-'] [name^='birth'] {
	margin-left: .5em;
	width: auto!important
}

ul[class^='form-layout-']>li:last-child {
	clear: left;
	text-align: left;
	width: 100%
}

ul[class^='form-layout-']>li:nth-last-child(2) {
	text-align: center;
	width: 100%
}

@media only screen and (orientation: landscape) {
	.sol-video.sol-1by1 {
		padding-bottom: 0
	}
}

@media only screen and (orientation: portrait) and (max-width: 667px) {
	.sol-video.sol-1by1 {
		height: 0!important;
		width: 100%!important;
		padding-bottom: 215%!important
	}
	div#sol-baselayer.sol-loading {
		background-position: center top+40%
	}
	div#sol-baselayer.sol-loading:before {
		top: 50%
	}
}

@media screen and (min-width: 1024px) {
	a.navlink {
		display: none
	}
}

@media screen and (max-width: 896px) {
	[type='submit'] {
		hyphens: auto;
		max-width: 100%!important;
		min-width: initial!important;
		overflow-wrap: break-word
	}
	.flip-clock-label {
		display: none
	}
	div.sol-form {
		border: none!important;
		border-radius: 0!important;
		padding: 0!important
	}
	div.sol-popup div.sol-form {
		margin-bottom: 2em!important
	}
	div.sol-form div.sol-paddings,
	div.sol-popup div.sol-form {
		margin-left: 3%!important;
		margin-right: 3%!important
	}
	div.sol-text {
		hyphens: auto;
		max-width: 100%;
		min-width: initial!important;
		overflow-wrap: break-word
	}
	div#sol-baselayer {
		border: none!important
	}
	div#sol-exit div.dialog {
		border: unset!important;
		border-radius: unset!important;
		box-shadow: unset!important;
		width: 100%!important
	}
	div#sol-legal {
		font-size: 14px!important;
		padding-left: .5em;
		padding-right: .5em;
		width: calc(100% - 1em)
	}
	div#sol-row_0 {
		margin-bottom: 0
	}
	nav#sol-navigation.tiles {
		background-color: rgba(0, 0, 0, 0.5);
		display: block;
		height: 100%;
		left: 0;
		margin: 0;
		position: fixed;
		top: 0!important;
		transition: .25s all ease-in;
		width: 100%
	}
	nav#sol-navigation.tiles>ul {
		background-color: transparent;
		border: none;
		flex-wrap: wrap;
		height: auto;
		left: 50%;
		max-height: 100%;
		max-width: 80%;
		position: fixed;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 550px
	}
	nav#sol-navigation.tiles>ul>li {
		background-color: #fff!important;
		border: none!important;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
		font-family: sans-serif!important;
		font-size: 1.2em!important;
		font-style: normal!important;
		font-variant: normal!important;
		font-weight: 400!important;
		margin: .5em 1%;
		text-shadow: none!important;
		width: 48%!important
	}
	nav#sol-navigation.tiles>ul>li:hover {
		background-color: #dedede!important
	}
	nav#sol-navigation.tiles>ul>li>a {
		color: #333;
		display: block;
		font-family: inherit;
		font-size: inherit;
		font-style: inherit;
		font-variant: inherit;
		font-weight: inherit;
		height: 100%;
		overflow: hidden;
		padding: 1em;
		text-align: left;
		text-decoration: none;
		text-overflow: ellipsis;
		text-shadow: inherit;
		white-space: nowrap
	}
}

@media screen and (min-width: 667px) {
	ul.form-layout-1 {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		width: 100%
	}
	ul.form-layout-1>li:nth-last-child(2),
	ul.form-layout-1>li:last-child {
		width: 100%
	}
	ul.form-layout-1 input:not([type='checkbox']):not([type='submit']),
	ul.form-layout-1 textarea {
		width: 95%
	}
	ul.form-layout-1 select {
		max-width: 95%;
		width: unset
	}
	ul.form-layout-2 {
		display: table
	}
	ul.form-layout-2>li:not(:last-child) {
		display: table-cell;
		width: 33%
	}
	ul.form-layout-2>li:last-child {
		caption-side: bottom;
		display: table-caption;
		margin-top: .5em
	}
	ul.form-layout-2>li:nth-last-child(2) {
		width: auto
	}
	ul.form-layout-2 input,
	ul.form-layout-2 select,
	ul.form-layout-2 textarea {
		margin: 0 .5em 0 0
	}
	ul.form-layout-2 input:not([type='checkbox']):not([type='submit']),
	ul.form-layout-2 select,
	ul.form-layout-2 textarea {
		width: 90%
	}
	ul.form-layout-3>li {
		float: left;
		width: 48%
	}
	ul.form-layout-3>li:nth-child(2n) {
		float: right
	}
	ul.form-layout-3 input:not([type='checkbox']):not([type='submit']),
	ul.form-layout-3 textarea {
		width: 95%
	}
	ul.form-layout-3 select {
		width: 103%
	}
}

@media screen and (max-width: 414px) {
	body {
		font-size: 1.1em;
		hyphens: auto
	}
	button,
	input,
	select,
	textarea {
		max-width: 94%!important
	}
	form {
		max-width: 100%!important
	}
	div.sol-block {
		margin-left: unset!important;
		margin-right: unset!important
	}
	div.sol-form input[type='email'],
	div.sol-form input[type='text'],
	div.sol-form textarea {
		width: 94%
	}
	div.sol-form select {
		max-width: 94%
	}
	div.sol-popup {
		border-radius: unset!important;
		box-shadow: unset!important;
		height: 100%!important;
		left: 0!important;
		top: 0!important;
		transform: unset!important
	}
	div.sol-popup>div.sol-closer {
		position: fixed;
		right: 10px;
		top: 10px
	}
	div.sol-row:first-child {
		margin-top: unset
	}
	div.sol-video {
		margin-left: 0!important;
		margin-right: 0!important;
		width: 100%!important
	}
	div.sol-video.sol-3by2 {
		padding-bottom: 66.67%!important
	}
	div.sol-video.sol-4by3 {
		padding-bottom: 75%!important
	}
	div.sol-video.sol-16by9 {
		padding-bottom: 56.25%!important
	}
	div.sol-video.sol-21by9 {
		padding-bottom: 42.86%!important
	}
	div#sol-baselayer {
		padding-left: 0!important;
		padding-right: 0!important;
		width: 100%
	}
	div#sol-legal a.popup:not(:last-child):before {
		content: "\A"!important;
		white-space: pre
	}
	nav#sol-navigation.tiles>ul {
		flex-direction: column;
		flex-wrap: nowrap;
		width: 80%
	}
	nav#sol-navigation.tiles>ul>li {
		width: 100%!important
	}
}

@media screen and (max-height: 414px) {
	div#sol-baselayer {
		border: none!important
	}
	a.navlink {
		opacity: 1
	}
	nav#sol-navigation {
		display: none
	}
}

body{background-attachment:fixed;background-color:#fff;background-position:left top;background-repeat:no-repeat;background-size:cover}
.sol-cols{width:900px}
#sol-baselayer{background-color:#fff;border:none;margin-bottom:0;margin-top:0;max-width:900px}
#sol-baselayer{margin-bottom:22px !important}
#sol-legal{background-color:#fff!important;bottom:0 !important;color:#222!important;font-family:Verdana, sans-serif !important;font-size:12px !important;font-style:normal !important;font-variant:normal !important;font-weight:400 !important;left:0 !important;position:fixed !important;text-align:center !important;z-index:102 !important}
#sol-row_1{padding-bottom:20px;padding-top:20px}
#sol-312878 > .sol-paddings{margin:20px}
#sol-312878{background-color:transparent;border:none;min-height:0;width:900px}
#sol-312879{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_3{background-color:transparent;border:none;padding-bottom:0;padding-top:50px}
#sol-312885 .sol-salesbutton{margin:0 auto}
#sol-312885{background-color:transparent;min-height:0;width:900px}
#sol-312885 button{background-color:rgb(83, 190, 0);background-position:center center;background-repeat:no-repeat;background-size:cover;border-color:rgb(246, 123, 0);border-radius:5px;border-style:dashed;border-width:4px;height:80px;max-width:100%;padding:5px;width:600px}
#sol-313349 > .sol-paddings{margin:20px}
#sol-313349{background-color:transparent;min-height:0;width:900px}
#sol-313350{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_6{background-color:transparent;border:none;padding-bottom:0;padding-top:20px}
#sol-312880{background-color:transparent;border:none;margin:0 auto;width:70px}
#sol-312881 > .sol-paddings{margin:20px}
#sol-312881{background-color:transparent;border:none;min-height:0;width:600px}
#sol-312882{background-color:transparent;border:none;margin:0 auto;width:70px}
#sol-312886 > .sol-paddings{margin:20px}
#sol-312886{background-color:transparent;min-height:0;width:900px}
#sol-312887{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313254 > .sol-paddings{margin:20px}
#sol-313254{background-color:transparent;min-height:0;width:900px}
#sol-313255{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_11{background-color:transparent;border:none;padding-bottom:0;padding-top:50px}
#sol-313266 .sol-salesbutton{margin:0 auto}
#sol-313266{background-color:transparent;border:none;min-height:0;width:400px}
#sol-313266 button{background-color:#f0f0f0;border-color:#cacaca;border-radius:3px;border-style:solid;border-width:2px;height:40px;max-width:100%;padding:5px;width:100px}
#sol-313268 > .sol-paddings{margin:20px}
#sol-313268{background-color:transparent;border:none;min-height:0;width:100px}
#sol-313269 .sol-salesbutton{margin:0 auto}
#sol-313269{background-color:transparent;border:none;min-height:0;width:400px}
#sol-313269 button{background-color:#f0f0f0;border-color:#cacaca;border-radius:3px;border-style:solid;border-width:2px;height:40px;max-width:100%;padding:5px;width:100px}
#sol-313256 > .sol-paddings{margin:20px}
#sol-313256{background-color:transparent;min-height:0;width:900px}
#sol-313257{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313337 > .sol-paddings{margin:20px}
#sol-313337{background-color:transparent;min-height:0;width:900px}
#sol-313338{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313258 > .sol-paddings{margin:20px}
#sol-313258{background-color:transparent;min-height:0;width:900px}
#sol-313259{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_18{background-color:transparent;border:none;padding-bottom:0;padding-top:50px}
#sol-313270 .sol-salesbutton{margin:0 auto}
#sol-313270{background-color:transparent;min-height:0;width:900px}
#sol-313270 button{background-color:#f0f0f0;border-color:#cacaca;border-radius:3px;border-style:solid;border-width:2px;height:40px;max-width:100%;padding:5px;width:350px}
#sol-313260 > .sol-paddings{margin:20px}
#sol-313260{background-color:transparent;min-height:0;width:900px}
#sol-313261{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313262 > .sol-paddings{margin:20px}
#sol-313262{background-color:transparent;min-height:0;width:900px}
#sol-313263{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313264 > .sol-paddings{margin:20px}
#sol-313264{background-color:transparent;min-height:0;width:900px}
#sol-313265{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313326 > .sol-paddings{margin:20px}
#sol-313326{background-color:transparent;min-height:0;width:900px}
#sol-313325{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-314540 > .sol-paddings{margin:20px}
#sol-314540{background-color:transparent;min-height:0;width:900px}
#sol-314542{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_29{background-color:transparent;border:none;padding-bottom:0;padding-top:50px}
#sol-314545 .sol-salesbutton{margin:0 auto}
#sol-314545{background-color:transparent;min-height:0;width:900px}
#sol-314545 button{background-color:#f0f0f0;border-color:#cacaca;border-radius:3px;border-style:solid;border-width:2px;height:40px;max-width:100%;padding:5px;width:300px}
#sol-313327 > .sol-paddings{margin:20px}
#sol-313327{background-color:transparent;min-height:0;width:900px}
#sol-313328{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-313891 > .sol-paddings{margin:20px}
#sol-313891{background-color:transparent;min-height:0;width:900px}
#sol-313892{background-color:transparent;margin:0 auto;padding-bottom:56%;width:100%}
#sol-row_34{background-color:transparent;border:none;padding-bottom:0;padding-top:0}
#sol-312884 > .sol-paddings{margin:20px}
#sol-312884{background-color:transparent;min-height:0;width:900px}

.linkbutton{
    cursor: pointer
}
            `}
        </Style>
    <div id="sol-baselayer" className>
    <div className="sol-row" id="sol-row_1">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-312878">
          <div className="sol-paddings">
            <h1 style={{textAlign: 'center'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: 55, color: '#ff6600'}}>Wie funktioniert SocialSwap?</span></h1>
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>Die dezentrale Exchange mit eigenem SST Token einfach erklärt!</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_2">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-312879">
          <iframe src="https://www.youtube-nocookie.com/embed/psJroUB8pPE?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_3">
      <div className="sol-cols">
        <div className="sol-block sol-sales_button" id="sol-312885">
          <div className="sol-paddings">
            <div className="pre" />
            <a href={refLink} target="_blank">
            <button className="sol-salesbutton linkbutton"><span style={{color: '#ffffff', fontSize: 26}}>HIER KANNST DU DABEI SEIN!</span></button>
            </a>
            <div className="post" />
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_4">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313349">
          <div className="sol-paddings">
            <div id="sol-row_2" className="sol-row">
              <div className="sol-cols">
                <div id="sol-312878" className="sol-block sol-text">
                  <div className="sol-paddings">
                    <h1 style={{textAlign: 'center'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: 55, color: '#ff6600'}}>Rückblick</span></h1>
                    <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>Die ersten 7 Tage von SocialSwap<br /></span></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_5">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313350">
          <iframe src="https://www.youtube-nocookie.com/embed/dA1ZLozItRU?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_6">
      <div className="sol-cols">
        <div className="sol-block sol-image" id="sol-312880">
          <div className="sol-paddings"><img alt src="https://www.isolsend.com/user/system/arrows/down/pfeil-orange-nach-unten.png" title /></div>
        </div>
        <div className="sol-block sol-text" id="sol-312881">
          <div className="sol-paddings">
            <div style={{textAlign: 'center'}}><span style={{fontSize: 26}}>Ich erkläre dir hier Schritt für Schritt<br /></span></div>
          </div>
        </div>
        <div className="sol-block sol-image" id="sol-312882">
          <div className="sol-paddings"><img alt src="https://www.isolsend.com/user/system/arrows/down/pfeil-orange-nach-unten.png" title /></div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_7">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-312886">
          <div className="sol-paddings">
            <h1 style={{textAlign: 'center'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: 55, color: '#ff6600'}}>Instruktionen</span></h1>
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>001. Willkommen in der SocialSwap DEX Community<br /></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_8">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-312887">
          <iframe src="https://www.youtube-nocookie.com/embed/KXfY3iEzjeo?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_9">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313254">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>002. </span><span style={{fontSize: 25}}>Bitcoin in Tron tauschen über Bittrex oder Binance</span><span style={{fontSize: 25}}>&nbsp;</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_10">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313255">
          <iframe src="https://www.youtube-nocookie.com/embed/abms4zzLceM?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_11">
      <div className="sol-cols">
        <div className="sol-block sol-sales_button" id="sol-313266">
          <div className="sol-paddings">
            <div className="pre" />
            <a href="https://global.bittrex.com/Account/Register" target="_blank">
            <button className="sol-salesbutton linkbutton">Bittrex</button>
            </a>
            <div className="post" />
          </div>
        </div>
        <div className="sol-block sol-text" id="sol-313268">
          <div className="sol-paddings">
            <div style={{textAlign: 'center'}}>
              <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>oder</span></h3>
            </div>
          </div>
        </div>
        <div className="sol-block sol-sales_button" id="sol-313269">
          <div className="sol-paddings">
            <div className="pre" />
            <a href="https://accounts.binance.cc/en/register" target="_blank">
            <button className="sol-salesbutton linkbutton">Binance</button>
            </a>
            <div className="post" />
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_12">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313256">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>003. TronLink als Google Chrome Extension installieren und ein neues Tron Wallet einrichten</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_13">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313257">
          <iframe src="https://www.youtube-nocookie.com/embed/vGSeUVs-7E4?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_14">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313337">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>003a. TronLink Wallet auf dem Smartphone installieren und einrichten<br /></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_15">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313338">
          <iframe src="https://www.youtube-nocookie.com/embed/DIJ9ToHXsaA?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_16">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313258">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>004. TronLink Wallet mit der SocialSwap DEX verbinden<br /></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_17">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313259">
          <iframe src="https://www.youtube-nocookie.com/embed/ZxovAexQEsQ?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_18">
      <div className="sol-cols">
        <div className="sol-block sol-sales_button" id="sol-313270">
          <div className="sol-paddings">
            <div className="pre" />
            <a href={refLink} target="_blank">
            <button className="sol-salesbutton linkbutton">Jetzt mit der SocialSwap DEX verbinden</button>
            </a>
            <div className="post" />
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_19">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313260">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>005. SST mit Tron auf der DEX kaufen</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_20">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313261">
          <iframe src="https://www.youtube-nocookie.com/embed/Z0zRzdPrUQE?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_21">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313262">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>006. Staking SST Pool mit den gekauften SST</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_22">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313263">
          <iframe src="https://www.youtube-nocookie.com/embed/73LOti9vcMg?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_23">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313264">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>007. SST Solo Pool Information nach zwei Tagen</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_24">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313265">
          <iframe src="https://www.youtube-nocookie.com/embed/ysMXM7C44s8?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_25">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313326">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>008. Liquidity Providing mit SST und TRX Pairing Pool nach zwei Tagen<br /></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_26">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313325">
          <iframe src="https://www.youtube-nocookie.com/embed/dZ0hULvr_Ng?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_27">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-314540">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>009. ETH oder BTC auf Poloniex in ETH oder BTC TRC20 tauschen</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_28">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-314542">
          <iframe src="https://www.youtube-nocookie.com/embed/Y5O7hj4ZLHU?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_29">
      <div className="sol-cols">
        <div className="sol-block sol-sales_button" id="sol-314545">
          <div className="sol-paddings">
            <div className="pre" />
            <a href="https://poloniex.com/signup" target="_blank">
            <button className="sol-salesbutton linkbutton">Jetzt bei Poloniex anmelden</button>
            </a>
            <div className="post" />
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_30">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313327">
          <div className="sol-paddings">
            <div id="sol-row_1" className="sol-row">
              <div className="sol-cols">
                <div id="sol-312878" className="sol-block sol-text">
                  <div className="sol-paddings">
                    <h1 style={{textAlign: 'center'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: 55, color: '#ff6600'}}>Bonus<br /></span></h1>
                    <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>001. TRX Transaktionsgebühren sparen<br /></span></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_31">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313328">
          <iframe src="https://www.youtube-nocookie.com/embed/5VDFcvqx7ik?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_32">
      <div className="sol-cols">
        <div className="sol-block sol-text" id="sol-313891">
          <div className="sol-paddings">
            <h3 style={{textAlign: 'center'}}><span style={{fontSize: 25}}>002. TRX Transaktionsgebühren am Beispiel SocialSwap SmartContract</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="sol-row" id="sol-row_33">
      <div className="sol-cols">
        <div className="sol-block sol-video sol-16by9" id="sol-313892">
          <iframe src="https://www.youtube-nocookie.com/embed/TDC_zm-yn1k?autoplay=0&controls=0&fs=0&loop=0&modestbranding=1&rel=0" loading="lazy" allowFullScreen allow="autoplay; fullscreen" />			
        </div>
      </div>
    </div>
  </div>
  
  </div>
  );
}

export default Funnel_1;